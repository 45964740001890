/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AgreementFilterInput,
    AgreementFilterInputFromJSON,
    AgreementFilterInputToJSON,
    ListFilterResponse,
    ListFilterResponseFromJSON,
    ListFilterResponseToJSON,
    ListSupplierFilterResponse,
    ListSupplierFilterResponseFromJSON,
    ListSupplierFilterResponseToJSON,
    StoredAgreementFilter,
    StoredAgreementFilterFromJSON,
    StoredAgreementFilterToJSON,
    StoredSupplierFilter,
    StoredSupplierFilterFromJSON,
    StoredSupplierFilterToJSON,
    SupplierFilterInput,
    SupplierFilterInputFromJSON,
    SupplierFilterInputToJSON,
} from '../models';

export interface CreateAgreementFilterRequest {
    agreementFilterInput?: AgreementFilterInput;
}

export interface CreateSupplierFilterRequest {
    supplierFilterInput?: SupplierFilterInput;
}

export interface DeleteAgreementFilterRequest {
    filterId: string;
}

export interface DeleteSupplierFilterRequest {
    filterId: string;
}

export interface UpdateAgreementFilterRequest {
    filterId: string;
    agreementFilterInput?: AgreementFilterInput;
}

export interface UpdateSupplierFilterRequest {
    filterId: string;
    supplierFilterInput?: SupplierFilterInput;
}

/**
 * FiltersApi - interface
 * 
 * @export
 * @interface FiltersApiInterface
 */
export interface FiltersApiInterface {
    /**
     * 
     * @summary Create a Filter
     * @param {AgreementFilterInput} [agreementFilterInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    createAgreementFilterRaw(requestParameters: CreateAgreementFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredAgreementFilter>>;

    /**
     * Create a Filter
     */
    createAgreementFilter(requestParameters: CreateAgreementFilterRequest, initOverrides?: RequestInit): Promise<StoredAgreementFilter>;

    /**
     * 
     * @summary Create a Filter
     * @param {SupplierFilterInput} [supplierFilterInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    createSupplierFilterRaw(requestParameters: CreateSupplierFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredSupplierFilter>>;

    /**
     * Create a Filter
     */
    createSupplierFilter(requestParameters: CreateSupplierFilterRequest, initOverrides?: RequestInit): Promise<StoredSupplierFilter>;

    /**
     * 
     * @summary Delete a filter
     * @param {string} filterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    deleteAgreementFilterRaw(requestParameters: DeleteAgreementFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a filter
     */
    deleteAgreementFilter(requestParameters: DeleteAgreementFilterRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Delete a filter
     * @param {string} filterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    deleteSupplierFilterRaw(requestParameters: DeleteSupplierFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a filter
     */
    deleteSupplierFilter(requestParameters: DeleteSupplierFilterRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary List Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getAgreementFiltersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListFilterResponse>>;

    /**
     * List Filters
     */
    getAgreementFilters(initOverrides?: RequestInit): Promise<ListFilterResponse>;

    /**
     * 
     * @summary List Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getSupplierFiltersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListSupplierFilterResponse>>;

    /**
     * List Filters
     */
    getSupplierFilters(initOverrides?: RequestInit): Promise<ListSupplierFilterResponse>;

    /**
     * 
     * @summary Update Filter
     * @param {string} filterId 
     * @param {AgreementFilterInput} [agreementFilterInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    updateAgreementFilterRaw(requestParameters: UpdateAgreementFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredAgreementFilter>>;

    /**
     * Update Filter
     */
    updateAgreementFilter(requestParameters: UpdateAgreementFilterRequest, initOverrides?: RequestInit): Promise<StoredAgreementFilter>;

    /**
     * 
     * @summary Update Filter
     * @param {string} filterId 
     * @param {SupplierFilterInput} [supplierFilterInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    updateSupplierFilterRaw(requestParameters: UpdateSupplierFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredSupplierFilter>>;

    /**
     * Update Filter
     */
    updateSupplierFilter(requestParameters: UpdateSupplierFilterRequest, initOverrides?: RequestInit): Promise<StoredSupplierFilter>;

}

/**
 * 
 */
export class FiltersApi extends runtime.BaseAPI implements FiltersApiInterface {

    /**
     * Create a Filter
     */
    async createAgreementFilterRaw(requestParameters: CreateAgreementFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredAgreementFilter>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreementFilters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgreementFilterInputToJSON(requestParameters.agreementFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredAgreementFilterFromJSON(jsonValue));
    }

    /**
     * Create a Filter
     */
    async createAgreementFilter(requestParameters: CreateAgreementFilterRequest = {}, initOverrides?: RequestInit): Promise<StoredAgreementFilter> {
        const response = await this.createAgreementFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Filter
     */
    async createSupplierFilterRaw(requestParameters: CreateSupplierFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredSupplierFilter>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/supplierFilters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierFilterInputToJSON(requestParameters.supplierFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredSupplierFilterFromJSON(jsonValue));
    }

    /**
     * Create a Filter
     */
    async createSupplierFilter(requestParameters: CreateSupplierFilterRequest = {}, initOverrides?: RequestInit): Promise<StoredSupplierFilter> {
        const response = await this.createSupplierFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a filter
     */
    async deleteAgreementFilterRaw(requestParameters: DeleteAgreementFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling deleteAgreementFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreementFilters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a filter
     */
    async deleteAgreementFilter(requestParameters: DeleteAgreementFilterRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAgreementFilterRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a filter
     */
    async deleteSupplierFilterRaw(requestParameters: DeleteSupplierFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling deleteSupplierFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/supplierFilters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a filter
     */
    async deleteSupplierFilter(requestParameters: DeleteSupplierFilterRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteSupplierFilterRaw(requestParameters, initOverrides);
    }

    /**
     * List Filters
     */
    async getAgreementFiltersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListFilterResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreementFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFilterResponseFromJSON(jsonValue));
    }

    /**
     * List Filters
     */
    async getAgreementFilters(initOverrides?: RequestInit): Promise<ListFilterResponse> {
        const response = await this.getAgreementFiltersRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Filters
     */
    async getSupplierFiltersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListSupplierFilterResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/supplierFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSupplierFilterResponseFromJSON(jsonValue));
    }

    /**
     * List Filters
     */
    async getSupplierFilters(initOverrides?: RequestInit): Promise<ListSupplierFilterResponse> {
        const response = await this.getSupplierFiltersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Filter
     */
    async updateAgreementFilterRaw(requestParameters: UpdateAgreementFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredAgreementFilter>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling updateAgreementFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreementFilters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgreementFilterInputToJSON(requestParameters.agreementFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredAgreementFilterFromJSON(jsonValue));
    }

    /**
     * Update Filter
     */
    async updateAgreementFilter(requestParameters: UpdateAgreementFilterRequest, initOverrides?: RequestInit): Promise<StoredAgreementFilter> {
        const response = await this.updateAgreementFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Filter
     */
    async updateSupplierFilterRaw(requestParameters: UpdateSupplierFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoredSupplierFilter>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling updateSupplierFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/supplierFilters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierFilterInputToJSON(requestParameters.supplierFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredSupplierFilterFromJSON(jsonValue));
    }

    /**
     * Update Filter
     */
    async updateSupplierFilter(requestParameters: UpdateSupplierFilterRequest, initOverrides?: RequestInit): Promise<StoredSupplierFilter> {
        const response = await this.updateSupplierFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
