import {
  Agreement,
  GlobalSearchResultType,
  Status,
  Supplier,
} from './api/schema';
import Features from './Features';
import { MessageKeys } from './translations/messageKeys';

export const SESSION_TIMEOUT_WARNING_MS = 1000 * 60 * 2;

export const DATE_FMT = 'yyyy-MM-dd';

export const DEFAULT_PAGE_SIZE = 40;

export const NONE_SELECT_ITEM = {
  label: '<None>',
  key: null,
};

export const DEPLOYMENT_MODEL_OPTIONS = [
  'Public',
  'Private',
  'Hybrid',
  'Community',
];

export const STATUS_SELECT_ITEM = [
  { key: 'notSigned', label: 'Not signed' },
  { key: 'signedByBothParties', label: 'Signed by both parties' },
  {
    key: 'signedByInternalEntity',
    label: 'Signed by internal contractual party',
  },
  { key: 'signedBySupplier', label: 'Signed by supplier' },
  {
    key: 'noticedByInternalEntity',
    label: 'Notice given by internal contractual party',
  },
  {
    key: 'noticedBySupplier',
    label: 'Notice given by supplier',
  },
  {
    key: 'terminatedByInternalEntity',
    label: 'Terminated by internal contractual party',
  },
  {
    key: 'terminatedBySupplier',
    label: 'Terminated by supplier',
  },
  {
    key: 'terminated',
    label: 'Terminated',
  },
];

export const SUBCONTRACTOR_SKELETON = {
  // required id field will will be set to a random uuidv4 on create.
  parentId: null,
  categoryOfActivity: null, // nullable uuid
  briefDescription: '',
  countriesWhereServiceIsPerformed: [],
  countriesOrRegionsWhereDataIsStored: [],
  supplier: {
    isExternal: true,
    externalSupplier: null, // nullable uuid
    internalSupplier: null, // nullable uuid
  },
};

export const EMPTY_AGREEMENT: Agreement = {
  details: {
    name: '',
    status: Features.AgreementStatusDefault as Status,
    term: {},
    briefDescription: '',
    agreementType: null,
    partyToAgreement: null,
    internalReference: [],
    mainSupplier: {
      isExternal: true,
      externalSupplier: null,
      internalSupplier: null,
    },
    receiversOfProvidedService: [],
    annualBudgetCostEstimation: {
      amount: '',
      currency: '',
    },
  },
  personalData: {
    role: null,
    dataProtectionIncluded: false,
    isPersonalDataProcessed: null,
    isPersonalDataTransferred: null,
    countriesWhereDataIsTransferred: [],
  },
  serviceDetails: {
    data: {
      deploymentModel: null,
      natureOfData: '',
    },
    subcontractors: [],
    isProvidedAsCloudService: null,
    countriesWhereServiceIsPerformed: [],
    countriesOrRegionsWhereDataIsStored: [],
  },
  npap: {
    data: {
      npapCompleted: null,
      npapDescription: '',
    },
    npapRequired: null,
  },
  changeManagementProcess: {
    data: {
      completed: null,
      summary: '',
    },
    required: null,
  },
  relatedAgreements: {
    hasParent: null,
  },
  agreementAssessment: {
    data: {
      functionCategory: null,
      assessmentCompleted: null,
      latestAssessment: null,
      summaryOfAssessment: '',
      competentAuthorityNotified: null,
      competentAuthorityNotifiedDate: null,
      competentAuthorityNotifiedDescription: '',
      isCriticalOrImportant: null,
      criticalOrImportant: {
        nextAudit: null,
        latestAudit: null,
        exitStrategy: '',
        finalApproval: '',
        latestRiskAssessment: null,
        timeCriticalOperation: null,
        riskAssessmentCompleted: null,
        alternativeServiceProviders: '',
        latestRiskAssessmentSummary: '',
      },
    },
    isOutsourcingArrangement: null,
  },
};

export const EMPTY_SUPPLIER: Supplier = {
  supplierName: '',
  description: '',
  corporateRegistrationNumber: '',
  legalEntityIdentifier: '',
  countryOfRegistration: '',
  registeredAddress: '',
  contactDetails: [],
  isUltimateParent: false,
  parentCompany: '',
  supplierAssessment: {
    isDueDiligenceConducted: null,
    latestDueDiligence: null,
    isRiskAssessmentCompleted: null,
    latestRiskAssessment: null,
    isCSRSurveyRequired: null,
    isCSRSurveyCompleted: null,
    latestCSRSurvey: null,
    isFollowCodeOfConductRequired: null,
    isFollowCodeOfConductConfirmed: null,
    isInformationSecurityRequired: null,
    isInformationSecurityCompleted: null,
  },
};

export const INTERNAL_ENTITY_SKELETON = {
  data: {
    name: '',
    description: '',
    corporateRegistrationNumber: '',
    legalEntityIdentifier: '',
    countryOfRegistration: '',
    registeredAddress: '',
    contactDetails: [],
    isUltimateParent: false,
    parentCompany: '',
  },
};

export const SUPPLIER_SKELETON = {
  data: {
    supplierName: '',
    description: '',
    corporateRegistrationNumber: '',
    legalEntityIdentifier: '',
    countryOfRegistration: '',
    registeredAddress: '',
    contactDetails: [],
    isUltimateParent: false,
    parentCompany: '',
    countriesOrRegionsWhereDataIsStored: [],
    supplierAssessment: {
      isDueDiligenceConducted: null,
      latestDueDiligence: '',
      isRiskAssessmentCompleted: null,
      latestRiskAssessment: '',
      isCSRSurveyRequired: null,
      isCSRSurveyCompleted: null,
      latestCSRSurvey: '',
      isFollowCodeOfConductRequired: null,
      isFollowCodeOfConductConfirmed: null,
      isInformationSecurityRequired: null,
      isInformationSecurityCompleted: null,
    },
  },
};

export const CATEGORY_SKELETON = {
  data: {
    name: '',
  },
};

export const REPORT_SKELETON = {
  data: {
    name: '',
    entity: 'agreements',
    query: '',
  },
};

export const GLOBAL_SEARCH_RESULT_TYPE_MESSAGE_KEYS = {
  [GlobalSearchResultType.Agreement]: MessageKeys.LabelAgreement,
  [GlobalSearchResultType.Supplier]: MessageKeys.LabelSupplier,
  [GlobalSearchResultType.AgreementAttachment]:
    MessageKeys.GlobalSearchAgreementAttachmentLabel,
  [GlobalSearchResultType.SupplierAttachment]:
    MessageKeys.GlobalSearchSupplierAttachmentLabel,
};

export const PORTAL_ROOT_SELECTOR = '#portal-root';

export const NOTIFICATIONS_OASYS = 'Oasys';

/**
 * @deprecated - use EMPTY_AGREEMENT instead
 */
export const AGREEMENT_SKELETON = {
  data: {
    details: {
      name: '',
      status: Features.AgreementStatusDefault,
      term: {},
      briefDescription: '',
      agreementType: null,
      partyToAgreement: null,
      internalReference: [],
      mainSupplier: {
        isExternal: true,
        externalSupplier: null,
        internalSupplier: null,
      },
      receiversOfProvidedService: [],
      annualBudgetCostEstimation: {
        amount: '',
        currency: '',
      },
    },
    personalData: {
      role: null,
      dataProtectionIncluded: false,
      isPersonalDataProcessed: null,
      isPersonalDataTransferred: null,
      countriesWhereDataIsTransferred: [],
    },
    serviceDetails: {
      data: {
        deploymentModel: null,
        natureOfData: '',
      },
      subcontractors: [],
      isProvidedAsCloudService: null,
      countriesWhereServiceIsPerformed: [],
      countriesOrRegionsWhereDataIsStored: [],
    },
    npap: {
      data: {
        npapCompleted: null,
        npapDescription: '',
      },
      npapRequired: null,
    },
    changeManagementProcess: {
      data: {
        completed: null,
        summary: '',
      },
      required: null,
    },
    relatedAgreements: {
      hasParent: null,
    },
    agreementAssessment: {
      data: {
        functionCategory: null,
        assessmentCompleted: null,
        latestAssessment: null,
        summaryOfAssessment: '',
        competentAuthorityNotified: null,
        competentAuthorityNotifiedDate: null,
        competentAuthorityNotifiedDescription: '',
        isCriticalOrImportant: null,
        criticalOrImportant: {
          nextAudit: null,
          latestAudit: null,
          exitStrategy: '',
          finalApproval: '',
          latestRiskAssessment: null,
          timeCriticalOperation: null,
          riskAssessmentCompleted: null,
          alternativeServiceProviders: '',
          latestRiskAssessmentSummary: '',
        },
      },
      isOutsourcingArrangement: null,
    },
  },
};
