import { CheckCircleIcon } from '@fcg-tech/regtech-components';
import { DataTableCellProps } from '@fcg-tech/regtech-datatable';
import React, { FunctionComponent } from 'react';
import { AgreementListItem, AgreementTableColumns } from '../../types';
import {
  formatBoolean,
  formatDate,
  formatStatus,
} from '../../utils/formatters';
import {
  AgreementTableIconContent,
  AgreementTableTextContent,
} from './AgreementTable.styles';

type AgreementTableCellProps = DataTableCellProps<AgreementListItem>;

export const AgreementTableCell: FunctionComponent<AgreementTableCellProps> = ({
  cell,
  row,
}) => {
  const agreement = row.original;

  switch (cell.column.id) {
    case AgreementTableColumns.Name:
      return (
        <AgreementTableTextContent title={agreement?.name}>
          {agreement?.name}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.AgreementType:
      return (
        <AgreementTableTextContent title={agreement?.agreementType}>
          {agreement?.agreementType}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.FunctionCategory:
      return (
        <AgreementTableTextContent title={agreement?.functionCategory}>
          {agreement?.functionCategory}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.BriefDescription:
      return (
        <AgreementTableTextContent title={agreement?.briefDescription}>
          {agreement?.briefDescription}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.ContractOwner:
      return (
        <AgreementTableTextContent title={agreement?.contractOwner}>
          {agreement?.contractOwner}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.SupplierName:
      return (
        <AgreementTableTextContent title={agreement?.supplierName}>
          {agreement?.supplierName}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.PartyToAgreement:
      return (
        <AgreementTableTextContent title={agreement?.partyToAgreement}>
          {agreement?.partyToAgreement}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.InternalReference:
      return (
        <AgreementTableTextContent title={agreement?.internalReference}>
          {agreement?.internalReference}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.Cabinet:
      return (
        <AgreementTableTextContent title={agreement?.cabinet}>
          {agreement?.cabinet}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.Status:
      return (
        <AgreementTableTextContent title={agreement?.status}>
          {formatStatus(agreement?.status)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.StartDate:
      return (
        <AgreementTableTextContent>
          {formatDate(agreement?.startDate)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.EndDate:
      return (
        <AgreementTableTextContent>
          {formatDate(agreement.endDate)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.LatestReview:
      return (
        <AgreementTableTextContent>
          {formatDate(agreement.latestReview)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.NextReview:
      return (
        <AgreementTableTextContent>
          {formatDate(agreement.nextReview)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.ArchivingReference:
      return (
        <AgreementTableTextContent title={agreement?.archivingReference}>
          {agreement?.archivingReference}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.CompetentAuthorityNotified:
      return (
        <AgreementTableTextContent>
          {formatBoolean(agreement.competentAuthorityNotified)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.IsOutsourcingArrangement:
      return (
        <AgreementTableTextContent>
          {formatBoolean(agreement.isOutsourcingArrangement)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.IsCritical:
      return agreement.isCritical ? (
        <AgreementTableIconContent>
          <CheckCircleIcon color="#e52c3e" size="16" />
        </AgreementTableIconContent>
      ) : null;

    case AgreementTableColumns.NrOfComments:
      return agreement.nrOfComments && agreement.nrOfComments > 0 ? (
        <AgreementTableIconContent>
          <span>{agreement.nrOfComments}</span>
          <CheckCircleIcon color="#fec10e" size="16" />
        </AgreementTableIconContent>
      ) : null;

    case AgreementTableColumns.IsPersonalDataTransferred:
      return (
        <AgreementTableTextContent>
          {formatBoolean(agreement.isPersonalDataTransferred)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.IsPersonalDataProcessed:
      return (
        <AgreementTableTextContent>
          {formatBoolean(agreement.isPersonalDataProcessed)}
        </AgreementTableTextContent>
      );

    case AgreementTableColumns.Tags:
      return (
        <AgreementTableTextContent>
          {agreement.tags?.length
            ? agreement.tags
                .sort((a: string, b: string) => a?.localeCompare(b))
                .join(', ')
            : null}
        </AgreementTableTextContent>
      );
  }
};
