/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  InfoCircle,
  Label,
} from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DateField,
  TextArea,
  TextField,
  Select,
} from '@fcg-tech/regtech-formik-components';

import {
  agreementPropType,
  internalEntityPropType,
  attachmentPropType,
  metadataPropType,
  supplierPropType,
} from '../../../propTypes';
import { NONE_SELECT_ITEM } from '../../../constants';
import { getTooltip } from '../../../utils/tooltipHelpers';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../../translations/messageKeys';
import { Attachments } from '../../Attachments';
import { FieldErrorNonBlocking } from '../../FieldError/FieldErrorNonBlocking';
import { FieldCompare } from '../../FieldCompare/FieldCompare';

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ReadOnlyValue = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  padding: 1rem 1rem 1rem 0;
  margin-left: 1.2rem;
`;

const ReadOnlyInfoCircle = styled(InfoCircle)`
  margin-left: 4rem;
`;

export const AgreementAssessmentSection = ({
  agreement,
  metadata,
  categories = [],
  attachments = [],
  internalEntities = [],
  suppliers = [],
}) => {
  const { t } = useTranslation();
  const internalOrExternalSupplierWithName = React.useMemo(() => {
    const { mainSupplier } = agreement.details || {};
    if (mainSupplier?.isExternal) {
      const { externalSupplier } = mainSupplier;
      const supplier = suppliers.find(
        (item) => item.metadata.id === externalSupplier,
      );
      return supplier ? `No: ${supplier?.data?.supplierName}` : 'No';
    }
    const { internalSupplier } = mainSupplier || {};
    const institution = internalEntities.find(
      (item) => item.metadata.id === internalSupplier,
    );
    return institution ? `Yes: ${institution?.name}` : 'Yes';
  }, [agreement, suppliers, internalEntities]);

  const categoryItems = React.useMemo(
    () =>
      [NONE_SELECT_ITEM].concat(
        categories.map((category) => ({
          key: category.metadata.id,
          label: category.name,
        })),
      ),
    [categories],
  );

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.AgreementAssessmentSectionLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(
                    MessageKeys.AgreementAssessmentSectionOutsourcingArrangementLabel,
                  )}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="agreementAssessment.isOutsourcingArrangement"
                  info={getTooltip(
                    'agreementAssessment.isOutsourcingArrangement',
                  )}
                />
              </BooleanDecision.FieldContainer>
              <FieldErrorNonBlocking name="agreementAssessment.isOutsourcingArrangement" />
              <FieldCompare name="agreementAssessment.isOutsourcingArrangement" />
            </FormGroup>
          </FormColumn>
          <FormColumn />
        </FormRow>
        {agreement.agreementAssessment.isOutsourcingArrangement && (
          <>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor="agreement-function-category">
                    {t(MessageKeys.AgreementAssessmentSectionCategoryLabel)}
                  </Label>
                  <Field
                    component={Select}
                    items={categoryItems}
                    typeahead
                    name="agreementAssessment.data.functionCategory"
                    id="agreement-function-category"
                    info={getTooltip(
                      'agreementAssessment.data.functionCategory',
                    )}
                  />
                  <FieldCompare
                    name="agreementAssessment.data.functionCategory"
                    nameOptions={categoryItems}
                  />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <BooleanDecision.FieldContainer>
                    <BooleanDecision.FieldLabel>
                      {t(
                        MessageKeys.AgreementAssessmentSectionCriticalImportantCompletedLabel,
                      )}
                    </BooleanDecision.FieldLabel>
                    <Field
                      component={BooleanDecision}
                      contractInfo
                      info={getTooltip(
                        'agreementAssessment.data.assessmentCompleted',
                      )}
                      name="agreementAssessment.data.assessmentCompleted"
                    />
                  </BooleanDecision.FieldContainer>
                  <FieldCompare name="agreementAssessment.data.assessmentCompleted" />
                </FormGroup>
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label
                    column
                    htmlFor="latest-critical-or-important-assessment"
                  >
                    {t(
                      MessageKeys.AgreementAssessmentSectionLastCriticalImportantLabel,
                    )}
                  </Label>
                  <Field
                    component={DateField}
                    name="agreementAssessment.data.latestAssessment"
                    id="latest-critical-or-important-assessment"
                    info={getTooltip(
                      'agreementAssessment.data.latestAssessment',
                    )}
                  />
                  <FieldCompare name="agreementAssessment.data.latestAssessment" />
                </FormGroup>
              </FormColumn>
              <FormColumn />
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <Label column htmlFor="assessment-summary">
                    {t(
                      MessageKeys.AgreementAssessmentSectionAssessmentSummaryLabel,
                    )}
                  </Label>
                  <Field
                    component={TextArea}
                    name="agreementAssessment.data.summaryOfAssessment"
                    id="assessment-summary"
                    info={getTooltip(
                      'agreementAssessment.data.summaryOfAssessment',
                    )}
                  />
                  <FieldCompare name="agreementAssessment.data.summaryOfAssessment" />
                </FormGroup>
              </FormColumn>
            </FormRow>
            {metadata && (
              <Attachments
                attachments={attachments}
                section="criticalOrImportantAssessment"
              />
            )}
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <BooleanDecision.FieldContainer>
                    <BooleanDecision.FieldLabel>
                      {t(
                        MessageKeys.AgreementAssessmentSectionCompetentAuthorityNotifiedLabel,
                      )}
                    </BooleanDecision.FieldLabel>
                    <Field
                      contractInfo
                      component={BooleanDecision}
                      name="agreementAssessment.data.competentAuthorityNotified"
                      info={getTooltip(
                        'agreementAssessment.data.competentAuthorityNotified',
                      )}
                    />
                    <FieldErrorNonBlocking name="agreementAssessment.data.competentAuthorityNotified" />
                  </BooleanDecision.FieldContainer>
                  <FieldCompare name="agreementAssessment.data.competentAuthorityNotified" />
                </FormGroup>
              </FormColumn>
            </FormRow>
            {agreement.agreementAssessment.data.competentAuthorityNotified && (
              <>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="competent-authority-notified-date">
                        {t(
                          MessageKeys.AgreementAssessmentSectionCompetentAuthorityNotifiedDateLabel,
                        )}
                      </Label>
                      <Field
                        component={DateField}
                        name="agreementAssessment.data.competentAuthorityNotifiedDate"
                        id="competent-authority-notified-date"
                        info={getTooltip(
                          'agreementAssessment.data.competentAuthorityNotifiedDate',
                        )}
                      />
                      <FieldCompare name="agreementAssessment.data.competentAuthorityNotifiedDate" />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn />
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label
                        column
                        htmlFor="competent-authority-notified-description"
                      >
                        {t(
                          MessageKeys.AgreementAssessmentSectionCompetentAuthorityDescriptionLabel,
                        )}
                      </Label>
                      <Field
                        component={TextArea}
                        name="agreementAssessment.data.competentAuthorityNotifiedDescription"
                        id="competent-authority-notified-description"
                        info={getTooltip(
                          'agreementAssessment.data.competentAuthorityNotifiedDescription',
                        )}
                      />
                      <FieldCompare name="agreementAssessment.data.competentAuthorityNotifiedDescription" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>

                {metadata && (
                  <Attachments
                    attachments={attachments}
                    section="criticalOrImportantAuthorityNotified"
                  />
                )}
              </>
            )}
            <FormRow>
              <FormColumn>
                <FormGroup>
                  <BooleanDecision.FieldContainer>
                    <BooleanDecision.FieldLabel>
                      {t(
                        MessageKeys.AgreementAssessmentSectionCriticalImportantLabel,
                      )}
                    </BooleanDecision.FieldLabel>
                    <Field
                      component={BooleanDecision}
                      contractInfo
                      info={getTooltip(
                        'agreementAssessment.data.isCriticalOrImportant',
                      )}
                      name="agreementAssessment.data.isCriticalOrImportant"
                    />
                  </BooleanDecision.FieldContainer>
                  <FieldCompare name="agreementAssessment.data.isCriticalOrImportant" />
                </FormGroup>
              </FormColumn>
            </FormRow>
            {agreement.agreementAssessment.data.isCriticalOrImportant && (
              <>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <FlexContainer>
                        <Label>
                          {t(
                            MessageKeys.AgreementAssessmentSectionSupplierInstitutionGroupLabel,
                          )}
                        </Label>
                        <ReadOnlyValue
                          info={getTooltip(
                            'agreementAssessment.data.criticalOrImportant.internal',
                          )}
                        >
                          {internalOrExternalSupplierWithName}
                          {
                            <ReadOnlyInfoCircle
                              info={getTooltip(
                                'agreementAssessment.data.criticalOrImportant.internal',
                              )}
                            />
                          }
                        </ReadOnlyValue>
                      </FlexContainer>
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.internal" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <BooleanDecision.FieldContainer>
                        <BooleanDecision.FieldLabel>
                          {t(
                            MessageKeys.AgreementAssessmentSectionRiskAssessmentCompletedLabel,
                          )}
                        </BooleanDecision.FieldLabel>
                        <Field
                          component={BooleanDecision}
                          contractInfo
                          info={getTooltip(
                            'agreementAssessment.data.criticalOrImportant.riskAssessmentCompleted',
                          )}
                          name="agreementAssessment.data.criticalOrImportant.riskAssessmentCompleted"
                        />
                        <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.riskAssessmentCompleted" />
                      </BooleanDecision.FieldContainer>
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.riskAssessmentCompleted" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="latest-risk-assessment">
                        {t(
                          MessageKeys.AgreementAssessmentSectionLatestRiskAssessmentLabel,
                        )}
                      </Label>
                      <Field
                        component={DateField}
                        name="agreementAssessment.data.criticalOrImportant.latestRiskAssessment"
                        id="latest-risk-assessment"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.latestRiskAssessment',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.latestRiskAssessment" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.latestRiskAssessment" />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="next-risk-assessment">
                        {t(
                          MessageKeys.AgreementAssessmentSectionNextRiskAssessmentLabel,
                        )}
                      </Label>
                      <Field
                        component={DateField}
                        name="agreementAssessment.data.criticalOrImportant.nextRiskAssessment"
                        id="next-risk-assessment"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.nextRiskAssessment',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.nextRiskAssessment" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.nextRiskAssessment" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="risk-assessment-summary">
                        {t(
                          MessageKeys.AgreementAssessmentSectionRiskAssessmentSummaryLabel,
                        )}
                      </Label>
                      <Field
                        component={TextArea}
                        name="agreementAssessment.data.criticalOrImportant.latestRiskAssessmentSummary"
                        id="risk-assessment-summary"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.latestRiskAssessmentSummary',
                        )}
                      />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.latestRiskAssessmentSummary" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                {metadata && (
                  <Attachments
                    attachments={attachments}
                    section="criticalOrImportantRiskAssesment"
                  />
                )}
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="final-approval">
                        {t(
                          MessageKeys.AgreementAssessmentSectionFinalApprovalLabel,
                        )}
                      </Label>
                      <Field
                        component={TextField}
                        name="agreementAssessment.data.criticalOrImportant.finalApproval"
                        id="final-approval"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.finalApproval',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.finalApproval" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.finalApproval" />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn />
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="latest-audit">
                        {t(
                          MessageKeys.AgreementAssessmentSectionLatestAuditLabel,
                        )}
                      </Label>
                      <Field
                        component={DateField}
                        name="agreementAssessment.data.criticalOrImportant.latestAudit"
                        id="latest-audit"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.latestAudit',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.latestAudit" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.latestAudit" />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="next-audit">
                        {t(
                          MessageKeys.AgreementAssessmentSectionNextAuditLabel,
                        )}
                      </Label>
                      <Field
                        component={DateField}
                        name="agreementAssessment.data.criticalOrImportant.nextAudit"
                        id="next-audit"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.nextAudit',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.nextAudit" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.nextAudit" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="audit-notes">
                        {t(
                          MessageKeys.AgreementAssessmentSectionAuditNotesLabel,
                        )}
                      </Label>
                      <Field
                        component={TextArea}
                        name="agreementAssessment.data.criticalOrImportant.latestAuditNotes"
                        id="audit-notes"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.latestAuditNotes',
                        )}
                      />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.latestAuditNotes" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                {metadata && (
                  <Attachments
                    attachments={attachments}
                    section="criticalOrImportantAuditReport"
                  />
                )}
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="exit-strategy">
                        {t(
                          MessageKeys.AgreementAssessmentSectionExitStrategyLabel,
                        )}
                      </Label>
                      <Field
                        component={TextArea}
                        name="agreementAssessment.data.criticalOrImportant.exitStrategy"
                        id="exit-strategy"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.exitStrategy',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.exitStrategy" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.exitStrategy" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <Label column htmlFor="alternative-service-providers">
                        {t(
                          MessageKeys.AgreementAssessmentSectionAlternativeServiceProviderLabel,
                        )}
                      </Label>
                      <Field
                        component={TextArea}
                        name="agreementAssessment.data.criticalOrImportant.alternativeServiceProviders"
                        id="alternative-service-providers"
                        info={getTooltip(
                          'agreementAssessment.data.criticalOrImportant.alternativeServiceProviders',
                        )}
                      />
                      <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.alternativeServiceProviders" />
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.alternativeServiceProviders" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                {metadata && (
                  <Attachments
                    attachments={attachments}
                    section="criticalOrImportantExitStrategy"
                  />
                )}
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <BooleanDecision.FieldContainer>
                        <BooleanDecision.FieldLabel>
                          {t(
                            MessageKeys.AgreementAssessmentSectionTimeCriticalOperationLabel,
                          )}
                        </BooleanDecision.FieldLabel>
                        <Field
                          component={BooleanDecision}
                          contractInfo
                          name="agreementAssessment.data.criticalOrImportant.timeCriticalOperation"
                          info={getTooltip(
                            'agreementAssessment.data.criticalOrImportant.timeCriticalOperation',
                          )}
                        />
                        <FieldErrorNonBlocking name="agreementAssessment.data.criticalOrImportant.timeCriticalOperation" />
                      </BooleanDecision.FieldContainer>
                      <FieldCompare name="agreementAssessment.data.criticalOrImportant.timeCriticalOperation" />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

AgreementAssessmentSection.propTypes = {
  agreement: agreementPropType,
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  suppliers: PropTypes.arrayOf(supplierPropType),
  metadata: metadataPropType,
  attachments: PropTypes.arrayOf(attachmentPropType),
};

AgreementAssessmentSection.defaultProps = {
  agreement: {},
  metadata: {},
  attachments: [],
  internalEntities: [],
  suppliers: [],
};
