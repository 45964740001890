/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AgreementType,
    AgreementTypeFromJSON,
    AgreementTypeToJSON,
    GetAgreementTypeResponse,
    GetAgreementTypeResponseFromJSON,
    GetAgreementTypeResponseToJSON,
    ListAllAgreementTypesResponse,
    ListAllAgreementTypesResponseFromJSON,
    ListAllAgreementTypesResponseToJSON,
} from '../models';

export interface CreateAgreementTypeRequest {
    agreementType?: AgreementType;
}

export interface DeleteAgreementTypeRequest {
    agreementTypeId: string;
}

export interface GetAgreementTypeRequest {
    agreementTypeId: string;
}

export interface UpdateAgreementTypeRequest {
    agreementTypeId: string;
    agreementType?: AgreementType;
}

/**
 * AgreementTypesApi - interface
 * 
 * @export
 * @interface AgreementTypesApiInterface
 */
export interface AgreementTypesApiInterface {
    /**
     * 
     * @summary Create an AgreementType
     * @param {AgreementType} [agreementType] item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTypesApiInterface
     */
    createAgreementTypeRaw(requestParameters: CreateAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Create an AgreementType
     */
    createAgreementType(requestParameters: CreateAgreementTypeRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Delete a single agreement type
     * @param {string} agreementTypeId The id of the agreement type to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTypesApiInterface
     */
    deleteAgreementTypeRaw(requestParameters: DeleteAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a single agreement type
     */
    deleteAgreementType(requestParameters: DeleteAgreementTypeRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Get an AgreementType
     * @param {string} agreementTypeId The id of the agreement type to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTypesApiInterface
     */
    getAgreementTypeRaw(requestParameters: GetAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAgreementTypeResponse>>;

    /**
     * Get an AgreementType
     */
    getAgreementType(requestParameters: GetAgreementTypeRequest, initOverrides?: RequestInit): Promise<GetAgreementTypeResponse>;

    /**
     * 
     * @summary List all agreement types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTypesApiInterface
     */
    listAllAgreementTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllAgreementTypesResponse>>;

    /**
     * List all agreement types
     */
    listAllAgreementTypes(initOverrides?: RequestInit): Promise<ListAllAgreementTypesResponse>;

    /**
     * 
     * @summary Update an AgreementType
     * @param {string} agreementTypeId The id of the agreement type to retrieve
     * @param {AgreementType} [agreementType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementTypesApiInterface
     */
    updateAgreementTypeRaw(requestParameters: UpdateAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Update an AgreementType
     */
    updateAgreementType(requestParameters: UpdateAgreementTypeRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class AgreementTypesApi extends runtime.BaseAPI implements AgreementTypesApiInterface {

    /**
     * Create an AgreementType
     */
    async createAgreementTypeRaw(requestParameters: CreateAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreementTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgreementTypeToJSON(requestParameters.agreementType),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an AgreementType
     */
    async createAgreementType(requestParameters: CreateAgreementTypeRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.createAgreementTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a single agreement type
     */
    async deleteAgreementTypeRaw(requestParameters: DeleteAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementTypeId === null || requestParameters.agreementTypeId === undefined) {
            throw new runtime.RequiredError('agreementTypeId','Required parameter requestParameters.agreementTypeId was null or undefined when calling deleteAgreementType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreementTypes/{agreementTypeId}`.replace(`{${"agreementTypeId"}}`, encodeURIComponent(String(requestParameters.agreementTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single agreement type
     */
    async deleteAgreementType(requestParameters: DeleteAgreementTypeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAgreementTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Get an AgreementType
     */
    async getAgreementTypeRaw(requestParameters: GetAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAgreementTypeResponse>> {
        if (requestParameters.agreementTypeId === null || requestParameters.agreementTypeId === undefined) {
            throw new runtime.RequiredError('agreementTypeId','Required parameter requestParameters.agreementTypeId was null or undefined when calling getAgreementType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreementTypes/{agreementTypeId}`.replace(`{${"agreementTypeId"}}`, encodeURIComponent(String(requestParameters.agreementTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgreementTypeResponseFromJSON(jsonValue));
    }

    /**
     * Get an AgreementType
     */
    async getAgreementType(requestParameters: GetAgreementTypeRequest, initOverrides?: RequestInit): Promise<GetAgreementTypeResponse> {
        const response = await this.getAgreementTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all agreement types
     */
    async listAllAgreementTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllAgreementTypesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreementTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAgreementTypesResponseFromJSON(jsonValue));
    }

    /**
     * List all agreement types
     */
    async listAllAgreementTypes(initOverrides?: RequestInit): Promise<ListAllAgreementTypesResponse> {
        const response = await this.listAllAgreementTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an AgreementType
     */
    async updateAgreementTypeRaw(requestParameters: UpdateAgreementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementTypeId === null || requestParameters.agreementTypeId === undefined) {
            throw new runtime.RequiredError('agreementTypeId','Required parameter requestParameters.agreementTypeId was null or undefined when calling updateAgreementType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreementTypes/{agreementTypeId}`.replace(`{${"agreementTypeId"}}`, encodeURIComponent(String(requestParameters.agreementTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgreementTypeToJSON(requestParameters.agreementType),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an AgreementType
     */
    async updateAgreementType(requestParameters: UpdateAgreementTypeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAgreementTypeRaw(requestParameters, initOverrides);
    }

}
