import {
  Checkbox,
  DateField,
  IconButton,
  Label,
  UnstyledButton,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

const padding = css`
  padding: 1.8rem 2.4rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 34rem;
  width: 34rem;
  height: 100%;
  max-height: 100%;
  background-color: white;
  isolation: isolate;
`;

export const FilterRowList = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const FilterStickyHeader = styled.div`
  position: sticky;
  z-index: 20;
  top: 0;
  display: flex;
  align-items: center;
  background-color: white;
  ${padding}

  &.scrolled {
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  }
`;

export const FilterRow = styled.div`
  ${padding};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &:last-child {
    margin-bottom: 5rem;
  }

  &.slim {
    padding-bottom: 0.4rem;
    padding-top: 0.4rem;
  }
`;

export const FilterRowContent = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterRowItem = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

export const FilterClearButton = styled.span.attrs({
  role: 'button',
})`
  ${({ theme }) => css`
    color: ${theme?.palette?.MainColorAccent.dark ?? 'rgba(0, 228, 228, 1)'};
    padding: 0.1rem;
    font-size: 1.3rem;
    cursor: pointer;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.4 !important;
      cursor: inherit;
    }
  `}
`;

export const FilterClearAllButton = styled(FilterClearButton)`
  display: block;
  width: 100%;
  flex: 1;
  text-align: center;
  padding: 1rem 0.3rem;
  opacity: 0.8;
  font-weight: 600;
`;

export const FilterRowLabel = styled.label`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > ${FilterClearButton} {
  }
`;

export const FilterRowLabelLeft = styled.span`
  display: flex;
  align-items: center;
`;

export const FilterExcludeWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FilterExcludeLabel = styled.label`
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:not(.checked) {
    color: #555;
  }
`;

export const FilterRowLabelButton = styled(IconButton)`
  margin-left: 1rem;
  width: 2.8rem;
  height: 2.8rem;
`;

export const FilterRowCheckboxLabel = styled(FilterRowLabel)`
  justify-content: flex-start;
`;

export const FilterCheckboxCheckbox = styled(Checkbox)`
  margin-right: 0.8rem;
`;

export const FilterExcludeCheckbox = styled(Checkbox)`
  margin-left: 1rem;
`;

export const FilterButtons = styled.div`
  ${padding};
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;

  & > .button + .button {
    margin-left: 1.6rem;
  }
`;

export const FilterDateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterDateRangeLabel = styled(Label)`
  justify-content: space-around;
  & + & {
    margin-left: 1rem;
  }
`;

export const FilterDateRangeLabelText = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
`;

export const FilterDateInput = styled(DateField)`
  margin-left: 0.32rem;
  font-size: 1.2rem;

  & .date-field-text-input {
    padding: 0.8rem;
    height: auto;
  }
`;

export const FilterDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterDateLabel = styled(Label)`
  justify-content: space-around;
  & + & {
    margin-left: 1rem;
  }
`;

export const FilterDateLabelText = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
`;

export const FilterNoItemsMessage = styled.span`
  font-style: italic;
  font-size: 1.3rem;
`;

export const FilterActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const FilterActionsRowWrapper = styled.div`
  position: relative;
`;

export const FilterActionsRowButton = styled(UnstyledButton)`
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

export const FilterActionRowIconWrapper = styled.span`
  margin-left: 0.6rem;
`;

export const FilterStoredFilterSelectOptionWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const FilterStoredFilterSelectIconWrapper = styled.span`
  margin-right: 0.6rem;
  position: relative;
  top: 0.1rem;
`;

export const FilterSelectMenuLoading = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 0.6rem;
  background-color: white;
  padding: 1.6rem;
  text-align: center;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
  color: rgba(0, 0, 0, 0.4);
`;
