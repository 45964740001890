import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useEffect, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  SideBarContent,
  SideBarHeader,
  SideBarLogoWrapper,
  SideBarWrapper,
} from './SideBar.styles';
import { SideBarContext, useSideBar } from './sideBarContext';
import { SideBarHamburgerButton } from './SideBarHamburgerButton';

type LogoComponent = () => JSX.Element;

export interface SideBarProps {
  className?: string;
  collapsible?: boolean;
  initiallyHidden?: boolean;
  logo?: false | LogoComponent;
  closeFloatingMenuOnClick?: boolean;
}

export const SideBar: FunctionComponent<PropsWithChildren<SideBarProps>> = ({
  children,
  className,
  collapsible,
  logo: Logo,
  closeFloatingMenuOnClick,
}) => {
  const sidebarContext = useSideBar();
  const location = useLocation();

  const {
    isHidden,
    setIsHidden,
    isFloatingMenuVisible,
    setIsFloatingMenuVisible,
    aniamteFloatingMenu,
    setAnimateFloatingMenu,
  } = sidebarContext;

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setIsFloatingMenuVisible((old) => {
        if (old) {
          return false;
        }

        return old;
      });
    }, 200);
  }, [setIsFloatingMenuVisible]);

  const handleClickHamburger = useCallback(() => {
    setIsHidden((old) => !old);
    setAnimateFloatingMenu(false);
    setTimeout(() => {
      setIsFloatingMenuVisible(false);
    }, 500);
  }, [setAnimateFloatingMenu, setIsFloatingMenuVisible, setIsHidden]);

  const handleMouseEnter = useCallback(() => {
    if (isHidden) {
      setIsFloatingMenuVisible(true);
      setTimeout(() => setAnimateFloatingMenu(true), 0);
    }
  }, [isHidden, setAnimateFloatingMenu, setIsFloatingMenuVisible]);

  const handleMouseLeave = useCallback(() => {
    setIsFloatingMenuVisible(false);
    setAnimateFloatingMenu(false);
  }, [setAnimateFloatingMenu, setIsFloatingMenuVisible]);

  useEffect(() => {
    if (closeFloatingMenuOnClick) {
      return handleClose;
    }
    return undefined;
  }, [closeFloatingMenuOnClick, handleClose, location.pathname]);

  return (
    <>
      <SideBarWrapper
        className={classNames(className, isHidden ? 'collapsed' : null)}
        onMouseEnter={handleMouseEnter}
      >
        <SideBarHeader>
          {Logo !== false ? (
            <SideBarLogoWrapper>
              <Link to="/">
                <Logo />
              </Link>
            </SideBarLogoWrapper>
          ) : null}
          {collapsible ? (
            <SideBarHamburgerButton onClick={handleClickHamburger} />
          ) : null}
        </SideBarHeader>
        <SideBarContent>{children}</SideBarContent>
      </SideBarWrapper>
      {isFloatingMenuVisible ? (
        <SideBarContext.Provider
          value={{ ...sidebarContext, isFloating: true }}
        >
          <SideBarWrapper
            className={classNames(
              className,
              'floating',
              aniamteFloatingMenu ? 'floating-animate' : null,
            )}
            onMouseLeave={handleMouseLeave}
          >
            <SideBarHeader>
              {Logo !== false ? (
                <SideBarLogoWrapper>
                  <Link to="/">{Logo ? <Logo /> : <SideBarLogoWrapper />}</Link>
                </SideBarLogoWrapper>
              ) : null}
              {collapsible ? (
                <SideBarHamburgerButton onClick={handleClickHamburger} />
              ) : null}
            </SideBarHeader>
            <SideBarContent>{children}</SideBarContent>
          </SideBarWrapper>
        </SideBarContext.Provider>
      ) : null}
    </>
  );
};
