/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateAgreementAttachmentUploadUrlRequest,
    CreateAgreementAttachmentUploadUrlRequestFromJSON,
    CreateAgreementAttachmentUploadUrlRequestToJSON,
    FileInfoWithS3Url,
    FileInfoWithS3UrlFromJSON,
    FileInfoWithS3UrlToJSON,
    ListAllAgreementAttachmentsResponse,
    ListAllAgreementAttachmentsResponseFromJSON,
    ListAllAgreementAttachmentsResponseToJSON,
    RenameAgreementAttachmentRequest,
    RenameAgreementAttachmentRequestFromJSON,
    RenameAgreementAttachmentRequestToJSON,
} from '../models';

export interface CreateAgreementAttachmentDownloadUrlRequest {
    agreementId: string;
    attachmentId: string;
}

export interface CreateAgreementAttachmentUploadUrlOperationRequest {
    agreementId: string;
    agreementVersion?: number;
    createAgreementAttachmentUploadUrlRequest?: CreateAgreementAttachmentUploadUrlRequest;
}

export interface DeleteAgreementAttachmentRequest {
    agreementId: string;
    attachmentId: string;
}

export interface ListAllAgreementAttachmentsRequest {
    agreementId: string;
    agreementVersion?: number;
}

export interface RenameAgreementAttachmentOperationRequest {
    agreementId: string;
    attachmentId: string;
    renameAgreementAttachmentRequest?: RenameAgreementAttachmentRequest;
}

/**
 * AgreementAttachmentsApi - interface
 * 
 * @export
 * @interface AgreementAttachmentsApiInterface
 */
export interface AgreementAttachmentsApiInterface {
    /**
     * Create a download url
     * @param {string} agreementId The id of the agreement to retrieve tags from
     * @param {string} attachmentId The id of the agreement to retrieve attachment from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementAttachmentsApiInterface
     */
    createAgreementAttachmentDownloadUrlRaw(requestParameters: CreateAgreementAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Create a download url
     */
    createAgreementAttachmentDownloadUrl(requestParameters: CreateAgreementAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url>;

    /**
     * 
     * @summary Create a presigned upload url where the file can be uploaded to
     * @param {string} agreementId The id of the agreement to retrieve attachment from
     * @param {number} [agreementVersion] An additional parameter to find files for a historical revision of an agreement
     * @param {CreateAgreementAttachmentUploadUrlRequest} [createAgreementAttachmentUploadUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementAttachmentsApiInterface
     */
    createAgreementAttachmentUploadUrlRaw(requestParameters: CreateAgreementAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Create a presigned upload url where the file can be uploaded to
     */
    createAgreementAttachmentUploadUrl(requestParameters: CreateAgreementAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url>;

    /**
     * 
     * @summary Delete an attachement
     * @param {string} agreementId The id of the agreement to retrieve tags from
     * @param {string} attachmentId The id of the agreement to retrieve attachment from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementAttachmentsApiInterface
     */
    deleteAgreementAttachmentRaw(requestParameters: DeleteAgreementAttachmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete an attachement
     */
    deleteAgreementAttachment(requestParameters: DeleteAgreementAttachmentRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary List all attachments that belongs to an agreements
     * @param {string} agreementId The id of the agreement to retrieve attachment from
     * @param {number} [agreementVersion] An additional parameter to find files for a historical revision of an agreement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementAttachmentsApiInterface
     */
    listAllAgreementAttachmentsRaw(requestParameters: ListAllAgreementAttachmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllAgreementAttachmentsResponse>>;

    /**
     * List all attachments that belongs to an agreements
     */
    listAllAgreementAttachments(requestParameters: ListAllAgreementAttachmentsRequest, initOverrides?: RequestInit): Promise<ListAllAgreementAttachmentsResponse>;

    /**
     * Rename the attachment
     * @param {string} agreementId The id of the agreement to retrieve tags from
     * @param {string} attachmentId The id of the agreement to retrieve attachment from
     * @param {RenameAgreementAttachmentRequest} [renameAgreementAttachmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementAttachmentsApiInterface
     */
    renameAgreementAttachmentRaw(requestParameters: RenameAgreementAttachmentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Rename the attachment
     */
    renameAgreementAttachment(requestParameters: RenameAgreementAttachmentOperationRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class AgreementAttachmentsApi extends runtime.BaseAPI implements AgreementAttachmentsApiInterface {

    /**
     * Create a download url
     */
    async createAgreementAttachmentDownloadUrlRaw(requestParameters: CreateAgreementAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createAgreementAttachmentDownloadUrl.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling createAgreementAttachmentDownloadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/attachments/{attachmentId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Create a download url
     */
    async createAgreementAttachmentDownloadUrl(requestParameters: CreateAgreementAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url> {
        const response = await this.createAgreementAttachmentDownloadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a presigned upload url where the file can be uploaded to
     */
    async createAgreementAttachmentUploadUrlRaw(requestParameters: CreateAgreementAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createAgreementAttachmentUploadUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.agreementVersion !== undefined) {
            queryParameters['agreementVersion'] = requestParameters.agreementVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/attachments`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAgreementAttachmentUploadUrlRequestToJSON(requestParameters.createAgreementAttachmentUploadUrlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Create a presigned upload url where the file can be uploaded to
     */
    async createAgreementAttachmentUploadUrl(requestParameters: CreateAgreementAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url> {
        const response = await this.createAgreementAttachmentUploadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an attachement
     */
    async deleteAgreementAttachmentRaw(requestParameters: DeleteAgreementAttachmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling deleteAgreementAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteAgreementAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/attachments/{attachmentId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an attachement
     */
    async deleteAgreementAttachment(requestParameters: DeleteAgreementAttachmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAgreementAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * List all attachments that belongs to an agreements
     */
    async listAllAgreementAttachmentsRaw(requestParameters: ListAllAgreementAttachmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllAgreementAttachmentsResponse>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAllAgreementAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.agreementVersion !== undefined) {
            queryParameters['agreementVersion'] = requestParameters.agreementVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/attachments`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAgreementAttachmentsResponseFromJSON(jsonValue));
    }

    /**
     * List all attachments that belongs to an agreements
     */
    async listAllAgreementAttachments(requestParameters: ListAllAgreementAttachmentsRequest, initOverrides?: RequestInit): Promise<ListAllAgreementAttachmentsResponse> {
        const response = await this.listAllAgreementAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rename the attachment
     */
    async renameAgreementAttachmentRaw(requestParameters: RenameAgreementAttachmentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling renameAgreementAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling renameAgreementAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreements/{agreementId}/attachments/{attachmentId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RenameAgreementAttachmentRequestToJSON(requestParameters.renameAgreementAttachmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rename the attachment
     */
    async renameAgreementAttachment(requestParameters: RenameAgreementAttachmentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.renameAgreementAttachmentRaw(requestParameters, initOverrides);
    }

}
