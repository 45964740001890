import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import styled from 'styled-components';
import {
  ArchiveIcon,
  Card,
  CardBody,
  CardTitle,
  IconButton,
  NotArchiveIcon,
  PageHeader,
  PageHeaderActions,
  PageLayout,
  PageLayoutPaddedContent,
  SecondaryButton,
  TooltipNext as Tooltip,
  useAccessControl,
  useEditContext,
  ConfirmDialog,
  PrimaryButton,
  PageHeaderActionButton,
  CabinetIcon,
  useToggle,
} from '@fcg-tech/regtech-components';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useTranslation } from 'react-i18next';
import { History, Trash2 } from 'lucide-react';
import { MessageKeys } from '../../../translations/messageKeys';
import {
  agreementPropType,
  categoryPropType,
  internalEntityPropType,
  attachmentPropType,
} from '../../../propTypes';
import { useTenant } from '../../../states/tenantState';
import { routes } from '../../../routes';
import { Attachments } from '../../../components/Attachments';
import { DirtyFormCheck } from '../../../components/DirtyFormCheck';
import { ValidationSummary } from '../../../components/ValidationSummary';
import { ValidationErrorsSummary } from '../../../components/ValidationErrorsSummary';
import { TagPicker } from '../../../components/TagPicker';
import { DetailsSection } from '../../../components/editorsections/agreements/DetailsSection';
import { NPAPSection } from '../../../components/editorsections/agreements/NPAPSection';
import { PersonalDataSection } from '../../../components/editorsections/agreements/PersonalDataSection';
import { ServiceDetailsSection } from '../../../components/editorsections/agreements/ServiceDetailsSection';
import { RelatedAgreementsSection } from '../../../components/editorsections/agreements/RelatedAgreementsSection';
import { AgreementAssessmentSection } from '../../../components/editorsections/agreements/AgreementAssessmentSection';
import { ChangeManagementProcessSection } from '../../../components/editorsections/agreements/ChangeManagementProcessSection';
import { formatZonedDateTime } from '../../../utils/formatters';
import { validateAgreementNonBlocking } from '../../../validations/validateAgreementNonBlocking';
import { validateAgreementSchema } from '../../../validations/validateAgreementSchema';
import { ValidationErrorsProvider } from '../../../components/FormikNonBlockingContext';
import Features from '../../../Features';
import { CabinetMoveModal } from '../../../components/modals/CabinetMoveModal';

const TagPickerWrapper = styled.div`
  margin-bottom: 2.4rem;
`;

export const EditAgreementPage = ({
  agreement,
  isArchived,
  agreements,
  attachments,
  selectedTags,
  // eslint-disable-next-line react/prop-types
  onToggleTag,
  tags,
  categories,
  internalEntities,
  agreementTypes,
  suppliers,
  isSaving,
  showForceSaveDialog,
  onCloseForceSaveDialog,
  onEdit,
  onCancel,
  onSave,
  onDelete,
  onArchive,
  onAddSupplier,
}) => {
  const { t } = useTranslation();
  const isEditEnabled = useEditContext();
  const tenant = useTenant();
  const tenantId = tenant.id;
  const [showCabinetMoveModal, , toggleCabinetMoveModal] = useToggle();

  const canEdit = useAccessControl(agreement.permissions, [
    'oasys:AgreementEdit',
  ]);

  const canDelete = useAccessControl(agreement.permissions, [
    'oasys:AgreementDelete',
  ]);

  const canMoveCabinet = useAccessControl(agreement.permissions, [
    'oasys:AgreementMoveCabinet',
  ]);

  const canArchive = useAccessControl(agreement.permissions, [
    'oasys:AgreementArchive',
  ]);

  const canViewHistory = useAccessControl(agreement.permissions, [
    'oasys:AgreementHistory',
  ]);

  const pageTitle = React.useMemo(() => {
    if (isArchived) {
      return (
        agreement.data.details.name +
          ` (${t(MessageKeys.EditAgreementArchivedHeadingLabel)})` ||
        t(MessageKeys.LabelUnnamed)` (${t(
          MessageKeys.EditAgreementArchivedHeadingLabel,
        )})`
      );
    } else {
      return agreement.data.details.name || t(MessageKeys.LabelUnnamed);
    }
  }, [agreement.data.details.name, t, isArchived]);

  const [nonBlockingErrors, setNonBlockingErrors] = useState([]);

  const handleValidate = useCallback(
    (values) => {
      const nonBlockingErrors = validateAgreementNonBlocking(values, t);
      setNonBlockingErrors(nonBlockingErrors);
    },
    [setNonBlockingErrors, t],
  );

  const handleArchiveClick = useCallback(() => {
    onArchive?.(!isArchived);
  }, [isArchived, onArchive]);

  const handleCabinetMoveModalRequestClose = useCallback(() => {
    toggleCabinetMoveModal();
  }, [toggleCabinetMoveModal]);

  return (
    <Formik
      initialValues={agreement.data}
      onSubmit={(values) =>
        onSave(values, agreement.metadata.version, nonBlockingErrors)
      }
      validate={handleValidate}
      validationSchema={validateAgreementSchema(t)}
      validateOnMount
      validateOnChange
    >
      {({
        values,
        initialValues,
        dirty,
        handleSubmit,
        resetForm,
        setFieldValue,
        validateForm,
      }) => (
        <PageLayout>
          {showForceSaveDialog ? (
            <ConfirmDialog
              title={t(MessageKeys.LabelWarning)}
              body={t(MessageKeys.EditAgreementContractOwnerWarningLabel)}
              confirmText={t(MessageKeys.LabelCancel)}
              cancelText={t(MessageKeys.LabelProceedSaving)}
              onChoice={(choice) => {
                if (!choice) {
                  onSave(
                    values,
                    agreement.metadata.version,
                    nonBlockingErrors,
                    true,
                  );
                } else {
                  onCloseForceSaveDialog();
                }
              }}
            />
          ) : null}
          {showCabinetMoveModal ? (
            <CabinetMoveModal
              fromCabinet={agreement?.metadata?.cabinet ?? ''}
              agreementId={agreement?.metadata?.id ?? ''}
              onRequestClose={handleCabinetMoveModalRequestClose}
            />
          ) : null}
          <DirtyFormCheck dirty={dirty && !isSaving && isEditEnabled} />
          <PageHeader
            title={pageTitle}
            breadcrumbs={[]}
            isArchived={isArchived}
          >
            {isEditEnabled ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    resetForm();
                    validateForm(initialValues);
                    onCancel();
                  }}
                >
                  {t(MessageKeys.LabelCancel)}
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={isSaving}
                >
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              </>
            ) : (
              <>
                <PageHeaderActions>
                  {!isArchived && canArchive ? (
                    <Tooltip
                      placement="bottom-end"
                      content={t(MessageKeys.EditAgreementArchivedLabel)}
                    >
                      <IconButton onClick={handleArchiveClick}>
                        <ArchiveIcon size="24" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="bottom-end"
                      content={t(MessageKeys.EditAgreementUnarchivedLabel)}
                    >
                      <IconButton onClick={handleArchiveClick}>
                        <NotArchiveIcon size="24" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {canViewHistory ? (
                    <Tooltip
                      html
                      content={t(MessageKeys.EditAgreementCurrentVersionLabel, {
                        version: agreement.metadata.version,
                        date: formatZonedDateTime(agreement.metadata.modified),
                        modifiedBy: agreement.metadata.modifiedBy,
                      })}
                      placement="bottom-end"
                    >
                      <PageHeaderActionButton
                        to={constructUrl(routes.agreementVersions, {
                          tenantId,
                          agreementId: agreement.metadata.id,
                        })}
                      >
                        <History size="24" />
                      </PageHeaderActionButton>
                    </Tooltip>
                  ) : null}
                  {!isArchived && canMoveCabinet ? (
                    <Tooltip
                      placement="bottom-end"
                      content={t(MessageKeys.EditAgreementCabinetMoveLabel)}
                    >
                      <PageHeaderActionButton onClick={toggleCabinetMoveModal}>
                        <CabinetIcon size="24" />
                      </PageHeaderActionButton>
                    </Tooltip>
                  ) : null}
                  {!isArchived && canDelete ? (
                    <PageHeaderActionButton onClick={onDelete}>
                      <Trash2 size="24" />
                    </PageHeaderActionButton>
                  ) : null}
                </PageHeaderActions>
                {!isArchived && canEdit ? (
                  <PrimaryButton onClick={onEdit}>
                    {t(MessageKeys.LabelEdit)}
                  </PrimaryButton>
                ) : null}
              </>
            )}
          </PageHeader>
          <PageLayoutPaddedContent>
            <ValidationErrorsSummary
              title={t(MessageKeys.EditAgreementUpdateRequiredFieldsLabel)}
            />
            <ValidationSummary
              title={t(MessageKeys.EditAgreementAdditionalInformationLabel)}
              nonBlockingErrors={nonBlockingErrors}
            />
            {isEditEnabled ? (
              tags &&
              tags.length > 0 && (
                <Card>
                  <CardBody>
                    <TagPicker
                      items={tags.map((tag) => ({
                        key: tag.name,
                        label: tag.name,
                      }))}
                      value={selectedTags}
                      onSelectItem={onToggleTag}
                    />
                  </CardBody>
                </Card>
              )
            ) : (
              <TagPickerWrapper>
                <TagPicker
                  isEditEnabled={false}
                  items={tags.map((tag) => ({
                    key: tag.name,
                    label: tag.name,
                  }))}
                  value={selectedTags}
                  onSelectItem={onToggleTag}
                />
              </TagPickerWrapper>
            )}
            <form onSubmit={handleSubmit}>
              <ValidationErrorsProvider value={nonBlockingErrors}>
                <DetailsSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                  internalEntities={internalEntities}
                  agreementTypes={agreementTypes}
                  suppliers={suppliers}
                  setFieldValue={setFieldValue}
                  onAddSupplier={onAddSupplier}
                />
                {Features.RelatedAgreements ? (
                  <RelatedAgreementsSection
                    tenantId={tenantId}
                    agreement={values}
                    relations={agreement.relations}
                    agreements={agreements}
                  />
                ) : null}
                <PersonalDataSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                  internalEntities={internalEntities}
                />
                <ServiceDetailsSection
                  agreement={values}
                  categories={categories}
                  internalEntities={internalEntities}
                  suppliers={suppliers}
                />
                <NPAPSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                />
                <ChangeManagementProcessSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                />
                <AgreementAssessmentSection
                  agreement={values}
                  metadata={agreement.metadata}
                  attachments={attachments}
                  internalEntities={internalEntities}
                  categories={categories}
                  suppliers={suppliers}
                />
              </ValidationErrorsProvider>
            </form>
            <Card>
              <CardBody>
                <CardTitle>{t(MessageKeys.LabelOtherDocumentation)}</CardTitle>
                {agreement.metadata && (
                  <Attachments section="other" attachments={attachments} />
                )}
              </CardBody>
            </Card>
          </PageLayoutPaddedContent>
        </PageLayout>
      )}
    </Formik>
  );
};

EditAgreementPage.propTypes = {
  agreement: agreementPropType.isRequired,
  isArchived: PropTypes.bool,
  agreements: PropTypes.arrayOf(agreementPropType),
  attachments: PropTypes.arrayOf(attachmentPropType),
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  categories: PropTypes.arrayOf(categoryPropType),
  // TODO: supplier prop type
  suppliers: PropTypes.arrayOf(PropTypes.shape({})),
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  isSaving: PropTypes.bool,
  showForceSaveDialog: PropTypes.bool,
  onCloseForceSaveDialog: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onArchive: PropTypes.func,
  onAddSupplier: PropTypes.func,
};

EditAgreementPage.defaultProps = {
  agreements: [],
  isArchived: false,
  attachments: [],
  selectedTags: [],
  tags: [],
  categories: [],
  suppliers: [],
  internalEntities: [],
  isSaving: false,
  showForceSaveDialog: false,
  onCloseForceSaveDialog: null,
  onSave: null,
  onEdit: null,
  onCancel: null,
  onDelete: null,
  onArchive: null,
  onAddSupplier: null,
};
