/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InitData,
    InitDataFromJSON,
    InitDataToJSON,
} from '../models';

/**
 * InitApi - interface
 * 
 * @export
 * @interface InitApiInterface
 */
export interface InitApiInterface {
    /**
     * 
     * @summary Check if authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitApiInterface
     */
    checkAuthenticatedRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Check if authenticated
     */
    checkAuthenticated(initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Initialization data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitApiInterface
     */
    getInitDataRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InitData>>;

    /**
     * Initialization data
     */
    getInitData(initOverrides?: RequestInit): Promise<InitData>;

    /**
     * 
     * @summary Check backend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitApiInterface
     */
    pingRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Check backend
     */
    ping(initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class InitApi extends runtime.BaseAPI implements InitApiInterface {

    /**
     * Check if authenticated
     */
    async checkAuthenticatedRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protected`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Check if authenticated
     */
    async checkAuthenticated(initOverrides?: RequestInit): Promise<void> {
        await this.checkAuthenticatedRaw(initOverrides);
    }

    /**
     * Initialization data
     */
    async getInitDataRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InitData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/init`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InitDataFromJSON(jsonValue));
    }

    /**
     * Initialization data
     */
    async getInitData(initOverrides?: RequestInit): Promise<InitData> {
        const response = await this.getInitDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check backend
     */
    async pingRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Check backend
     */
    async ping(initOverrides?: RequestInit): Promise<void> {
        await this.pingRaw(initOverrides);
    }

}
