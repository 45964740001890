import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import { Translator } from '@fcg-tech/regtech-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgreementListItem } from '../../api/schema';
import { MessageKeys } from '../../translations/messageKeys';
import {
  AgreementTableColumns,
  AgreementTableType,
  StoredAgreementTableColumnOptions,
} from '../../types';
import { AgreementTableCell } from './AgreementTableCell';
import { useAgreementTableContext } from './AgreementTableContext';

export const getAgreementTableColumns = (
  tableType: AgreementTableType,
  t: Translator,
): Array<DataTableColumn<AgreementListItem>> => {
  const columns: Record<
    AgreementTableColumns,
    DataTableColumn<AgreementListItem>
  > = {
    [AgreementTableColumns.Name]: {
      id: AgreementTableColumns.Name,
      defaultCanSort: true,
      width: 300,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableNameLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.name,
    },

    [AgreementTableColumns.AgreementType]: {
      id: AgreementTableColumns.AgreementType,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableAgreementTypeLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.agreementType,
    },

    [AgreementTableColumns.FunctionCategory]: {
      id: AgreementTableColumns.FunctionCategory,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableFunctionCategoryLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.functionCategory,
    },

    [AgreementTableColumns.BriefDescription]: {
      id: AgreementTableColumns.BriefDescription,
      defaultCanSort: true,
      width: 300,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableBriefDescriptionLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.briefDescription,
    },

    [AgreementTableColumns.ContractOwner]: {
      id: AgreementTableColumns.ContractOwner,
      defaultCanSort: true,
      width: 200,
      minWidth: 100,
      Header: t(MessageKeys.AgreementTableContractOwnerLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.contractOwner,
    },

    [AgreementTableColumns.SupplierName]: {
      id: AgreementTableColumns.SupplierName,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableSupplierNameLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.supplierName,
    },

    [AgreementTableColumns.PartyToAgreement]: {
      id: AgreementTableColumns.PartyToAgreement,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTablePartyToAgreementLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.partyToAgreement,
    },

    [AgreementTableColumns.InternalReference]: {
      id: AgreementTableColumns.InternalReference,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableInternalReferenceLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.internalReference,
    },

    [AgreementTableColumns.Cabinet]: {
      id: AgreementTableColumns.Cabinet,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableCabinetLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.cabinet,
    },

    [AgreementTableColumns.Status]: {
      id: AgreementTableColumns.Status,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableStatusLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.status,
    },

    [AgreementTableColumns.StartDate]: {
      id: AgreementTableColumns.StartDate,
      defaultCanSort: true,
      width: 150,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableStartDateLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.startDate,
    },

    [AgreementTableColumns.EndDate]: {
      id: AgreementTableColumns.EndDate,
      defaultCanSort: true,
      width: 150,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableEndDateLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.endDate,
    },

    [AgreementTableColumns.LatestReview]: {
      id: AgreementTableColumns.LatestReview,
      defaultCanSort: true,
      width: 150,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableLatestReviewLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.latestReview,
    },

    [AgreementTableColumns.NextReview]: {
      id: AgreementTableColumns.NextReview,
      defaultCanSort: true,
      width: 150,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableNextReviewLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.nextReview,
    },

    [AgreementTableColumns.ArchivingReference]: {
      id: AgreementTableColumns.ArchivingReference,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.AgreementTableArchivingReferenceLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.archivingReference,
    },

    [AgreementTableColumns.CompetentAuthorityNotified]: {
      id: AgreementTableColumns.CompetentAuthorityNotified,
      defaultCanSort: true,
      width: 250,
      minWidth: 250,
      Header: t(MessageKeys.AgreementTableCompetentAuthorityNotifiedLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) =>
        agreement.competentAuthorityNotified,
    },

    [AgreementTableColumns.IsOutsourcingArrangement]: {
      id: AgreementTableColumns.IsOutsourcingArrangement,
      defaultCanSort: true,
      width: 200,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableIsOutsourcingArrangementLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) =>
        agreement.isOutsourcingArrangement,
    },

    [AgreementTableColumns.IsCritical]: {
      id: AgreementTableColumns.IsCritical,
      defaultCanSort: true,
      width: 200,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableIsCriticalLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.isCritical,
    },

    [AgreementTableColumns.NrOfComments]: {
      id: AgreementTableColumns.NrOfComments,
      defaultCanSort: true,
      width: 200,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableNrOfCommentsLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.nrOfComments,
    },

    [AgreementTableColumns.IsPersonalDataTransferred]: {
      id: AgreementTableColumns.IsPersonalDataTransferred,
      defaultCanSort: true,
      width: 200,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableIsPersonalDataTransferredLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) =>
        agreement.isPersonalDataTransferred,
    },

    [AgreementTableColumns.IsPersonalDataProcessed]: {
      id: AgreementTableColumns.IsPersonalDataProcessed,
      defaultCanSort: true,
      width: 200,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableIsPersonalDataProcessedLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) =>
        agreement.isPersonalDataProcessed,
    },

    [AgreementTableColumns.Tags]: {
      id: AgreementTableColumns.Tags,
      defaultCanSort: true,
      width: 200,
      minWidth: 200,
      Header: t(MessageKeys.AgreementTableTagsLabel),
      Cell: AgreementTableCell,
      accessor: (agreement: AgreementListItem) => agreement.tags,
    },
  };

  switch (tableType) {
    case AgreementTableType.Overview:
      return [
        columns[AgreementTableColumns.Name],
        columns[AgreementTableColumns.AgreementType],
        columns[AgreementTableColumns.FunctionCategory],
        columns[AgreementTableColumns.BriefDescription],
        columns[AgreementTableColumns.ContractOwner],
        columns[AgreementTableColumns.SupplierName],
        columns[AgreementTableColumns.PartyToAgreement],
        columns[AgreementTableColumns.InternalReference],
        columns[AgreementTableColumns.Cabinet],
        columns[AgreementTableColumns.Status],
        columns[AgreementTableColumns.StartDate],
        columns[AgreementTableColumns.EndDate],
        columns[AgreementTableColumns.LatestReview],
        columns[AgreementTableColumns.NextReview],
        columns[AgreementTableColumns.ArchivingReference],
        columns[AgreementTableColumns.CompetentAuthorityNotified],
        columns[AgreementTableColumns.IsOutsourcingArrangement],
        columns[AgreementTableColumns.IsCritical],
        columns[AgreementTableColumns.NrOfComments],
        columns[AgreementTableColumns.IsPersonalDataTransferred],
        columns[AgreementTableColumns.IsPersonalDataProcessed],
        columns[AgreementTableColumns.Tags],
      ];

    default:
      return [];
  }
};

const columnOptionsKey = (key: AgreementTableType) => `${key}-options`;

export const saveAgreementTableColumnOptions = (
  key: AgreementTableType,
  options: Record<string, Partial<StoredAgreementTableColumnOptions>>,
) => {
  localStorage.setItem(columnOptionsKey(key), JSON.stringify(options));
};

export const clearAgreementTableColumnOptions = (key?: AgreementTableType) => {
  if (key) {
    localStorage.removeItem(columnOptionsKey(key));
  } else {
    Object.values(AgreementTableType).forEach((key) =>
      localStorage.removeItem(columnOptionsKey(key)),
    );
  }
};

export const loadAgreementTableColumnOptions = (
  key: AgreementTableType,
): Record<string, Partial<StoredAgreementTableColumnOptions>> => {
  const options = (JSON.parse(localStorage.getItem(columnOptionsKey(key))) ??
    {}) as Record<string, Partial<StoredAgreementTableColumnOptions>>;
  return options;
};

export const mergeTableColumnsAndOptions = (
  columnOptions: Record<string, Partial<StoredAgreementTableColumnOptions>>,
  mergeInto: Array<DataTableColumn<AgreementListItem>>,
): Array<DataTableColumn<AgreementListItem>> => {
  return mergeInto
    .map<DataTableColumn<AgreementListItem>>((m) => ({
      ...m,
      width: columnOptions?.[m.id]?.width ?? m.width,
    }))
    .filter((m) => columnOptions?.[m.id]?.visible ?? true);
};

export const useAgreementTableColumnOptions = (
  tableType: AgreementTableType,
): [
  columns: Array<DataTableColumn<AgreementListItem>>,
  handleColumnsChange: (
    columns: Array<DataTableColumn<AgreementListItem>>,
  ) => void,
  setColumns: React.Dispatch<
    React.SetStateAction<Array<DataTableColumn<AgreementListItem>>>
  >,
] => {
  const { t } = useTranslation();

  const { columnOptions, updateColumnOptions } = useAgreementTableContext();

  const allColumns = useMemo(
    () => getAgreementTableColumns(tableType, t),
    [t, tableType],
  );

  const [columns, setColumns] = useState<
    Array<DataTableColumn<AgreementListItem>>
  >(
    useMemo(
      () => mergeTableColumnsAndOptions(columnOptions, allColumns),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );

  useEffect(() => {
    setColumns(mergeTableColumnsAndOptions(columnOptions, allColumns));
  }, [allColumns, columnOptions]);

  const handleColumnsChange = useCallback(
    (updatedColumns: Array<DataTableColumn<AgreementListItem>>) => {
      updateColumnOptions((previousColumnOptions) => {
        const updatedOptions = Object.fromEntries(
          updatedColumns.map<
            [string, Partial<StoredAgreementTableColumnOptions>]
          >((col) => [
            col.id,
            {
              width: col.width,
              visible: previousColumnOptions?.[col.id]?.visible ?? true,
            },
          ]),
        );

        return {
          ...previousColumnOptions,
          ...updatedOptions,
        };
      });
    },
    [updateColumnOptions],
  );

  return [columns, handleColumnsChange, setColumns];
};
