import { messages as filterMessages } from '@fcg-tech/regtech-filter';
import { plural } from '@fcg-tech/regtech-utils';
import { MessageKeys, TranslationLocale } from './messageKeys';

const en: TranslationLocale = {
  language: 'en',
  messages: {
    [MessageKeys.LabelActions]: 'Actions',
    [MessageKeys.LabelAdd]: 'Add',
    [MessageKeys.LabelAgreement]: 'Agreement',
    [MessageKeys.LabelAgreements]: 'Agreements',
    [MessageKeys.LabelAgreementType]: 'Agreement type',
    [MessageKeys.LabelAll]: 'All',
    [MessageKeys.LabelArchived]: 'Archived',
    [MessageKeys.LabelCabinet]: 'Cabinet',
    [MessageKeys.LabelCancel]: 'Cancel',
    [MessageKeys.LabelCategory]: 'Category',
    [MessageKeys.LabelClear]: 'Clear',
    [MessageKeys.LabelCompleted]: 'Completed',
    [MessageKeys.LabelConfirm]: 'Confirm',
    [MessageKeys.LabelConfirmAction]: 'Please confirm',
    [MessageKeys.LabelConfirmLabel]: 'Confirm',
    [MessageKeys.LabelConfirmDelete]: 'Yes, delete it',
    [MessageKeys.LabelConfirmed]: 'Confirmed',
    [MessageKeys.LabelContractOwner]: 'Contract Owner',
    [MessageKeys.LabelCouldNotSave]: 'An error occured, changes were not saved',
    [MessageKeys.LabelCurrent]: 'Current',
    [MessageKeys.LabelDelete]: 'Delete',
    [MessageKeys.LabelDescription]: 'Description',
    [MessageKeys.LabelDetails]: 'Details',
    [MessageKeys.LabelDisabled]: 'Disabled',
    [MessageKeys.LabelDownloadCSV]: 'Download CSV',
    [MessageKeys.LabelEdit]: 'Edit',
    [MessageKeys.LabelEnabled]: 'Enabled',
    [MessageKeys.LabelErrorOccured]:
      'An error occured, changes may not have been saved',
    [MessageKeys.LabelExclude]: 'Exclude',
    [MessageKeys.LabelExport]: 'Export',
    [MessageKeys.LabelFilename]: 'Filename',
    [MessageKeys.LabelHistory]: 'History',
    [MessageKeys.LabelIntragroupAgreements]: 'Intragroup agreements',
    [MessageKeys.LabelLoadingAgreement]: 'Loading agreement',
    [MessageKeys.LabelLoadingCabinet]: 'Loading cabinet',
    [MessageKeys.LabelLoadingCalendar]: 'Loading calendar',
    [MessageKeys.LabelLoadingCategory]: 'Loading category',
    [MessageKeys.LabelLoadingDashboard]: 'Loading dashboard',
    [MessageKeys.LabelLoadingDetails]: 'Loading details',
    [MessageKeys.LabelLoadingInternalEntity]: 'Loading internal entity',
    [MessageKeys.LabelLoadingReport]: 'Loading report',
    [MessageKeys.LabelLoadingTag]: 'Loading tag',
    [MessageKeys.LabelLogout]: 'Logout',
    [MessageKeys.LabelLogin]: 'Login',
    [MessageKeys.LabelMarkAsDone]: 'Mark as done',
    [MessageKeys.LabelMove]: 'Move',
    [MessageKeys.LabelName]: 'Name',
    [MessageKeys.LabelNo]: 'No',
    [MessageKeys.LabelNoAgreementsFound]: 'No agreements found',
    [MessageKeys.LabelNoItems]: 'No items found',
    [MessageKeys.LabelNotArchived]: 'Not archived',
    [MessageKeys.LabelOk]: 'Ok',
    [MessageKeys.LabelOtherDocumentation]: 'Other documentation',
    [MessageKeys.LabelPrint]: 'Print',
    [MessageKeys.LabelProceed]: 'Yes, proceed',
    [MessageKeys.LabelProceedSaving]: 'Yes, proceed with saving',
    [MessageKeys.LabelReports]: 'Reports',
    [MessageKeys.LabelRequired]: 'Required',
    [MessageKeys.LabelSave]: 'Save',
    [MessageKeys.LabelSearchResultSummary]:
      '<bold>{{totalResults}}</bold> items in total found',
    [MessageKeys.LabelSomethingWentWrong]: 'Something went wrong',
    [MessageKeys.LabelSubmit]: 'Submit',
    [MessageKeys.LabelSummary]: 'Summary',
    [MessageKeys.LabelSupplier]: 'Supplier',
    [MessageKeys.LabelUnknown]: '(Unknown)',
    [MessageKeys.LabelUnnamed]: '(Unnamed)',
    [MessageKeys.LabelUploaded]: 'Uploaded',
    [MessageKeys.LabelUploadFiles]: 'Upload file(s)',
    [MessageKeys.LabelUploadedLowerCase]: 'uploaded',
    [MessageKeys.LabelVersion]: 'Version',
    [MessageKeys.LabelWarning]: 'Warning',
    [MessageKeys.LabelYes]: 'Yes',

    [MessageKeys.ErrorGeneric]:
      'An unknown error occurred. Please try again later',

    [MessageKeys.DocumentationLinkTooltip]:
      'Click here to open the Oasys user guide',

    [MessageKeys.AgreementHistoryHeading]: '{{agreement}}: Agreement history',

    [MessageKeys.AgreementFilterIncludeArchivedTooltip]:
      'Click to include archived agreements',
    [MessageKeys.AgreementFilterExcludeArchivedTooltip]:
      'Click to exclude archived agreements',
    [MessageKeys.AgreementFilterColumnPickerTooltip]:
      'Click to show more/less columns',

    [MessageKeys.AgreementsOverviewHeading]: 'Agreements overview',
    [MessageKeys.AgreementsOverviewCreateNewAgreementLabel]: 'Create agreement',
    [MessageKeys.AgreementsOverviewCreateAgreementFailure]:
      'Failed to create agreement',
    [MessageKeys.AgreementsOverviewCreateAgreementSuccess]:
      'Successfully created new agreement',
    [MessageKeys.AgreementsOverviewCreateAgreementConfirmForceQuestion]:
      'If you create the agreement without adding a “tag” that you have access to, you will lose access to the agreement. Are you sure you want to proceed?',
    [MessageKeys.AgreementsOverviewCreateAgreementNameRequired]:
      'Agreement name is required',
    [MessageKeys.AgreementsOverviewCreateAgreementCabinetRequired]:
      'Cabinet is required',
    [MessageKeys.AgreementsOverviewCreateAgreementName]: 'Agreement name',
    [MessageKeys.AgreementsOverviewCreateAgreementCabinet]: 'Cabinet',
    [MessageKeys.AgreementsOverviewCreateAgreementTags]: 'Tags',

    [MessageKeys.AgreementTableNameLabel]: 'Agreement name',
    [MessageKeys.AgreementTableAgreementTypeLabel]: 'Agreement type',
    [MessageKeys.AgreementTableFunctionCategoryLabel]: 'Category',
    [MessageKeys.AgreementTableBriefDescriptionLabel]: 'Brief description',
    [MessageKeys.AgreementTableContractOwnerLabel]: 'Contract Owner',
    [MessageKeys.AgreementTableSupplierNameLabel]: 'Supplier',
    [MessageKeys.AgreementTablePartyToAgreementLabel]: 'Party',
    [MessageKeys.AgreementTableInternalReferenceLabel]: 'Internal reference',
    [MessageKeys.AgreementTableCabinetLabel]: 'Cabinet',
    [MessageKeys.AgreementTableStatusLabel]: 'Status',
    [MessageKeys.AgreementTableStartDateLabel]: 'Start date',
    [MessageKeys.AgreementTableEndDateLabel]: 'End date',
    [MessageKeys.AgreementTableLatestReviewLabel]: 'Latest review',
    [MessageKeys.AgreementTableNextReviewLabel]: 'Next review',
    [MessageKeys.AgreementTableArchivingReferenceLabel]: 'Archiving Reference',
    [MessageKeys.AgreementTableCompetentAuthorityNotifiedLabel]:
      'Competent authority notified',
    [MessageKeys.AgreementTableIsOutsourcingArrangementLabel]:
      'Outsourcing arrangement',
    [MessageKeys.AgreementTableIsCriticalLabel]: 'Critical or important',
    [MessageKeys.AgreementTableNrOfCommentsLabel]: 'Additional info. required',
    [MessageKeys.AgreementTableIsPersonalDataTransferredLabel]:
      'Personal data transferred',
    [MessageKeys.AgreementTableIsPersonalDataProcessedLabel]:
      'Personal data processed',
    [MessageKeys.AgreementTableTagsLabel]: 'Tags',
    [MessageKeys.AgreementTableNoItems]: 'No agreements found',
    [MessageKeys.AgreementTableShowCommentsLabel]:
      'Show additional info. required',

    [MessageKeys.AgreementTypesHeading]: 'Agreement types',
    [MessageKeys.AgreementTypesCreateNewAgreementTypeLabel]:
      'Create agreement type',
    [MessageKeys.AgreementTypesCreateAgreementTypeFailure]:
      'Failed to create agreement type',
    [MessageKeys.AgreementTypesCreateAgreementTypeSuccess]:
      'Successfully created new agreement type',
    [MessageKeys.AgreementTypesNoItems]: 'No agreement types found',

    [MessageKeys.AttachmentTableFilenameLabel]: 'Filename',
    [MessageKeys.AttachmentTableUploadedLabel]: 'Uploaded',
    [MessageKeys.AttachmentTableNoItems]: 'No attachments found',
    [MessageKeys.AttachmentTableConfirmDeleteQuestion]:
      'Are you sure you want to delete this attachment?',

    [MessageKeys.CabinetsHeading]: 'Cabinets',
    [MessageKeys.CabinetsCreateNewCabinetLabel]: 'Create cabinet',
    [MessageKeys.CabinetsCreateCabinetFailure]: 'Failed to create cabinet',
    [MessageKeys.CabinetsCreateCabinetSuccess]:
      'Successfully created new cabinet',
    [MessageKeys.CabinetsNoItems]: 'No cabinets found',

    [MessageKeys.CategoriesHeading]: 'Categories',
    [MessageKeys.CategoriesDescription]:
      'Categories are used for outsourcing arrangements. A category should be assigned to reflect the nature of the function outsourced to facilitate the identification of different types of arrangements.',
    [MessageKeys.CategoriesCreateNewCategoryLabel]: 'Create category',
    [MessageKeys.CabinetsCreateCabinetNameFormatTooltip]:
      'Your new cabinet will be created as <b>{{cabinetName}}</b>',
    [MessageKeys.CategoriesCreateCategoryFailure]: 'Failed to create category',
    [MessageKeys.CategoriesCreateCategorySuccess]:
      'Successfully created new category',
    [MessageKeys.CategoriesNoItems]: 'No categories found',

    [MessageKeys.DashboardAllCaughtUp]: 'All caught up!',
    [MessageKeys.DashboardTotalAgreementsCount]:
      '<count>{{count}}</count> total agreements',
    [MessageKeys.DashboardComplianceHeading]:
      'Agreements requiring additional input',
    [MessageKeys.DashboardComplianceAdditionalInfoRequired]:
      'Additional info. required',
    [MessageKeys.DashboardComplianceOutsourcingArrangementNotSet]:
      'Outsourcing arr. not set',
    [MessageKeys.DashboardComplianceCriticalOrImportantNotSet]:
      'Critical or important not set',
    [MessageKeys.DashboardSummaryHeading]: 'Summary agreements',
    [MessageKeys.DashboardSummaryCriticalOrImportant]: 'Critical or important',
    [MessageKeys.DashboardSummaryOutsourcingArrangement]:
      'Outsourcing arrangement',
    [MessageKeys.DashboardUpcomingYearHeading]:
      'Agreements with actions upcoming 12 months',
    [MessageKeys.DashboardUpcomingYearAuditRequired]: 'Audit required',
    [MessageKeys.DashboardUpcomingYearNumberOfAgreements]: 'No. of agreements',
    [MessageKeys.DashboardHoistGettingStartedHeading]: 'Instruction video',
    [MessageKeys.DashboardHoistGettingStartedBody]:
      'Instruction on how to upload agreements, including Q&A session',
    [MessageKeys.DashboardNotificationCount]:
      '<count>{{countStr}}</count> unread notifications',
    [MessageKeys.DashboardAndCountMoreNotifications]:
      '...and {{count}} more notifications',
    [MessageKeys.DashboardViewAllNotifications]: 'View all notifications',
    [MessageKeys.DashboardViewAllAgreements]: 'View all agreements',

    [MessageKeys.GlobalSearchHeading]: 'Search results for',
    [MessageKeys.GlobalSearchTypeLabel]: 'Type',
    [MessageKeys.GlobalSearchNameLabel]: 'Name',
    [MessageKeys.GlobalSearchContentLabel]: 'Content',
    [MessageKeys.GlobalSearchNoItems]: 'No results found',
    [MessageKeys.DocumentationLinkTooltip]:
      'Click here to open the Oasys user guide',

    [MessageKeys.ErrorAccessDenied]: 'Missing permissions',
    [MessageKeys.ErrorAccessDeniedMessage]:
      'You do not have permission to view the content of this site. Please contact your system support administrator.',
    [MessageKeys.ErrorGeneric]:
      'An unknown error occurred. Please try again later',
    [MessageKeys.ErrorNotFoundHeading]:
      'Hmm, we could not find what you were looking for',
    [MessageKeys.ErrorNotFoundInfo]:
      'The page you requested could not be found. Please check the address and try again. If you believe this resource should exist, please contact support.',
    [MessageKeys.ErrorReportToSupportMessage]:
      'If you choose to contact support, please supply them with the error identifier code below',

    [MessageKeys.FileUploadZoneActiveMessage]: 'Drag file(s) here',
    [MessageKeys.FileUploadZoneHoverMessage]: 'Drop the file(s) to upload',
    [MessageKeys.FileUploadZoneDragFilesHintLabel]:
      'Hint: You can also drag file(s) here to upload them',
    [MessageKeys.FileUploadZoneSelectFilesButtonLabel]: 'Select file(s)',
    [MessageKeys.FileUploadZoneUploadErrorMessage]: 'Failed to upload',
    [MessageKeys.FileUploadZoneUploadInProgressMessage]: 'Uploading file(s)...',
    [MessageKeys.FileUploadZoneConfirmRemoveFileMessage]:
      'Are your sure you wish to remove this file?',
    [MessageKeys.FileUploadZoneNoItems]: 'No files found',

    [MessageKeys.FilterSaveSuccess]: 'Successfully saved filter',
    [MessageKeys.FilterSaveFailure]: 'Failed to save filter',
    [MessageKeys.FilterDeleteSuccess]: 'Successfully deleted filter',
    [MessageKeys.FilterDeleteFailure]: 'Failed to delete filter',
    [MessageKeys.FilterSaveFilterButtonLabel]: 'Save as new filter',
    [MessageKeys.FilterSaveFilterNameLabel]: 'Filter name',
    [MessageKeys.FilterConfirmDeleteQuestion]:
      'Are you sure you want to delete this filter?',

    [MessageKeys.GlobalSearchHeading]: 'Search results for',
    [MessageKeys.GlobalSearchTypeLabel]: 'Type',
    [MessageKeys.GlobalSearchNameLabel]: 'Name',
    [MessageKeys.GlobalSearchContentLabel]: 'Content',
    [MessageKeys.GlobalSearchNoItems]: 'No results found',

    [MessageKeys.InternalEntitiesHeading]: 'Internal entities',
    [MessageKeys.InternalEntitiesCreateNewInternalEntityLabel]:
      'Create internal entity',
    [MessageKeys.InternalEntitiesCreateInternalEntityFailure]:
      'Failed to create internal entity',
    [MessageKeys.InternalEntitiesCreateInternalEntitySuccess]:
      'Successfully created new internal entity',
    [MessageKeys.InternalEntitiesNoItems]: 'No internal entities found',

    [MessageKeys.InternalEntityName]: 'Name',
    [MessageKeys.InternalEntityCountryOfRegistration]:
      'Country of registration',
    [MessageKeys.InternalEntityRegisteredAddress]: 'Registered address',
    [MessageKeys.InternalEntityUltimateParent]: 'Ultimate parent',
    [MessageKeys.InternalEntityParentCompany]: 'Parent company',
    [MessageKeys.InternalEntityCorporateRegistrationNumber]:
      'Corporate registration number',
    [MessageKeys.InternalEntityLegalEntityIdentifier]:
      'Legal entity identifier',
    [MessageKeys.InternalEntityContactDetails]: 'Contact details',
    [MessageKeys.InternalEntityContactDetailsPlaceholder]:
      'Type to add contact details...',
    [MessageKeys.InternalEntityDescription]: 'Description',

    [MessageKeys.ReportsHeading]: 'Reports',
    [MessageKeys.ReportsCreateNewReportLabel]: 'Create report',
    [MessageKeys.ReportsCreateReportFailure]: 'Failed to create report',
    [MessageKeys.ReportsCreateReportSuccess]: 'Successfully created new report',
    [MessageKeys.ReportsNoItems]: 'No reports found',

    [MessageKeys.TagsHeading]: 'Tags',
    [MessageKeys.TagsCreateNewTagLabel]: 'Create tag',
    [MessageKeys.TagsCreateTagNameFormatTooltip]:
      'Your new tag will be created as <b>{{tagName}}</b>',
    [MessageKeys.TagsCreateTagFailure]: 'Failed to create tag',
    [MessageKeys.TagsCreateTagSuccess]: 'Successfully created new tag',
    [MessageKeys.TagsNoItems]: 'No tags found',

    [MessageKeys.AgreementAssessmentSectionLabel]: 'Agreement assessment',
    [MessageKeys.AgreementAssessmentSectionOutsourcingArrangementLabel]:
      'Outsourcing arrangement',
    [MessageKeys.AgreementAssessmentSectionCategoryLabel]: 'Category',
    [MessageKeys.AgreementAssessmentSectionCriticalImportantCompletedLabel]:
      'Critical or important assessment completed',
    [MessageKeys.AgreementAssessmentSectionLastCriticalImportantLabel]:
      'Latest critical or important assessment',
    [MessageKeys.AgreementAssessmentSectionAssessmentSummaryLabel]:
      'Assessment summary',
    [MessageKeys.AgreementAssessmentSectionCompetentAuthorityDescriptionLabel]:
      'Competent authority notified description',
    [MessageKeys.AgreementAssessmentSectionCompetentAuthorityNotifiedLabel]:
      'Competent authority notified',
    [MessageKeys.AgreementAssessmentSectionCompetentAuthorityNotifiedDateLabel]:
      'Date of competent authority notification',
    [MessageKeys.AgreementAssessmentSectionCriticalImportantLabel]:
      'Critical or important',
    [MessageKeys.AgreementAssessmentSectionSupplierInstitutionGroupLabel]:
      'Is the supplier part of the institutions group',
    [MessageKeys.AgreementAssessmentSectionRiskAssessmentCompletedLabel]:
      'Risk assessment completed',
    [MessageKeys.AgreementAssessmentSectionLatestRiskAssessmentLabel]:
      'Latest risk assessment',
    [MessageKeys.AgreementAssessmentSectionNextRiskAssessmentLabel]:
      'Next risk assessment',
    [MessageKeys.AgreementAssessmentSectionRiskAssessmentSummaryLabel]:
      'Risk assessment summary',
    [MessageKeys.AgreementAssessmentSectionFinalApprovalLabel]:
      'Final approval',
    [MessageKeys.AgreementAssessmentSectionLatestAuditLabel]: 'Latest audit',
    [MessageKeys.AgreementAssessmentSectionNextAuditLabel]: 'Next audit',
    [MessageKeys.AgreementAssessmentSectionAuditNotesLabel]: 'Audit notes',
    [MessageKeys.AgreementAssessmentSectionExitStrategyLabel]: 'Exit strategy',
    [MessageKeys.AgreementAssessmentSectionAlternativeServiceProviderLabel]:
      'Alternative service providers',
    [MessageKeys.AgreementAssessmentSectionTimeCriticalOperationLabel]:
      'Time critical operation',

    [MessageKeys.AgreementTypeSuccessfullyCreatedLabel]:
      'Agreement type successfully created',
    [MessageKeys.AgreementTypeFailedCreateLabel]:
      'Failed to create agreement type',
    [MessageKeys.AgreementTypeLabel]: 'Agreement types',
    [MessageKeys.AgreementTypeCreateLabel]: 'Create agreement type',
    [MessageKeys.AgreementTypeSuccessfullyUpdatedLabel]:
      'Agreement type successfully updated',
    [MessageKeys.AgreementTypeSuccessfullyDeletedLabel]:
      'Agreement type successfully deleted',
    [MessageKeys.AgreementTypeFailedDeleteLabel]:
      'Failed to delete agreement type',
    [MessageKeys.AgreementTypeConfirmDeleteLabel]:
      'Are you sure you want to delete this agreement type?',

    [MessageKeys.AgreementVersionInfoLabel]:
      'Version <b>{{version}}</b><br />Saved <b>{{date}}</b> by <b>{{modifiedBy}}</b><br /><br />Click to view agreement history',
    [MessageKeys.AgreementVersionAdditionalInformationLabel]:
      'Additional information required',
    [MessageKeys.AgreementVersionOtherAttachmentsLabel]: 'Other attachments',

    [MessageKeys.CabinetMoveModalMoveAgreementLabel]: 'Move agreement',
    [MessageKeys.CabinetMoveCaution]: 'Use with caution!',
    [MessageKeys.CabinetMoveCautionText]:
      'Moving an agreement to another cabinet, will affect user access to agreements.',

    [MessageKeys.CalendarExportSecretAddressTooltipLabel]:
      'You should not give your Secret Address to other people. If you accidentally shared your Secret Address, click Delete to invalidate the old address and create a new one. Copy the ICAL link that appears in the window and paste the link as directed by your other calendar application. Only you should know the Secret Address for your calendar. Do not share this address.',
    [MessageKeys.CalendarExportLabel]: 'Calendar export',
    [MessageKeys.CalendarExportSecretAddressBodyLabel]:
      'The Secret Address lets you view your calendar in other applications, like Outlook or Apple Calendar.',
    [MessageKeys.CalendarExportSecretAddressLabel]: 'Secret Address',
    [MessageKeys.CalendarExportCopyLabel]: 'Copy',
    [MessageKeys.CalendarExportDeleteSecretAddressLabel]:
      'Delete secret address',
    [MessageKeys.CalendarExportGetSecretAddressLabel]: 'Get secret address',
    [MessageKeys.CalendarExportTodayLabel]: 'Today',
    [MessageKeys.CalendarExportAgendaLabel]: 'Agenda',
    [MessageKeys.CalendarExportCalendarLabel]: 'Calendar',
    [MessageKeys.CalendarExportExportLabel]: 'Export',

    [MessageKeys.ChangeManagementProcessSectionLabel]:
      'Change management process',

    [MessageKeys.DetailsSectionLabel]: 'Details',
    [MessageKeys.DetailsSectionAgreementNameLabel]: 'Agreement name',
    [MessageKeys.DetailsSectionAgreementTypeLabel]: 'Agreement type',
    [MessageKeys.DetailsSectionSupplierLabel]: 'Supplier',
    [MessageKeys.DetailsSectionSupplierExternalLabel]: 'External',
    [MessageKeys.DetailsSectionSupplierInternalLabel]: 'Internal',
    [MessageKeys.DetailsSectionNoOptionsTextLabel]: 'No match',
    [MessageKeys.DetailsSectionPartyToAgreementLabel]: 'Party to the agreement',
    [MessageKeys.DetailsSectionReceiversProvidedServiceLabel]:
      'Receivers of provided service',
    [MessageKeys.DetailsSectionContractOwnerLabel]: 'Contract owner',
    [MessageKeys.DetailsSectionInternalReferenceLabel]: 'Internal reference',
    [MessageKeys.DetailsSectionInternalReferencePlaceholderLabel]:
      'Enter further contact details',
    [MessageKeys.DetailsSectionStatusLabel]: 'Status',
    [MessageKeys.DetailsSectionStartDateLabel]: 'Start date',
    [MessageKeys.DetailsSectionEndDateLabel]: 'End date',
    [MessageKeys.DetailsSectionDateAgreementRenewalLabel]:
      'Date for agreement renewal',
    [MessageKeys.DetailsSectionNoticePeriodSupplierLabel]:
      'Notice period for supplier',
    [MessageKeys.DetailsSectionNoticePeriodInternalEntityLabel]:
      'Notice period for internal entity',
    [MessageKeys.DetailsSectionArchivingReferenceLabel]: 'Archiving reference',
    [MessageKeys.DetailsSectionLatesReviewLabel]:
      'Latest review of the agreement',
    [MessageKeys.DetailsSectionNextReviewLabel]: 'Next review of the agreement',
    [MessageKeys.DetailsSectionGowerningLawLabel]:
      'Governing law of the agreement',
    [MessageKeys.DetailsSectionEstimatedAnnualBudgetLabel]:
      'Estimated annual budget cost',
    [MessageKeys.DetailsSectionDescriptionLabel]:
      'A brief description of the provided service or goods',
    [MessageKeys.DetailsSectionAgreementsLabel]: 'Agreements',
    [MessageKeys.DetailsSectionNoAttachmentsLabel]: 'No uploaded agreements',

    [MessageKeys.DropzoneMinFileSizeLabel]:
      'File is smaller than {{minFileSize}} bytes',
    [MessageKeys.DropzoneMaxFileSizeLabel]:
      'File is larger than {{maxFileSize}} bytes',

    [MessageKeys.EditAgreementContractOwnerWarningLabel]:
      'If you save the agreement without adding yourself as contract owner or adding a “tag” that you have been given access to, you will lose access to the agreement. Are you sure you want to save?',
    [MessageKeys.EditAgreementCurrentVersionLabel]:
      'Version: <b>{{version}}</b><br />Edited <b>{{date}}</b> by <b>{{modifiedBy}}</b><br /><br />Click to view agreement history',
    [MessageKeys.EditAgreementUpdateRequiredFieldsLabel]:
      'Please update the required fields',
    [MessageKeys.EditAgreementAdditionalInformationLabel]:
      'Additional information required',
    [MessageKeys.EditAgreementSuccessfullyDeletedLabel]:
      'Agreement successfully deleted',
    [MessageKeys.EditAgreementFailedDeleteLabel]: 'Failed to delete agreement',
    [MessageKeys.EditAgreementSuccessfullyUpdatedLabel]:
      'Agreement successfully updated',
    [MessageKeys.EditAgreementSuccessfullyArchivedLabel]:
      'Agreement successfully archived',
    [MessageKeys.EditAgreementSuccessfullyUnarchivedLabel]:
      'Agreement successfully unarchived',
    [MessageKeys.EditAgreementFailedArchivedLabel]:
      'Failed to archive agreement',
    [MessageKeys.EditAgreementFailedUnarchivedLabel]:
      'Failed to unarchive agreement',
    [MessageKeys.EditAgreementArchivedLabel]: 'Archive agreement',
    [MessageKeys.EditAgreementUnarchivedLabel]: 'Unarchive agreement',
    [MessageKeys.EditAgreementArchivedHeadingLabel]: 'Archived',
    [MessageKeys.EditAgreementCabinetMoveLabel]:
      'Move agreement to another cabinet',
    [MessageKeys.EditAgreementCabinetMoveSuccessLabel]:
      'Successfully moved agreement to cabinet',
    [MessageKeys.EditAgreementCabinetMoveFailLabel]:
      'Failed to move agreement to cabinet',

    [MessageKeys.EditCabinetSuccessfullyDeletedLabel]:
      'Cabinet successfully deleted',
    [MessageKeys.EditCabinetFailedDeleteLabel]: 'Failed to delete cabinet',
    [MessageKeys.EditCabinetConfirmDeleteLabel]:
      'Are you sure you want to delete this cabinet?',

    [MessageKeys.EditCategorySuccessfullyUpdatedLabel]:
      'Category successfully updated',
    [MessageKeys.EditCategorySuccessfullyDeletedLabel]:
      'Category successfully deleted',
    [MessageKeys.EditCategoryFailedDeleteLabel]: 'Failed to delete category',
    [MessageKeys.EditCategoryConfirmDeleteLabel]:
      'Are you sure you want to delete this category?',

    [MessageKeys.EditInternalEntitySuccessfullyUpdatedLabel]:
      'Internal entity successfully updated',
    [MessageKeys.EditInternalEntitySuccessfullyDeletedLabel]:
      'Internal entity successfully deleted',
    [MessageKeys.EditInternalEntityFailedDeleteLabel]:
      'Failed to delete internal entity',
    [MessageKeys.EditInternalEntityConfirmDeleteLabel]:
      'Are you sure you want to delete this internal entity?',

    [MessageKeys.EditReportSuccessfullyUpdatedLabel]:
      'Report successfully updated',
    [MessageKeys.EditReportSuccessfullyDeletedLabel]:
      'Report successfully deleted',
    [MessageKeys.EditReportFailedDeleteLabel]: 'Failed to delete report',
    [MessageKeys.EditReportConfirmDeleteLabel]:
      'Are you sure you want to delete this report?',

    [MessageKeys.EditSupplierCurrentVersionLabel]:
      'Version: <b>{{version}}</b> <br />Edited <b>{{modified}}</b> by <b>{{modifiedBy}}</b><br /><br />Click to view supplier history',
    [MessageKeys.EditSupplierSupplierAssessmentLabel]: 'Supplier assessment',
    [MessageKeys.EditSupplierSuccessfullyUpdatedLabel]:
      'Supplier successfully updated',
    [MessageKeys.EditSupplierSuccessfullyDeletedLabel]:
      'Supplier successfully deleted',
    [MessageKeys.EditRSupplierFailedDeleteLabel]: 'Failed to delete supplier',
    [MessageKeys.EditSupplierConfirmDeleteLabel]:
      'Are you sure you want to delete this supplier?',

    [MessageKeys.ErrorBoundaryMessageHeaderLabel]:
      'Ouch, it looks like we have a problem',
    [MessageKeys.ErrorBoundaryMessageBodyLabel]:
      'Please take a screenshot of this when you report the problem:',

    [MessageKeys.FieldCompareValueUpdatedFromLabel]:
      'Value updated from: {{label}}',
    [MessageKeys.FieldCompareValueUpdatedLabel]: 'Value updated',
    [MessageKeys.FieldCompareItemCreatedLabel]: 'Item was created',
    [MessageKeys.FieldCompareItemDeletedLabel]: 'Item was deleted',

    [MessageKeys.FileUploadModalDragDropLabel]: 'Drag & drop file here',
    [MessageKeys.FileUploadModalFlieDialogLabel]: 'Open file dialog',

    [MessageKeys.GlobalSearchAgreementAttachmentLabel]: 'Agreement attachment',
    [MessageKeys.GlobalSearchSupplierAttachmentLabel]: 'Supplier attachment',
    [MessageKeys.GlobalSearchResultQueryLabel]:
      '{{count}} results for {{query}}',

    [MessageKeys.HistoryTableVersionLabel]: 'Version',
    [MessageKeys.HistoryTableTimestampLabel]: 'Timestamp',
    [MessageKeys.HistoryTableUserLabel]: 'User',
    [MessageKeys.HistoryTableVNoVersionLabel]: 'No previous versions found',

    [MessageKeys.IEBlockerHeadingLabel]:
      'Ouch, it looks like we have a problem',
    [MessageKeys.IEBlockerBodyLabel]:
      'You are currently using Internet Explorer as a web browser.\nPlease open this page in a newer browser.\nWe recommend Microsoft Edge, Chrome or Safari.',

    [MessageKeys.InternalEntitiesCountryRegistrationLabel]:
      'Country of registration',
    [MessageKeys.InternalEntitiesCorporateRegistrationNumberabel]:
      'Corporate registration number',
    [MessageKeys.InternalEntitiesEmptyTableTextLabel]:
      'No internal entities found',

    [MessageKeys.InternalEntityDetailsSubFormCountryRegistrationLabel]:
      'Country of registration',
    [MessageKeys.InternalEntityDetailsSubFormRegistratedAddressLabel]:
      'Registered address',
    [MessageKeys.InternalEntityDetailsSubFormUltimateParentLabel]:
      'Ultimate parent',
    [MessageKeys.InternalEntityDetailsSubFormParentCompanyLabel]:
      'Parent company',
    [MessageKeys.InternalEntityDetailsSubFormCorporateRegistrationNumberLabel]:
      'Corporate registration number',
    [MessageKeys.InternalEntityDetailsSubFormLegalEntityIdentifierLabel]:
      'Legal entity identifier',
    [MessageKeys.InternalEntityDetailsSubFormContactDetailsLabel]:
      'Contact details',

    [MessageKeys.JSONTableResultNotRepresentedLabel]:
      'Result can not be represented as table. View as JSON',

    [MessageKeys.ListDataTableNOItemsLabel]: 'No items found',

    [MessageKeys.MainLayoutHeaderSearchLabel]: 'Search...',

    [MessageKeys.NotificationsPageHeading]: 'Notifications',
    [MessageKeys.NotificationsLabel]: 'Notifications',
    [MessageKeys.NotificationsSettingsEmailSubHeadingLabel]:
      'Email subscription settings',
    [MessageKeys.NotificationsSettingsDateSubHeadingLabel]:
      'Date reminder settings',
    [MessageKeys.NotificationsSettingsLabel]: 'Settings',
    [MessageKeys.NotificationsSettingsText]:
      'Recieve further email notification',
    [MessageKeys.NotificationsSettingsToggleTextOn]: 'Subscribe',
    [MessageKeys.NotificationsSettingsToggleTextOff]: 'Unsubscribe',
    [MessageKeys.NotificationsSettingsSavedSuccess]:
      'Successfully updated settings',
    [MessageKeys.NotificationsSettingsSavedFailure]:
      'Failed to update settings',
    [MessageKeys.NotificationsSettingsDescriptionLabel]:
      'Email notifications are sent when you are Contract Owner of the agreement',
    [MessageKeys.NotificationsSettingsReminerSentLabel]:
      'Reminders will be sent {{reminders}} days ahead',
    [MessageKeys.NotificationsSettingsNumberDaysAheadLabel]:
      'The number of days ahead you wish to be reminded',
    [MessageKeys.NotificationsSettingsEndDateTitleLabel]: 'End date',
    [MessageKeys.NotificationsSettingsEndDateDescriptionLabel]:
      'The notifications you will receive by email to remind you when an agreement is about to expire.',
    [MessageKeys.NotificationsSettingsRenewalDateTitleLabel]: 'Renewal date',
    [MessageKeys.NotificationsSettingsRenewalDateDescriptionLabel]:
      'The notifications you will receive by email to remind you when an agreement must be renewed.',
    [MessageKeys.NotificationsSettingsNextReviewTitleLabel]: 'Next review',
    [MessageKeys.NotificationsSettingsNextReviewDescriptionLabel]:
      'The notifications you will receive by email to remind you of the next review of an agreement.',
    [MessageKeys.NotificationsSettingsNextAuditTitleLabel]: 'Next audit',
    [MessageKeys.NotificationsSettingsNextAuditDescriptionLabel]:
      'The notifications you will receive by email to remind you of the next audit of an agreement.',
    [MessageKeys.NotificationsSettingsNextRiskAssessmentTitleLabel]:
      'Next risk assessment',
    [MessageKeys.NotificationsSettingsNextRiskAssessmentDescriptionLabel]:
      'The notifications you will receive by email to remind you of the next risk assessment of an agreement.',
    [MessageKeys.NotificationsSettingsHeadingLabel]:
      'Email notifications settings',
    [MessageKeys.NotificationsUnreadCountTooltip]: '1 unread notification',
    [plural(MessageKeys.NotificationsUnreadCountTooltip)]:
      '{{count}} unread notifications',

    [MessageKeys.SideMenuDashboardLabel]: 'Dashboard',
    [MessageKeys.SideMenuCalendarLabel]: 'Calendar',
    [MessageKeys.SideMenuAgreementsLabel]: 'Agreements',
    [MessageKeys.SideMenuCabinetsLabel]: 'Cabinets',
    [MessageKeys.SideMenuTagsLabel]: 'Tags',
    [MessageKeys.SideMenuAgreementTypesLabel]: 'Agreement types',
    [MessageKeys.SideMenuCategoriesLabel]: 'Categories',
    [MessageKeys.SideMenuSuppliersLabel]: 'Suppliers',
    [MessageKeys.SideMenuInternalEntitiesLabel]: 'Internal entities',
    [MessageKeys.SideMenuReportsLabel]: 'Reports',

    [MessageKeys.NPAPSectionLabel]: 'New product approval process',

    [MessageKeys.ParentAgreementFieldSupplierLabel]: 'Supplier',
    [MessageKeys.ParentAgreementFieldAgreementNameLabel]: 'Agreement name',

    [MessageKeys.PersonalDataSectionLabel]: 'Personal data',
    [MessageKeys.PersonalDataSectionDataTransferredLabel]:
      'Personal data transferred (outside EU/EEA)',
    [MessageKeys.PersonalDataSectionDataTransferredCountryLabel]:
      'Country or region where data is transferred',
    [MessageKeys.PersonalDataSectionDataProcessedLabel]:
      'Personal data processed',
    [MessageKeys.PersonalDataSectionPartyAgreementLabel]:
      'Party to the agreement',
    [MessageKeys.PersonalDataSectionRoleLabel]: 'Role',
    [MessageKeys.PersonalDataSectionDataProtectionIncludedLabel]:
      'Data protection included in the agreement with service provider',

    [MessageKeys.RelatedAgreementsSectionLabel]: 'Related agreements',
    [MessageKeys.RelatedAgreementsSectionParentAgreementLabel]:
      'Child to a parent agreement',
    [MessageKeys.RelatedAgreementsSectionParentLabel]: 'Parent',
    [MessageKeys.RelatedAgreementsSectionOtherRelationshipsLabel]:
      'Other relationships',

    [MessageKeys.RelatedAgreementsTableNameLabel]: 'Name',
    [MessageKeys.RelatedAgreementsTableAgreementTypeLabel]: 'Agreement type',
    [MessageKeys.RelatedAgreementsTableCategoryLabel]: 'Category',
    [MessageKeys.RelatedAgreementsTableRelationshipLabel]: 'Relationship',
    [MessageKeys.RelatedAgreementsTableNoAgreementsLabel]:
      'No related agreements found',

    [MessageKeys.ReportsAddReportLabel]: 'Add report',
    [MessageKeys.ReportsEmptyTableTextLabel]: 'No reports found',

    [MessageKeys.ReportDetailsCardLabel]: 'Details',
    [MessageKeys.ReportDetailsCardEntityAgreementsLabel]: 'Agreements',
    [MessageKeys.ReportDetailsCardEntitySuppliersLabel]: 'Suppliers',
    [MessageKeys.ReportDetailsCardNameLabel]: 'Name',
    [MessageKeys.ReportDetailsCardEntityLabel]: 'Entity',
    [MessageKeys.ReportDetailsCardQueryLabel]: 'Query',
    [MessageKeys.ReportDetailsCardDownloadCsvLabel]: 'Download CSV',
    [MessageKeys.ReportDetailsCardViewTableLabel]: 'View as Table',
    [MessageKeys.ReportDetailsCardViewJsonLabel]: 'View as JSON',

    [MessageKeys.ServerError400Label]:
      'Sorry, we are unable to process this request',
    [MessageKeys.ServerError401Label]:
      'Your session have timed out, please login again',
    [MessageKeys.ServerError403Label]:
      'You have not permissions to view this page',
    [MessageKeys.ServerError404Label]: 'Page not found',

    [MessageKeys.ServiceDetailsSectionLabel]: 'Service details',
    [MessageKeys.ServiceDetailsSectionCloudServiceLabel]:
      'Provided as cloud service',
    [MessageKeys.ServiceDetailsSectionDeploymentModelLabel]: 'Deployment model',
    [MessageKeys.ServiceDetailsSectionNatureDataLabel]: 'Nature of data',
    [MessageKeys.ServiceDetailsSectionCountriesServicePerformedLabel]:
      'Countries where service is performed',
    [MessageKeys.ServiceDetailsSectionCountriesDataStoredLabel]:
      'Countries or regions where data is stored',
    [MessageKeys.ServiceDetailsSectionSubContractorsLabel]:
      'Sub-contractors used to provide material parts of the service',

    [MessageKeys.SessionTimeoutHeading]: 'Your session is about to expire!',
    [MessageKeys.SessionTimeoutInfoMessage]:
      'Please save any drafts and re-login to refresh your session.',
    [MessageKeys.SessionTimeoutAutoLogoutMessage]:
      'You will be automatically signed out in <bold>{{time}}</bold>!',
    [MessageKeys.SessionTimeoutTimerMinutesAndSeconds]:
      '{{minutes}}m {{seconds}}s',
    [MessageKeys.SessionTimeoutTimerOnlySeconds]: '{{seconds}} seconds',

    [MessageKeys.SearchAdvancedSearchButtonTooltip]:
      'Open advanced keyword search',
    [MessageKeys.SearchHelpModalExample1Description]:
      'returns all agreements where name containing the phrase “Agreement ABC” or the word “Agreement”.',
    [MessageKeys.SearchHelpModalExample2Description]:
      'returns all suppliers where name containing the phrase "Supplier ABC” or the word “Supplier”.',
    [MessageKeys.SearchHelpModalExample3Description]:
      'returns all suppliers where corporate registration number containing the word “123456789”.',
    [MessageKeys.SearchHelpModalExample1Label]: '"Agreement ABC" OR Agreement',
    [MessageKeys.SearchHelpModalExample2Label]: '"Supplier ABC" OR Supplier',
    [MessageKeys.SearchHelpModalExample3Label]: '123456789',
    [MessageKeys.SearchHelpModalExampleTextLabel]: 'Example phrase:',
    [MessageKeys.SearchHelpModalOrTextDescription]:
      '"or" between keywords will generally widen your search result to include more results',
    [MessageKeys.SearchHelpModalOrTextLabel]: 'OR:',
    [MessageKeys.SearchHelpModalQuotedTextDescription]:
      'requires that the phrase is found somewhere in the text',
    [MessageKeys.SearchHelpModalQuotedTextLabel]: 'Quoted text:',
    [MessageKeys.SearchHelpModalTitle]: 'Search help',
    [MessageKeys.SearchHelpModalUnquotedTextDescription]:
      'requires each word to be found somewhere in the text',
    [MessageKeys.SearchHelpModalUnquotedTextLabel]: 'Unquoted text:',

    [MessageKeys.SubcontractorsFieldAddLabel]: 'Add sub-contractor',
    [MessageKeys.SubcontractorsFieldEditLabel]: 'Edit sub-contractor',
    [MessageKeys.SubcontractorsFieldDeleteLabel]:
      'Are you sure you want to delete this subcontractor?',
    [MessageKeys.SubcontractorsFieldConfirmDeleteLabel]: 'Yes, delete it',
    [MessageKeys.SubcontractorsFieldEditFormSupplierLabel]: 'Supplier',
    [MessageKeys.SubcontractorsFieldEditFormSupplierExternalLabel]: 'External',
    [MessageKeys.SubcontractorsFieldEditFormSupplierInternalLabel]: 'Internal',
    [MessageKeys.SubcontractorsFieldEditFormFunctionCategoryLabel]:
      'Function category',
    [MessageKeys.SubcontractorsFieldEditFormCountriesServicePerformedLabel]:
      'Countries where service is performed',
    [MessageKeys.SubcontractorsFieldEditFormCountriesDataStoredLabel]:
      'Countries or regions where data is stored',
    [MessageKeys.SubcontractorsFieldEditFormDescriptionLabel]:
      'A brief description of the service provided by the sub-contractor',
    [MessageKeys.SubcontractorsFieldTableSupplierNameLabel]: 'Supplier name',
    [MessageKeys.SubcontractorsFieldTableCategoryLabel]: 'Category',
    [MessageKeys.SubcontractorsFieldTableCountriesServicePerformedLabel]:
      'Countries services performed',
    [MessageKeys.SubcontractorsFieldTableNoSubcontractorsLabel]:
      'No subcontractors found',

    [MessageKeys.SupplierHistoryHeading]: '{{supplier}}: Supplier history',

    [MessageKeys.SuppliersHeading]: 'Suppliers',
    [MessageKeys.SuppliersNoItems]: 'No suppliers found',
    [MessageKeys.SupplierCreateNewSupplierLabel]: 'Create supplier',
    [MessageKeys.SupplierCreateSupplierFailure]: 'Failed to create supplier',
    [MessageKeys.SupplierCreateSupplierSuccess]:
      'Successfully created new supplier',
    [MessageKeys.SupplierEditThisSupplierLabel]: 'Edit this supplier',
    [MessageKeys.SupplierSelectorCreateOptionText]:
      'Create supplier: {{supplierName}}',
    [MessageKeys.SupplierSelectorTypeToSelectOrCreateSupplierPlaceholder]:
      'Type to select or create supplier...',

    [MessageKeys.SupplierName]: 'Name',
    [MessageKeys.SupplierNameRequired]: 'Supplier name is required',
    [MessageKeys.SupplierNameNameClashError]:
      'A supplier with this name already exists',
    [MessageKeys.SupplierCountryOfRegistration]: 'Country of registration',
    [MessageKeys.SupplierRegisteredAddress]: 'Registered address',
    [MessageKeys.SupplierUltimateParent]: 'Ultimate parent',
    [MessageKeys.SupplierParentCompany]: 'Parent company',
    [MessageKeys.SupplierCorporateRegistrationNumber]:
      'Corporate registration number',
    [MessageKeys.SupplierLegalEntityIdentifier]: 'Legal entity identifier',
    [MessageKeys.SupplierContactDetails]: 'Contact details',
    [MessageKeys.SupplierContactDetailsPlaceholder]:
      'Type to add contact details...',
    [MessageKeys.SupplierDescription]: 'Description',
    [MessageKeys.SupplierAssessmentCSRSurveyRequired]:
      'Corporate Social Responsibility survey',
    [MessageKeys.SupplierAssessmentDueDilligenceConducted]:
      'Due Diligence of supplier conducted',
    [MessageKeys.SupplierAssessmentFollowCodeOfConductRequired]:
      'Follow Code of Conduct',
    [MessageKeys.SupplierAssessmentInformationSecurityRequired]:
      'Information Security requirements',
    [MessageKeys.SupplierAssessmentLatestCSRSurvey]: 'Latest CSR survey',
    [MessageKeys.SupplierAssessmentLatestDueDilligence]: 'Latest Due Diligence',
    [MessageKeys.SupplierAssessmentLatestRiskAssessment]:
      'Latest risk assessment',
    [MessageKeys.SupplierAssessmentRiskAssessmentCompleted]:
      'Risk assessment of supplier completed',

    [MessageKeys.SupplierDetailsSubFormCreateOptionLabel]:
      'Press enter to add new supplier:',

    [MessageKeys.SupplierVersionSupplierAssessmentLabel]: 'Supplier assessment',
    [MessageKeys.SupplierVersionVersionSavedLabel]:
      'Version <b>{{version}}</b> saved <b>{{date}}</b> by <b>{{modifiedBy}}</b><br /><br />Click to view supplier history',

    [MessageKeys.UnauthorizedSettingsTooltip]:
      'Change your notification settings',

    [MessageKeys.ValidateAgreementGowerningLawLabel]:
      'Governing law must be set',
    [MessageKeys.ValidateAgreementReceiversProvidedServiceLabel]:
      'No receivers of provided service',
    [MessageKeys.ValidateAgreementAnnualBudgetAmountLabel]:
      'Annual budget cost estimation amount must be set',
    [MessageKeys.ValidateAgreementAnnualBudgetCurrencyLabel]:
      'Annual budget cost estimation currency must be set',
    [MessageKeys.ValidateAgreementCompetentAuthorityNotifiedLabel]:
      'Competent Authority must be notified',
    [MessageKeys.ValidateAgreementRiskAssessmentCompletedLabel]:
      'Please complete Risk assessment',
    [MessageKeys.ValidateAgreementLatestRiskAssessmentLabel]:
      'Please fill in a date of latest risk assessment',
    [MessageKeys.ValidateAgreementNextRiskAssessmentLabel]:
      'Please fill in a date of next risk assessment',
    [MessageKeys.ValidateAgreementFinalApprovalLabel]:
      'Final approval must be specified',
    [MessageKeys.ValidateAgreementLatestAuditLabel]:
      'Please fill in a date of latest audit',
    [MessageKeys.ValidateAgreementNextAuditLabel]:
      'Please fill in a date of next audit',
    [MessageKeys.ValidateAgreementExitStrategyLabel]:
      'Exit strategy must be specified',
    [MessageKeys.ValidateAgreementAlternativeServiceProvidersLabel]:
      'Alternative service providers must be specified',
    [MessageKeys.ValidateAgreementTimeCriticalOperationLabel]:
      'Time critital operation must be specified',
    [MessageKeys.ValidateAgreementAgreementTypeLabel]:
      'Agreement type is required',
    [MessageKeys.ValidateAgreementPartyToAgreementLabel]:
      'Party to the agreement is required',
    [MessageKeys.ValidateAgreementBriefDescriptionLabel]:
      'A brief description is required',
    [MessageKeys.ValidateAgreementExternalSupplierLabel]:
      'External supplier is required',
    [MessageKeys.ValidateAgreementInternalSupplierLabel]:
      'Internal supplier is required',
    [MessageKeys.ValidateAgreementStartDateLabel]: 'Start date is required',
    [MessageKeys.ValidateAgreementEndDateLabel]: 'End date is required',
    [MessageKeys.ValidateAgreementPersonalDataTransferredLabel]:
      'Personal data transfered is required',
    [MessageKeys.ValidateAgreementPersonalDataProcessedLabel]:
      'Personal data processed is required',
    [MessageKeys.ValidateAgreementRoleLabel]: 'Role is required',
    [MessageKeys.ValidateAgreementDataProtectionIncludedLabel]:
      'Data protection included is required',
    [MessageKeys.ValidateAgreementNameLabel]: 'Agreement name is required',
    [MessageKeys.ValidateAgreementContractOwnerLabel]:
      'Contract owner is required',
    [MessageKeys.ValidateAgreementContractOwnerEmailLabel]:
      'Contract owner must be a valid email',
    [MessageKeys.ValidateAgreementIsOutsourcingArrangementLabel]:
      'Outsourcing arrangement must be specified',

    [MessageKeys.ValidationSummaryAuthorityLabel]:
      'Authority validation requirements:',
    [MessageKeys.ValidationSummaryOtherLabel]: 'Other validation requirements:',

    [MessageKeys.VersionHistoryTableVersionLabel]: 'Version',
    [MessageKeys.VersionHistoryTableModifiedLabel]: 'Timestamp',
    [MessageKeys.VersionHistoryTableModifiedByLabel]: 'Username',
    [MessageKeys.VersionHistoryTableNoItems]: 'No previous versions found',
  },
};

export default {
  ...filterMessages.en.messages,
  ...en.messages,
};
