/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryToJSON,
    GetCategoryResponse,
    GetCategoryResponseFromJSON,
    GetCategoryResponseToJSON,
    ListAllCategoriesResponse,
    ListAllCategoriesResponseFromJSON,
    ListAllCategoriesResponseToJSON,
} from '../models';

export interface CreateCategoryRequest {
    category?: Category;
}

export interface DeleteCategoryRequest {
    categoryId: string;
}

export interface GetCategoryRequest {
    categoryId: string;
}

export interface UpdateCategoryRequest {
    categoryId: string;
    category?: Category;
}

/**
 * CategoriesApi - interface
 * 
 * @export
 * @interface CategoriesApiInterface
 */
export interface CategoriesApiInterface {
    /**
     * 
     * @summary Create an Category
     * @param {Category} [category] item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    createCategoryRaw(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Create an Category
     */
    createCategory(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Delete a category
     * @param {string} categoryId The name of the categoriy to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    deleteCategoryRaw(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a category
     */
    deleteCategory(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Get category
     * @param {string} categoryId The name of the categoriy to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCategoryResponse>>;

    /**
     * Get category
     */
    getCategory(requestParameters: GetCategoryRequest, initOverrides?: RequestInit): Promise<GetCategoryResponse>;

    /**
     * 
     * @summary List all categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    listAllCategoriesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllCategoriesResponse>>;

    /**
     * List all categories
     */
    listAllCategories(initOverrides?: RequestInit): Promise<ListAllCategoriesResponse>;

    /**
     * 
     * @summary Update a Category
     * @param {string} categoryId The name of the categoriy to retrieve
     * @param {Category} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    updateCategoryRaw(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Update a Category
     */
    updateCategory(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class CategoriesApi extends runtime.BaseAPI implements CategoriesApiInterface {

    /**
     * Create an Category
     */
    async createCategoryRaw(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryToJSON(requestParameters.category),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Category
     */
    async createCategory(requestParameters: CreateCategoryRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.createCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a category
     */
    async deleteCategoryRaw(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a category
     */
    async deleteCategory(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Get category
     */
    async getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCategoryResponse>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Get category
     */
    async getCategory(requestParameters: GetCategoryRequest, initOverrides?: RequestInit): Promise<GetCategoryResponse> {
        const response = await this.getCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all categories
     */
    async listAllCategoriesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllCategoriesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllCategoriesResponseFromJSON(jsonValue));
    }

    /**
     * List all categories
     */
    async listAllCategories(initOverrides?: RequestInit): Promise<ListAllCategoriesResponse> {
        const response = await this.listAllCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a Category
     */
    async updateCategoryRaw(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryToJSON(requestParameters.category),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a Category
     */
    async updateCategory(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCategoryRaw(requestParameters, initOverrides);
    }

}
