import { classNames } from '@fcg-tech/regtech-utils';
import { CheckSquare, Square } from 'lucide-react';
import React from 'react';
import {
  CheckboxHiddenInput,
  InputCheckboxLabelWrapper,
  InputCheckboxWrapper,
} from './Checkbox.styles';

export interface InputCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  labelPosition?: 'left' | 'right';
  inputClassName?: string;
}

const size = '18';

export const InputCheckbox = React.forwardRef<
  HTMLInputElement,
  InputCheckboxProps
>(
  (
    {
      className,
      inputClassName,
      ref,
      as,
      label,
      labelPosition = 'right',
      ...props
    },
    inputRef,
  ) => {
    return (
      <InputCheckboxWrapper
        className={classNames(
          'input-checkbox-wrapper',
          className,
          props.disabled && 'disabled',
        )}
      >
        <CheckboxHiddenInput
          {...props}
          ref={inputRef}
          type="checkbox"
          className={inputClassName}
        />
        {label && labelPosition === 'left' ? (
          <InputCheckboxLabelWrapper>{label}</InputCheckboxLabelWrapper>
        ) : null}
        {props.checked ? <CheckSquare size={size} /> : <Square size={size} />}
        {label && labelPosition === 'right' ? (
          <InputCheckboxLabelWrapper>{label}</InputCheckboxLabelWrapper>
        ) : null}
      </InputCheckboxWrapper>
    );
  },
);
