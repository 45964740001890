import { classNames, useIsMountedRef } from '@fcg-tech/regtech-utils';
import { Placement, createPopper, State } from '@popperjs/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEventCallback, useForkRef } from '../../hooks';
import { randId } from '../../utils';
import { Portal } from '../Portal';
import { TooltipTooltip, TooltipNextWrapper } from './Tooltip.styles';
import { TooltipArrow } from './TooltipArrow';
import { TooltipContext, useTooltipContext } from './tooltipContext';

type PointerEventHandler = (event: PointerEvent) => void;

type ChildrenCallback = (opts: {
  ref: React.Ref<HTMLElement>;
  onPointerEnter: PointerEventHandler;
  onPointerLeave: PointerEventHandler;
}) => React.ReactElement;

interface StringHtmlContent {
  content: string | number | undefined | null | (() => string);
  html?: boolean;
}

interface NonStringContent {
  content:
    | React.ReactNode
    | Array<React.ReactNode>
    | JSX.Element
    | (() => React.ReactNode)
    | undefined
    | null;
  html?: never;
}

interface TooltipBaseProps {
  id?: string | number;
  portal?: HTMLElement | string;

  /**
   * Control the visibility and disable pointer events
   */
  forceOpen?: boolean;

  placement?: Placement;
  /**
   * Delay in milliseconds
   */
  showDelay?: number;

  /**
   * Delay in milliseconds
   */
  hideDelay?: number;
  priority?: number;

  className?: string;
  children?: React.ReactElement;
  render?: ChildrenCallback;
}

export type TooltipProps =
  | (TooltipBaseProps & StringHtmlContent)
  | (TooltipBaseProps & NonStringContent);

export const TooltipNext = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      content: initialContent,
      id: providedId,
      forceOpen = undefined,
      portal,
      placement = 'top',
      html,
      showDelay = 0,
      hideDelay = 0,
      priority = 1,
      className,
      render,
      children,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(
      forceOpen !== undefined ? forceOpen : false,
    );
    const [content, setContent] =
      useState<TooltipProps['content']>(initialContent);
    const [position, setPosition] = useState<Partial<State>>(null);

    const isMounted = useIsMountedRef();
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const detectorRef = useRef<HTMLElement>();
    const arrowRef = useRef<SVGSVGElement>();
    const id = useMemo(() => providedId || randId(), [providedId]);

    const context = useRef<TooltipContext>();
    context.current = useTooltipContext();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRef = useForkRef((children as any).ref, detectorRef);

    useEffect(() => setContent(initialContent), [initialContent]);

    const handleShow = useCallback(
      (show: boolean) => {
        if (window.ontouchstart !== undefined) {
          // Touch device
          return;
        }
        if (
          (show && context.current?.visibleTooltip?.priority > priority) ||
          !isMounted.current
        ) {
          return;
        }

        setOpen(show);

        if (show) {
          if (context.current?.visibleTooltip?.id !== id) {
            context.current?.visibleTooltip?.setVisible?.(false);
          }
          context.current?.setVisibleTooltip?.(id);
        } else {
          setPosition(null);
          if (context.current?.visibleTooltip?.id === id) {
            context.current?.setVisibleTooltip?.(null);
          }
        }
      },
      [id, isMounted, priority],
    );

    useEffect(() => {
      if (forceOpen !== undefined) {
        handleShow(forceOpen);
      }
    }, [handleShow, forceOpen]);

    const handleEnter = useEventCallback((event: PointerEvent) => {
      if (forceOpen !== undefined) {
        return;
      }
      if (window.ontouchstart !== undefined) {
        // Touch device
        return;
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (showDelay) {
        timeout.current = setTimeout(() => {
          handleShow(true);
          timeout.current = null;
        }, showDelay);
      } else {
        handleShow(true);
        timeout.current = null;
      }
    });
    const handleLeave = useEventCallback((event: PointerEvent) => {
      if (forceOpen !== undefined) {
        return;
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (hideDelay) {
        timeout.current = setTimeout(() => {
          handleShow(false);
          timeout.current = null;
        }, hideDelay);
      } else {
        handleShow(false);
        timeout.current = null;
      }
    });

    useEffect(() => {
      context.current.setTooltips?.({
        id,
        setVisible: setOpen,
        priority,
      });

      return () =>
        context.current.setTooltips?.(
          {
            id,
          },
          true,
        );
    }, [handleShow, id, priority]);

    const popperRef = useRef<HTMLDivElement>();

    const popper = useRef<ReturnType<typeof createPopper>>();

    useEffect(() => {
      const run = async () => {
        if (detectorRef.current && popperRef.current) {
          if (open) {
            popper.current = createPopper(
              detectorRef.current,
              popperRef.current,
              {
                placement,
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              },
            );

            const bounds = detectorRef.current.getBoundingClientRect();
            const tooltipBounds = popperRef.current.getBoundingClientRect();

            const state = await popper.current?.update?.();
            setPosition(state);

            if (tooltipBounds.width > bounds.width && arrowRef.current) {
              if (['top-start', 'bottom-start'].includes(state.placement)) {
                arrowRef.current.style.left = `${bounds.width / 2}px`;
              } else if (['top-end', 'bottom-end'].includes(state.placement)) {
                arrowRef.current.style.right = `${bounds.width / 2}px`;
              } else {
                arrowRef.current.style.left = undefined;
                arrowRef.current.style.right = undefined;
              }
            }
            if (tooltipBounds.height > bounds.height && arrowRef.current) {
              if (['left-start', 'right-start'].includes(state.placement)) {
                arrowRef.current.style.top = `${bounds.height / 2}px`;
              } else if (['left-end', 'right-end'].includes(state.placement)) {
                arrowRef.current.style.bottom = `${bounds.height / 2}px`;
              } else {
                arrowRef.current.style.top = undefined;
                arrowRef.current.style.bottom = undefined;
              }
            }
          } else {
            setPosition(null);
          }
        }
      };
      run();
    }, [open, placement]);

    const childrenProps = {
      ref: handleRef,
      onPointerEnter: handleEnter,
      onPointerLeave: handleLeave,
    };

    return content ? (
      <React.Fragment>
        {open ? (
          <Portal container={portal}>
            <TooltipNextWrapper
              ref={popperRef}
              className={classNames(
                'tooltip',
                position && 'positioned',
                position?.placement &&
                  `tooltip__placement-${position.placement}`,
                className,
              )}
            >
              {html ? (
                <TooltipTooltip
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof content === 'function'
                        ? String(content())
                        : String(content),
                  }}
                />
              ) : (
                <TooltipTooltip>
                  {typeof content === 'function' ? content() : content}
                </TooltipTooltip>
              )}
              <TooltipArrow ref={arrowRef} />
            </TooltipNextWrapper>
          </Portal>
        ) : null}
        {render
          ? render(childrenProps)
          : React.cloneElement(children, {
              ...children.props,
              ...childrenProps,
            })}
      </React.Fragment>
    ) : (
      children
    );
  },
);
