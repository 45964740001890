import { Environment } from './types';

export const environment: Environment = {
  production: true,
  featureSet: 'default',
  showErrorTraces: false,
  oauthBaseUrl: 'https://auth.fcgapps.com',
  oauthClientId: '5n37ftetfku6ek16cneqflang0',
  oauthScopes: 'openid+email',
  apiBaseUrl: 'https://1fzot1ynfb.execute-api.eu-north-1.amazonaws.com/Prod',
  documentationApiUrl:
    'https://atlk65auk3.execute-api.eu-north-1.amazonaws.com/documentation/oasys',
  notificationApiBaseUrl:
    'https://v1ppanf8zg.execute-api.eu-north-1.amazonaws.com',
};
