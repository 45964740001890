import {
  formatQueryDate,
  isQueryDate,
  parseQueryDate,
} from '@fcg-tech/regtech-api-utils';
import {
  flattenJSON,
  prune,
  single,
  unflattenJSON,
} from '@fcg-tech/regtech-utils';
import {
  addDays,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import { parse } from 'query-string';
import { StylesConfig } from 'react-select';
import {
  DateRangeInterval,
  FilterValue,
  FilterValues,
  RelativeDateInterval,
} from './types';

export const parseQuery = (url: string) =>
  parse(url.replace(/^\?/, '') ?? '', {
    arrayFormat: 'comma',
    decode: true,
  }) ?? {};

const booleanRe = /^true|false$/i;
const numberRe = /^\d+$/;

export const parseUrlFilterValue = (
  value?: string,
): FilterValue | undefined => {
  if (value?.match(booleanRe)) {
    return Boolean(value.match(/true/i));
  }

  if (value?.match(numberRe)) {
    return Number(value);
  }

  if (value && isQueryDate(value)) {
    return parseQueryDate(value) ?? null;
  }

  return value;
};

export const parseUrlFilterValues = <T extends FilterValues>(
  url: string,
  filterProps: Array<keyof T>,
): T => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: any = {};
  const parsed = dedup(
    parseQuery(url) as Record<keyof T, string | Array<string>>,
  );

  filterProps.forEach((filterProp) => {
    const value = parsed[filterProp];
    if (Array.isArray(value)) {
      filter[filterProp] = value.map(parseUrlFilterValue).filter(Boolean);
    } else if (value) {
      const parsed = parseUrlFilterValue(value);
      filter[filterProp] =
        parsed && typeof parsed === 'string' ? [parsed] : parsed;
    } else {
      // Could be a date interval
      const from = parsed[`${String(filterProp)}.from`];
      const to = parsed[`${String(filterProp)}.to`];
      const interval = parsed[`${String(filterProp)}.interval`];

      if (from || to || interval) {
        filter[filterProp] = {
          from: parseUrlFilterValue(single(from)),
          to: parseUrlFilterValue(single(to)),
          interval: parseUrlFilterValue(single(interval)),
        };
      }
    }
  });

  return unflattenJSON(prune(filter)) as T;
};

export const stringifyUrlFilterValue = (value: FilterValue): string => {
  if (value && value instanceof Date) {
    return formatQueryDate(value);
  }

  return (value as string | number | boolean)?.toString() ?? undefined;
};

export const stringifyUrlFilterValues = <T extends FilterValues>(
  filter: T,
  exclude: Array<keyof T> = [],
): Partial<Record<keyof T, string | Array<string>>> => {
  const query: Partial<Record<keyof T, string | Array<string>>> = {};
  Object.entries(flattenJSON<FilterValue>(filter, false)).forEach(
    ([key, prop]) => {
      const filterProp = key as keyof T;
      if (exclude.includes(filterProp)) {
        return;
      }

      if (Array.isArray(prop)) {
        if (prop.length) {
          query[filterProp] = prop
            .map((val) => stringifyUrlFilterValue(val as FilterValue))
            .filter((v) => v !== undefined && v !== null)
            .sort()
            .join(',');
        }
      } else {
        query[filterProp] = stringifyUrlFilterValue(prop);
      }
    },
  );
  return query;
};

export const calculateIntervals = <T extends FilterValues>(filter: T): T => {
  const copy: FilterValues = {};
  Object.entries(filter).forEach(([key, prop]) => {
    if (isDateRangeInterval(prop) && prop.interval) {
      copy[key] = getDateFilterForInterval(
        prop.interval as RelativeDateInterval,
      );
    } else {
      copy[key] = prop;
    }
  });

  return copy as T;
};

export const getStringOrFirstStringValue = (
  v?: { toString: () => string } | Array<{ toString: () => string }> | null,
): string | undefined => (Array.isArray(v) ? v[0]?.toString() : v?.toString());

export const getDateFilterForInterval = (
  interval?: RelativeDateInterval | null,
): DateRangeInterval | null => {
  let from: Date | undefined = undefined;
  let to: Date | undefined = undefined;

  if (!interval) {
    return null;
  }
  switch (interval) {
    case RelativeDateInterval.Today:
      from = new Date();
      to = from;
      break;

    case RelativeDateInterval.Overdue:
      to = subDays(new Date(), 1);
      break;

    case RelativeDateInterval.LastWeek:
      from = subWeeks(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.LastThreeDays:
      from = subDays(new Date(), 3);
      to = new Date();
      break;

    case RelativeDateInterval.LastTwoWeeks:
      from = subWeeks(new Date(), 2);
      to = new Date();
      break;

    case RelativeDateInterval.ThisWeek:
      from = startOfWeek(new Date());
      to = endOfWeek(new Date());
      break;

    case RelativeDateInterval.ThisPartialWeek:
      from = startOfWeek(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.LastMonth:
      from = subMonths(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisMonth:
      from = startOfMonth(new Date());
      to = endOfMonth(new Date());
      break;

    case RelativeDateInterval.ThisPartialMonth:
      from = startOfMonth(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.LastQuarter:
      from = subQuarters(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisQuarter:
      from = startOfQuarter(new Date());
      to = endOfQuarter(new Date());
      break;

    case RelativeDateInterval.ThisPartialQuarter:
      from = startOfQuarter(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.LastYear:
      from = subYears(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisYear:
      from = startOfYear(new Date());
      to = endOfYear(new Date());
      break;

    case RelativeDateInterval.ThisPartialYear:
      from = startOfYear(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.NextWeek:
      from = new Date();
      to = addWeeks(new Date(), 1);
      break;

    case RelativeDateInterval.NextMonth:
      from = new Date();
      to = addDays(new Date(), 30);
      break;
  }

  return {
    from,
    to,
    interval,
  };
};

export const getAdjustedDateRange = (
  rangeInterval: DateRangeInterval,
): DateRangeInterval | null => {
  if (rangeInterval?.interval) {
    return getDateFilterForInterval(
      rangeInterval.interval as RelativeDateInterval,
    );
  }

  return rangeInterval;
};

export const isDateRangeInterval = (o: unknown): o is DateRangeInterval =>
  !!o &&
  typeof o === 'object' &&
  o !== null &&
  (Reflect.has(o, 'from') ||
    Reflect.has(o, 'to') ||
    Reflect.has(o, 'interval'));

export const areDateRangesEqual = (
  left: DateRangeInterval,
  right: DateRangeInterval,
): boolean => {
  if (
    (left.from && right.from && !isSameDay(left.from, right.from)) ||
    left.from !== right.from
  ) {
    return false;
  }
  if (
    (left.to && right.to && !isSameDay(left.to, right.to)) ||
    left.to !== right.to
  ) {
    return false;
  }
  return left.interval === right.interval;
};

export const dedup = <T extends FilterValues>(
  parsed: Record<keyof T, string | Array<string>>,
): Record<keyof T, string | Array<string>> => {
  const copy: Partial<Record<keyof T, string | Array<string>>> = {};
  Object.entries(parsed).forEach(([key, prop]) => {
    if (Array.isArray(prop)) {
      copy[key as keyof T] = Array.from(new Set(prop));
    } else {
      copy[key as keyof T] = prop;
    }
  });
  return copy as Record<keyof T, string | Array<string>>;
};

export const isFilterEmpty = <T extends FilterValues>(filter: T): boolean => {
  const keys = new Set(Object.keys(filter));
  keys.delete('unarchivedOnly');
  keys.delete('freezeAt');
  return keys.size === 0;
};

export const multiSelectStyles: StylesConfig<unknown, true> = {
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
};

export const singleSelectStyles: StylesConfig<unknown, false> = {
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
};
