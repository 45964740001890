/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetTagResponse,
    GetTagResponseFromJSON,
    GetTagResponseToJSON,
    ListAllTagsResponse,
    ListAllTagsResponseFromJSON,
    ListAllTagsResponseToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
} from '../models';

export interface CreateOrUpdateTagRequest {
    tagName: string;
    tag?: Tag;
}

export interface DeleteTagRequest {
    tagName: string;
}

export interface GetTagRequest {
    tagName: string;
}

/**
 * TagsApi - interface
 * 
 * @export
 * @interface TagsApiInterface
 */
export interface TagsApiInterface {
    /**
     * 
     * @summary Create or Update a tag
     * @param {string} tagName The name of the tag to retrieve
     * @param {Tag} [tag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    createOrUpdateTagRaw(requestParameters: CreateOrUpdateTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Create or Update a tag
     */
    createOrUpdateTag(requestParameters: CreateOrUpdateTagRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Delete a single tag
     * @param {string} tagName The name of the tag to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a single tag
     */
    deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Get a single tag, with metadata and permissions
     * @param {string} tagName The name of the tag to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTagRaw(requestParameters: GetTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTagResponse>>;

    /**
     * Get a single tag, with metadata and permissions
     */
    getTag(requestParameters: GetTagRequest, initOverrides?: RequestInit): Promise<GetTagResponse>;

    /**
     * 
     * @summary List all tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    listAllTagsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllTagsResponse>>;

    /**
     * List all tags
     */
    listAllTags(initOverrides?: RequestInit): Promise<ListAllTagsResponse>;

}

/**
 * 
 */
export class TagsApi extends runtime.BaseAPI implements TagsApiInterface {

    /**
     * Create or Update a tag
     */
    async createOrUpdateTagRaw(requestParameters: CreateOrUpdateTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling createOrUpdateTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreements/tags/{tagName}`.replace(`{${"tagName"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagToJSON(requestParameters.tag),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create or Update a tag
     */
    async createOrUpdateTag(requestParameters: CreateOrUpdateTagRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createOrUpdateTagRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a single tag
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling deleteTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/tags/{tagName}`.replace(`{${"tagName"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single tag
     */
    async deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTagRaw(requestParameters, initOverrides);
    }

    /**
     * Get a single tag, with metadata and permissions
     */
    async getTagRaw(requestParameters: GetTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTagResponse>> {
        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling getTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/tags/{tagName}`.replace(`{${"tagName"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTagResponseFromJSON(jsonValue));
    }

    /**
     * Get a single tag, with metadata and permissions
     */
    async getTag(requestParameters: GetTagRequest, initOverrides?: RequestInit): Promise<GetTagResponse> {
        const response = await this.getTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all tags
     */
    async listAllTagsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllTagsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllTagsResponseFromJSON(jsonValue));
    }

    /**
     * List all tags
     */
    async listAllTags(initOverrides?: RequestInit): Promise<ListAllTagsResponse> {
        const response = await this.listAllTagsRaw(initOverrides);
        return await response.value();
    }

}
