import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getIn, useFormikContext } from 'formik';
import {
  Card,
  CardBody,
  CardTitle,
  Field,
  Label,
  InfoCircle,
  PrimaryButton,
  useEditContext,
} from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DateField,
  DynamicList,
  EnumDecision,
  PillMultiSelect,
  Select,
  TextArea,
  TextField,
} from '@fcg-tech/regtech-formik-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  categoryPropType,
  internalEntityPropType,
  metadataPropType,
  attachmentPropType,
  agreementPropType,
} from '../../../propTypes';
import { AccessControl } from '../../../components/AccessControl';
import { NONE_SELECT_ITEM } from '../../../constants';
import { getTooltip } from '../../../utils/tooltipHelpers';
import {
  internalEntityToSelectOption,
  supplierToSelectOption,
  agreementTypeToSelectOption,
  countrySelectOptions,
} from '../../../utils/optionConverters';
import { GlobalActions } from '../../../types';
import { MessageKeys } from '../../../translations/messageKeys';
import { currencyState } from '../../../states/currencyState';
import { countryState } from '../../../states/countryState';
import { currencySelectOption } from '../../../utils/optionConverters';
import { CurrencyAmountField } from '../../CurrencyAmountField';
import { NoticePeriodField } from '../../NoticePeriodField';
import { FormRow, FormColumn, FormGroup } from '../../FormLayout';
import { Attachments } from '../../Attachments';
import { FieldErrorNonBlocking } from '../../FieldError/FieldErrorNonBlocking';
import { FieldError } from '../../FieldError/FieldError';
import { FieldCompare } from '../../FieldCompare/FieldCompare';
import Features from '../../../Features';
import { STATUS_SELECT_ITEM } from '../../../constants';

const AddSupplierButtonWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * + * {
    margin-left: 1rem;
  }
`;

export const DetailsSection = ({
  metadata,
  agreement,
  attachments,
  internalEntities,
  agreementTypes,
  suppliers,
  setFieldValue,
  onAddSupplier,
}) => {
  const { t } = useTranslation();
  const currencies = useRecoilValue(currencyState);
  const countries = useRecoilValue(countryState);
  const isEditEnabled = useEditContext();

  const countryOptions = useMemo(
    () => countries?.map(countrySelectOptions),
    [countries],
  );

  const currencyOptions = useMemo(
    () => currencies?.map(currencySelectOption),
    [currencies],
  );

  const internalEntityOptions = useMemo(
    () => internalEntities.map(internalEntityToSelectOption),
    [internalEntities],
  );

  const supplierOptions = useMemo(
    () => suppliers.map(supplierToSelectOption),
    [suppliers],
  );

  const agreementTypeOptions = useMemo(
    () => agreementTypes?.map(agreementTypeToSelectOption),
    [agreementTypes],
  );

  // TODO: temp solution
  React.useEffect(() => {
    if (isEditEnabled) {
      if (agreement.details?.term?.endDateRequired === false) {
        setFieldValue('details.term.endDate', null);
      }
      if (agreement.details?.term?.endDateRequired === null) {
        setFieldValue('details.term.endDate', undefined);
      }
    }
  }, [agreement, setFieldValue, isEditEnabled]);

  const { errors } = useFormikContext();

  return (
    <Card>
      <CardBody>
        <CardTitle>{t(MessageKeys.DetailsSectionLabel)}</CardTitle>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-name">
                {t(MessageKeys.DetailsSectionAgreementNameLabel)}
              </Label>
              <Field
                component={TextField}
                name="details.name"
                type="text"
                id="agreement-name"
                info={getTooltip('details.name')}
                error={getIn(errors, 'details.name')}
              />
              <FieldError name="details.name" />
              <FieldCompare name="details.name" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-type">
                {t(MessageKeys.DetailsSectionAgreementTypeLabel)}
              </Label>
              <Field
                component={Select}
                items={[NONE_SELECT_ITEM, ...agreementTypeOptions]}
                typeahead
                name="details.agreementType"
                id="agreement-type"
                info={getTooltip('details.agreementType')}
              />
              {Features.FieldErrors.NonBlocking.Agreement.Details
                .AgreementType && (
                <FieldErrorNonBlocking name="details.agreementType" />
              )}
              <FieldCompare
                name="details.agreementType"
                nameOptions={agreementTypeOptions}
              />
            </FormGroup>
            <FormGroup>
              <EnumDecision.FieldContainer>
                <EnumDecision.FieldLabel htmlFor="agreement-main-supplier">
                  {t(MessageKeys.DetailsSectionSupplierLabel)}
                </EnumDecision.FieldLabel>
                <Field
                  component={EnumDecision}
                  name="details.mainSupplier.isExternal"
                  items={[
                    {
                      label: t(MessageKeys.DetailsSectionSupplierExternalLabel),
                      value: true,
                    },
                    {
                      label: t(MessageKeys.DetailsSectionSupplierInternalLabel),
                      value: false,
                    },
                  ]}
                />
              </EnumDecision.FieldContainer>
              <br />
              <Field
                component={Select}
                key={`suppliers-field-${
                  agreement.details?.mainSupplier?.isExternal
                    ? supplierOptions?.length
                    : internalEntityOptions?.length
                }`}
                noOptionsText={t(MessageKeys.DetailsSectionNoOptionsTextLabel)}
                typeahead
                id="agreement-main-supplier"
                info={getTooltip('details.mainSupplier.isExternal')}
                items={
                  agreement.details?.mainSupplier?.isExternal
                    ? [NONE_SELECT_ITEM, ...supplierOptions]
                    : [NONE_SELECT_ITEM, ...internalEntityOptions]
                }
                name={
                  agreement.details?.mainSupplier?.isExternal
                    ? 'details.mainSupplier.externalSupplier'
                    : 'details.mainSupplier.internalSupplier'
                }
              />
              {Features.FieldErrors.NonBlocking.Agreement.Details.Supplier && (
                <FieldErrorNonBlocking
                  name={
                    agreement.details?.mainSupplier?.isExternal
                      ? 'details.mainSupplier.externalSupplier'
                      : 'details.mainSupplier.internalSupplier'
                  }
                />
              )}
              <FieldCompare
                name={
                  agreement.details?.mainSupplier?.isExternal
                    ? 'details.mainSupplier.externalSupplier'
                    : 'details.mainSupplier.internalSupplier'
                }
                nameOptions={
                  agreement.details?.mainSupplier?.isExternal
                    ? supplierOptions
                    : internalEntityOptions
                }
              />
              {onAddSupplier &&
              isEditEnabled &&
              agreement.details?.mainSupplier?.isExternal ? (
                <AccessControl requiredPermissions={GlobalActions.SupplierAdd}>
                  <AddSupplierButtonWrapper>
                    <PrimaryButton type="button" onClick={onAddSupplier}>
                      {t(MessageKeys.SupplierCreateNewSupplierLabel)}
                    </PrimaryButton>
                    <InfoCircle
                      info={getTooltip(
                        'details.mainSupplier.createExternalSupplier',
                      )}
                    />
                  </AddSupplierButtonWrapper>
                </AccessControl>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-party-to-arrangement">
                {t(MessageKeys.DetailsSectionPartyToAgreementLabel)}
              </Label>
              <Field
                component={Select}
                items={[NONE_SELECT_ITEM, ...internalEntityOptions]}
                typeahead
                name="details.partyToAgreement"
                id="agreement-party-to-arrangement"
                info={getTooltip('details.partyToAgreement')}
              />
              {Features.FieldErrors.NonBlocking.Agreement.Details
                .PartyToAgreement && (
                <FieldErrorNonBlocking name="details.partyToAgreement" />
              )}
              <FieldCompare
                name="details.partyToAgreement"
                nameOptions={internalEntityOptions}
              />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-service-receivers">
                {t(MessageKeys.DetailsSectionReceiversProvidedServiceLabel)}
              </Label>
              <Field
                component={PillMultiSelect}
                items={internalEntityOptions}
                typeahead
                name="details.receiversOfProvidedService"
                id="agreement-service-receivers"
                info={getTooltip('details.receiversOfProvidedService')}
              />
              <FieldErrorNonBlocking
                name="details.receiversOfProvidedService"
                nameOptions={internalEntityOptions}
              />
              <FieldCompare name="details.receiversOfProvidedService" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-contract-owner">
                {t(MessageKeys.DetailsSectionContractOwnerLabel)}
              </Label>
              <Field
                component={TextField}
                name="details.contractOwner"
                id="agreement-contract-owner"
                type="email"
                info={getTooltip('details.contractOwner')}
                error={getIn(errors, 'details.contractOwner')}
              />
              <FieldError name="details.contractOwner" />
              <FieldCompare name="details.contractOwner" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-internal-reference">
                {t(MessageKeys.DetailsSectionInternalReferenceLabel)}
              </Label>
              <Field
                component={DynamicList}
                placeholder="Enter further contact details"
                name="details.internalReference"
                id="agreement-internal-reference"
                info={getTooltip('details.internalReference')}
              />
              <FieldCompare name="details.internalReference" />
            </FormGroup>
          </FormColumn>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-status">
                {t(MessageKeys.DetailsSectionStatusLabel)}
              </Label>
              <Field
                component={Select}
                items={[NONE_SELECT_ITEM, ...STATUS_SELECT_ITEM]}
                typeahead
                name="details.status"
                id="agreement-status"
                info={getTooltip('details.status')}
              />
              <FieldCompare name="details.status" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-start-date">
                {t(MessageKeys.DetailsSectionStartDateLabel)}
              </Label>
              <Field
                component={DateField}
                name="details.term.startDate"
                type="date"
                id="agreement-start-date"
                info={getTooltip('details.term.startDate')}
              />
              {Features.FieldErrors.NonBlocking.Agreement.Details.Term
                .StartDate && (
                <FieldErrorNonBlocking name="details.term.startDate" />
              )}
              <FieldCompare name="details.term.startDate" />
            </FormGroup>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  {t(MessageKeys.DetailsSectionEndDateLabel)}
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="details.term.endDateRequired"
                  info={getTooltip('details.term.endDateRequired')}
                />
              </BooleanDecision.FieldContainer>
              <br />
              {agreement.details.term?.endDateRequired && (
                <Field
                  component={DateField}
                  name="details.term.endDate"
                  type="date"
                  id="agreement-end-date"
                  info={getTooltip('details.term.endDate')}
                />
              )}
              {Features.FieldErrors.NonBlocking.Agreement.Details.Term
                .EndDate && (
                <FieldErrorNonBlocking name="details.term.endDate" />
              )}
              <FieldCompare name="details.term.endDate" />
            </FormGroup>

            <FormGroup>
              <Label column htmlFor="agreement-renewal-date">
                {t(MessageKeys.DetailsSectionDateAgreementRenewalLabel)}
              </Label>
              <Field
                component={DateField}
                name="details.term.renewalDate"
                type="date"
                id="agreement-renewal-date"
                info={getTooltip('details.term.renewalDate')}
              />
              <FieldCompare name="details.term.renewalDate" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-notice-period-service-provider">
                {t(MessageKeys.DetailsSectionNoticePeriodSupplierLabel)}
              </Label>
              <Field
                component={NoticePeriodField}
                name="details.term.noticePeriodSupplier"
                id="agreement-notice-period-supplier"
                info={getTooltip('details.term.noticePeriodSupplier')}
              />
              <FieldCompare name="details.term.noticePeriodSupplier" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-notice-period-institution">
                {t(MessageKeys.DetailsSectionNoticePeriodInternalEntityLabel)}
              </Label>
              <Field
                component={NoticePeriodField}
                name="details.term.noticePeriodInternalEntity"
                id="agreement-notice-period-internal-entity"
                info={getTooltip('details.term.noticePeriodInternalEntity')}
              />
              <FieldCompare name="details.term.noticePeriodInternalEntity" />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="agreement-archiving-reference">
                {t(MessageKeys.DetailsSectionArchivingReferenceLabel)}
              </Label>
              <Field
                component={TextField}
                name="details.archivingReference"
                type="text"
                id="agreement-archiving-reference"
                info={getTooltip('details.archivingReference')}
              />
              <FieldCompare name="details.archivingReference" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-latest-review-date">
                {t(MessageKeys.DetailsSectionLatesReviewLabel)}
              </Label>
              <Field
                component={DateField}
                name="details.latestReview"
                type="date"
                id="agreement-latest-review-date"
                info={getTooltip('details.latestReview')}
              />
              <FieldCompare name="details.latestReview" />
            </FormGroup>
          </FormColumn>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-next-review-date">
                {t(MessageKeys.DetailsSectionNextReviewLabel)}
              </Label>
              <Field
                component={DateField}
                name="details.nextReview"
                type="date"
                id="agreement-next-review-date"
                info={getTooltip('details.nextReview')}
              />
              <FieldCompare name="details.nextReview" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-governing-law">
                {t(MessageKeys.DetailsSectionGowerningLawLabel)}
              </Label>
              <Field
                component={Select}
                items={[NONE_SELECT_ITEM, ...countryOptions]}
                name="details.governingLawOftheAgreement"
                id="agreement-governing-law"
                info={getTooltip('details.governingLawOftheAgreement')}
                typeahead
              />
              <FieldErrorNonBlocking name="details.governingLawOftheAgreement" />
              <FieldCompare name="details.governingLawOftheAgreement" />
            </FormGroup>
          </FormColumn>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-estimated-annual-budget-cost">
                {t(MessageKeys.DetailsSectionEstimatedAnnualBudgetLabel)}
              </Label>
              <Field
                id="agreement-estimated-annual-budget-cost"
                name="details.annualBudgetCostEstimation"
                component={CurrencyAmountField}
                items={currencyOptions}
                info={getTooltip('details.annualBudgetCostEstimation')}
              />
              <FieldErrorNonBlocking name="details.annualBudgetCostEstimation.amount" />
              <FieldErrorNonBlocking name="details.annualBudgetCostEstimation.currency" />
              <FieldCompare name="details.annualBudgetCostEstimation.amount" />
              <FieldCompare name="details.annualBudgetCostEstimation.currency" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup>
              <Label column htmlFor="agreement-brief-description">
                {t(MessageKeys.DetailsSectionDescriptionLabel)}
              </Label>
              <Field
                component={TextArea}
                name="details.briefDescription"
                id="agreement-brief-description"
                info={getTooltip('details.briefDescription')}
              />
              {Features.FieldErrors.NonBlocking.Agreement.Details
                .BriefDescription && (
                <FieldErrorNonBlocking name="details.briefDescription" />
              )}
              <FieldCompare name="details.briefDescription" />
            </FormGroup>
          </FormColumn>
        </FormRow>
        {metadata && (
          <Attachments
            title={t(MessageKeys.DetailsSectionAgreementsLabel)}
            attachments={attachments}
            section="details"
            noAttachmentsFoundMessage={t(
              MessageKeys.DetailsSectionNoAttachmentsLabel,
            )}
          />
        )}
      </CardBody>
    </Card>
  );
};

DetailsSection.propTypes = {
  metadata: metadataPropType,
  agreement: agreementPropType,
  attachments: PropTypes.arrayOf(attachmentPropType),
  // TODO: service provider prop type
  suppliers: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(categoryPropType),
  internalEntities: PropTypes.arrayOf(internalEntityPropType),
  setFieldValue: PropTypes.func,
  onAddSupplier: PropTypes.func,
};

DetailsSection.defaultProps = {
  metadata: null,
  agreement: null,
  attachments: [],
  suppliers: [],
  categories: [],
  internalEntities: [],
  setFieldValue: PropTypes.func,
  onAddSupplier: null,
};
