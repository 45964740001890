export enum MessageKeys {
  LabelActions = 'label-actions',
  LabelAdd = 'label-add',
  LabelAgreement = 'label-agreement',
  LabelAgreements = 'label-agreements',
  LabelAgreementType = 'label-agreement-type',
  LabelAll = 'label-all',
  LabelArchived = 'label-archived',
  LabelCabinet = 'label-cabinet',
  LabelCancel = 'label-cancel',
  LabelCategory = 'label-category',
  LabelClear = 'label-clear',
  LabelCompleted = 'label-completed',
  LabelConfirm = 'label-confirm',
  LabelConfirmAction = 'label-confirm-action',
  LabelConfirmDelete = 'label-confirm-delete',
  LabelConfirmed = 'label-confirmed',
  LabelConfirmLabel = 'label-confirm-label',
  LabelContractOwner = 'label-contract-owner',
  LabelCouldNotSave = 'label-could-not-save',
  LabelCurrent = 'label-current',
  LabelDelete = 'label-delete',
  LabelDescription = 'label-description',
  LabelDetails = 'label-details',
  LabelDisabled = 'label-disabled',
  LabelDownloadCSV = 'label-download-csv',
  LabelEdit = 'label-edit',
  LabelEnabled = 'label-enabled',
  LabelErrorOccured = 'label-error-occured',
  LabelExclude = 'label-exclude',
  LabelExport = 'label-export',
  LabelFilename = 'label-filename',
  LabelHistory = 'label-history',
  LabelIntragroupAgreements = 'label-intragroup-agreements',
  LabelLoadingAgreement = 'label-loding-agreement',
  LabelLoadingCabinet = 'label-loding-cabinet',
  LabelLoadingCalendar = 'label-loding-calendar',
  LabelLoadingCategory = 'label-loding-category',
  LabelLoadingDashboard = 'label-loding-dashboard',
  LabelLoadingDetails = 'label-loding-details',
  LabelLoadingInternalEntity = 'label-loding-internal-entity',
  LabelLoadingReport = 'label-loding-report',
  LabelLoadingTag = 'label-loding-tag',
  LabelLogin = 'label-login',
  LabelLogout = 'label-logout',
  LabelMarkAsDone = 'label-mark-as-done',
  LabelMove = 'label-move',
  LabelName = 'label-name',
  LabelNo = 'label-no',
  LabelNoAgreementsFound = 'label-no-agreements-found',
  LabelNoItems = 'label-no-items',
  LabelNotArchived = 'label-not-archived',
  LabelOk = 'label-ok',
  LabelOtherDocumentation = 'label-other-documentation',
  LabelPrint = 'label-print',
  LabelProceed = 'label-proceed',
  LabelProceedSaving = 'label-proceed-saving',
  LabelReports = 'label-reports',
  LabelRequired = 'label-required',
  LabelSave = 'label-save',
  LabelSearchResultSummary = 'label-search-result-summary',
  LabelSomethingWentWrong = 'label-something-went-wrong',
  LabelSubmit = 'label-submit',
  LabelSummary = 'label-summary',
  LabelSupplier = 'label-supplier',
  LabelUnknown = 'label-unknown',
  LabelUnnamed = 'label-unnamed',
  LabelUploaded = 'label-uploaded',
  LabelUploadFiles = 'label-upload-files',
  LabelUploadedLowerCase = 'label-uploaded-lower-case',
  LabelVersion = 'label-version',
  LabelWarning = 'label-warning',
  LabelYes = 'label-yes',

  AgreementHistoryHeading = 'agreement-history-heading',

  AgreementsOverviewHeading = 'agreements-overview-heading',
  AgreementsOverviewCreateNewAgreementLabel = 'agreements-overview-create-new-agreement-label',
  AgreementsOverviewCreateAgreementFailure = 'agreements-overview-create-agreement-failure',
  AgreementsOverviewCreateAgreementSuccess = 'agreements-overview-create-agreement-success',
  AgreementsOverviewCreateAgreementConfirmForceQuestion = 'agreements-overview-create-agreement-confirm-force-question',
  AgreementsOverviewCreateAgreementNameRequired = 'agreements-overview-create-agreement-name-required',
  AgreementsOverviewCreateAgreementCabinetRequired = 'agreements-overview-create-agreement-cabinet-required',
  AgreementsOverviewCreateAgreementName = 'agreements-overview-create-agreement-name',
  AgreementsOverviewCreateAgreementCabinet = 'agreements-overview-create-agreement-cabinet',
  AgreementsOverviewCreateAgreementTags = 'agrements-overview-create-agreement-tags',

  AgreementFilterIncludeArchivedTooltip = 'agreement-table-filter-include-archived-tooltip',
  AgreementFilterExcludeArchivedTooltip = 'agreement-table-filter-exclude-archived-tooltip',
  AgreementFilterColumnPickerTooltip = 'agreement-table-column-picker-tooltip',

  AgreementTableNameLabel = 'agreement-table-name-label',
  AgreementTableAgreementTypeLabel = 'agreement-table-agreement-type-label',
  AgreementTableFunctionCategoryLabel = 'agreement-table-function-category-label',
  AgreementTableBriefDescriptionLabel = 'agreement-table-brief-description-label',
  AgreementTableContractOwnerLabel = 'agreement-table-contract-owner-label',
  AgreementTableSupplierNameLabel = 'agreement-table-supplier-name-label',
  AgreementTablePartyToAgreementLabel = 'agreement-table-party-to-agreement-label',
  AgreementTableInternalReferenceLabel = 'agreement-table-internal-reference-label',
  AgreementTableCabinetLabel = 'agreement-table-cabinet-label',
  AgreementTableStatusLabel = 'agreement-table-status-label',
  AgreementTableStartDateLabel = 'agreement-table-start-date-label',
  AgreementTableEndDateLabel = 'agreement-table-end-date-label',
  AgreementTableLatestReviewLabel = 'agreement-table-latest-review-label',
  AgreementTableNextReviewLabel = 'agreement-table-next-review-label',
  AgreementTableArchivingReferenceLabel = 'agreement-table-archiving-reference-label',
  AgreementTableCompetentAuthorityNotifiedLabel = 'agreement-table-competent-authority-notified-label',
  AgreementTableIsOutsourcingArrangementLabel = 'agreement-table-is-outsourcing-arrangement-label',
  AgreementTableIsCriticalLabel = 'agreement-table-is-critical-label',
  AgreementTableNrOfCommentsLabel = 'agreement-table-nr-of-comments-label',
  AgreementTableIsPersonalDataTransferredLabel = 'agreement-table-is-personal-data-transferred-label',
  AgreementTableIsPersonalDataProcessedLabel = 'agreement-table-is-personal-data-processed-label',
  AgreementTableTagsLabel = 'agreement-table-tags-label',
  AgreementTableNoItems = 'agreement-table-no-items',
  AgreementTableShowCommentsLabel = 'agreement-table-show-comments-label',

  AgreementTypesHeading = 'agreement-types-heading',
  AgreementTypesCreateNewAgreementTypeLabel = 'agreement-types-create-new-agreement-type-label',
  AgreementTypesCreateAgreementTypeFailure = 'agreement-types-create-agreement-types-failure',
  AgreementTypesCreateAgreementTypeSuccess = 'agreement-types-create-agreement-type-success',
  AgreementTypesNoItems = 'agreement-types-no-items',

  AttachmentTableFilenameLabel = 'attachment-table-filename-label',
  AttachmentTableUploadedLabel = 'attachment-table-uploaded-label',
  AttachmentTableNoItems = 'attachment-table-no-items',
  AttachmentTableConfirmDeleteQuestion = 'attachment-table-confirm-delete-question',

  CabinetsHeading = 'cabinets-heading',
  CabinetsCreateNewCabinetLabel = 'cabinets-create-new-cabinet-label',
  CabinetsCreateCabinetNameFormatTooltip = 'cabinets-create-cabinet-name-format-tooltip',
  CabinetsCreateCabinetFailure = 'cabinets-create-cabinet-failure',
  CabinetsCreateCabinetSuccess = 'cabinets-create-cabinet-success',
  CabinetsNoItems = 'cabinets-no-items',

  CategoriesHeading = 'categories-heading',
  CategoriesDescription = 'categories-description',
  CategoriesCreateNewCategoryLabel = 'categories-create-new-category-label',
  CategoriesCreateCategoryFailure = 'categories-create-category-failure',
  CategoriesCreateCategorySuccess = 'categories-create-category-success',
  CategoriesNoItems = 'categories-no-items',

  DashboardAllCaughtUp = 'dashboard-all-caught-up',
  DashboardTotalAgreementsCount = 'dashboard-agreement-count',
  DashboardComplianceHeading = 'dashboard-compliance-heading',
  DashboardComplianceAdditionalInfoRequired = 'dashboard-compliance-additional-info-required',
  DashboardComplianceOutsourcingArrangementNotSet = 'dashboard-compliance-outsourcing-arrangement-not-set',
  DashboardComplianceCriticalOrImportantNotSet = 'dashboard-compliance-critical-or-important-not-set',
  DashboardSummaryHeading = 'dashboard-summary-heading',
  DashboardSummaryCriticalOrImportant = 'dashboard-summary-critical-or-important',
  DashboardSummaryOutsourcingArrangement = 'dashboard-summary-outsourcing-arrangement',
  DashboardUpcomingYearHeading = 'dashboard-upcoming-year-heading',
  DashboardUpcomingYearAuditRequired = 'dashboard-upcoming-year-audit-required',
  DashboardUpcomingYearNumberOfAgreements = 'dashboard-upcoming-year-number-of-agreements',
  DashboardHoistGettingStartedHeading = 'dashboard-hoist-getting-started-heading',
  DashboardHoistGettingStartedBody = 'dashboard-hoist-instruction-video-body-label',
  DashboardNotificationCount = 'dashboard-notification-count',
  DashboardAndCountMoreNotifications = 'dashboard-and-count-more-notifications',
  DashboardViewAllNotifications = 'dashboard-view-all-notifications',
  DashboardViewAllAgreements = 'dashboard-view-all-agreements',

  DocumentationLinkTooltip = 'documentation-link-tooltip',

  ErrorAccessDenied = 'error-access-denied',
  ErrorAccessDeniedMessage = 'error-access-denied-message',
  ErrorGeneric = 'error-generic',
  ErrorNotFoundHeading = 'error-not-found-heading',
  ErrorNotFoundInfo = 'error-not-found-info',
  ErrorReportToSupportMessage = 'error-report-to-support-message',

  FileUploadZoneActiveMessage = 'file-upload-zone-active-message',
  FileUploadZoneHoverMessage = 'file-upload-zone-upload-hover-message',
  FileUploadZoneDragFilesHintLabel = 'file-upload-zone-drag-files-hint-label',
  FileUploadZoneSelectFilesButtonLabel = 'file-upload-zone-select-files-button-label',
  FileUploadZoneUploadErrorMessage = 'file-upload-zone-upload-error-message',
  FileUploadZoneUploadInProgressMessage = 'file-upload-zone-upload-in-progress-message',
  FileUploadZoneConfirmRemoveFileMessage = 'file-upload-zone-confirm-remove-file-message',
  FileUploadZoneNoItems = 'file-upload-zone-no-items',

  FilterSaveSuccess = 'filter-save-success',
  FilterSaveFailure = 'filter-save-failure',
  FilterDeleteSuccess = 'filter-delete-success',
  FilterDeleteFailure = 'filter-delete-failure',
  FilterSaveFilterButtonLabel = 'filter-save-filter-button-label',
  FilterSaveFilterNameLabel = 'filter-save-filter-name-label',
  FilterConfirmDeleteQuestion = 'filter-confirm-delete-question',

  InternalEntitiesHeading = 'internal-entities-heading',
  InternalEntitiesCreateNewInternalEntityLabel = 'internal-entities-create-new-internal-entity-label',
  InternalEntitiesCreateInternalEntityFailure = 'internal-entities-create-internal-entity-failure',
  InternalEntitiesCreateInternalEntitySuccess = 'internal-entities-create-internal-entity-success',
  InternalEntitiesNoItems = 'internal-entities-no-items',

  InternalEntityName = 'internal-entity-name',
  InternalEntityCountryOfRegistration = 'internal-entity-country-of-registration',
  InternalEntityRegisteredAddress = 'internal-entity-registered-address',
  InternalEntityUltimateParent = 'internal-entity-ultimate-parent',
  InternalEntityParentCompany = 'internal-entity-parent-company',
  InternalEntityCorporateRegistrationNumber = 'internal-entity-corporate-registration-number',
  InternalEntityLegalEntityIdentifier = 'internal-entity-legal-entity-identifier',
  InternalEntityContactDetails = 'internal-entity-contact-details',
  InternalEntityContactDetailsPlaceholder = 'internal-entity-contact-details-placeholder',
  InternalEntityDescription = 'internal-entity-description',

  ReportsHeading = 'reports-heading',
  ReportsCreateNewReportLabel = 'reports-create-new-report-label',
  ReportsCreateReportFailure = 'reports-create-report-failure',
  ReportsCreateReportSuccess = 'reports-create-report-success',
  ReportsNoItems = 'reports-no-items',

  TagsHeading = 'tags-heading',
  TagsCreateNewTagLabel = 'tags-create-new-tag-label',
  TagsCreateTagNameFormatTooltip = 'tags-create-tag-name-format-tooltip',
  TagsCreateTagFailure = 'tags-create-tag-failure',
  TagsCreateTagSuccess = 'tags-create-tag-success',
  TagsNoItems = 'tags-no-items',

  AgreementAssessmentSectionLabel = 'agreement-assessment-section-label',
  AgreementAssessmentSectionOutsourcingArrangementLabel = 'agreement-assessment-section-outsourcing-arrangement-label',
  AgreementAssessmentSectionCategoryLabel = 'agreement-assessment-section-category-label',
  AgreementAssessmentSectionCriticalImportantCompletedLabel = 'agreement-assessment-section-critical-important-completed-label',
  AgreementAssessmentSectionLastCriticalImportantLabel = 'agreement-assessment-section-last-critical-important-label',
  AgreementAssessmentSectionAssessmentSummaryLabel = 'agreement-assessment-section-assessment-summary-label',
  AgreementAssessmentSectionCompetentAuthorityDescriptionLabel = 'agreement-assessment-section-competent-authority-notified-label',
  AgreementAssessmentSectionCompetentAuthorityNotifiedDateLabel = 'agreement-assessment-section-competent-authority-notified-date-label',
  AgreementAssessmentSectionCompetentAuthorityNotifiedLabel = 'agreement-assessment-section-competent-authority-description-label',
  AgreementAssessmentSectionCriticalImportantLabel = 'agreement-assessment-section-critical-important-label',
  AgreementAssessmentSectionSupplierInstitutionGroupLabel = 'agreement-assessment-section-supplier-institution-group-label',
  AgreementAssessmentSectionRiskAssessmentCompletedLabel = 'agreement-assessment-section-risk-assessment-completed-label',
  AgreementAssessmentSectionLatestRiskAssessmentLabel = 'agreement-assessment-section-latest-risk-assessment-label',
  AgreementAssessmentSectionNextRiskAssessmentLabel = 'agreement-assessment-section-next-risk-assessment-label',
  AgreementAssessmentSectionRiskAssessmentSummaryLabel = 'agreement-assessment-section-risk-assessment-summary-label',
  AgreementAssessmentSectionFinalApprovalLabel = 'agreement-assessment-section-final-approval-label',
  AgreementAssessmentSectionLatestAuditLabel = 'agreement-assessment-section-latest-audit-label',
  AgreementAssessmentSectionNextAuditLabel = 'agreement-assessment-section-next-audit-label',
  AgreementAssessmentSectionAuditNotesLabel = 'agreement-assessment-section-audit-notes-label',
  AgreementAssessmentSectionExitStrategyLabel = 'agreement-assessment-section-exit-strategy-label',
  AgreementAssessmentSectionAlternativeServiceProviderLabel = 'agreement-assessment-section-alternative-service-provider-label',
  AgreementAssessmentSectionTimeCriticalOperationLabel = 'agreement-assessment-section-time-critical-operation-label',

  AgreementTypeSuccessfullyCreatedLabel = 'agreement-type-successfully-created-label',
  AgreementTypeFailedCreateLabel = 'agreement-type-failed-create-label',
  AgreementTypeLabel = 'agreement-type-label',
  AgreementTypeCreateLabel = 'agreement-type-create-label',
  AgreementTypeSuccessfullyUpdatedLabel = 'agreement-type-successfully-updated-label',
  AgreementTypeSuccessfullyDeletedLabel = 'agreement-type-successfully-deleted-label',
  AgreementTypeFailedDeleteLabel = 'agreement-type-failed-delete-label',
  AgreementTypeConfirmDeleteLabel = 'agreement-type-confirm-delete-label',

  AgreementVersionInfoLabel = 'agreement-version-info-label',
  AgreementVersionAdditionalInformationLabel = 'agreement-version-additional-information-label',
  AgreementVersionOtherAttachmentsLabel = 'agreement-version-other-attachments-label',

  CabinetMoveModalMoveAgreementLabel = 'cabinet-move-modal-move-agreement-label',
  CabinetMoveCaution = 'cabinet-move-caution',
  CabinetMoveCautionText = 'cabinet-move-caution-text',

  CalendarExportSecretAddressTooltipLabel = 'calendar-export-secret-address-tooltip-label',
  CalendarExportLabel = 'calendar-export-label',
  CalendarExportSecretAddressBodyLabel = 'calendar-export-secret-address-body-label',
  CalendarExportSecretAddressLabel = 'calendar-export-secret-address-label',
  CalendarExportCopyLabel = 'calendar-export-copy-label',
  CalendarExportDeleteSecretAddressLabel = 'calendar-export-delete-secret-address-label',
  CalendarExportGetSecretAddressLabel = 'calendar-export-get-secret-address-label',
  CalendarExportTodayLabel = 'calendar-export-today-label',
  CalendarExportAgendaLabel = 'calendar-export-agenda-label',
  CalendarExportCalendarLabel = 'calendar-export-calendar-label',
  CalendarExportExportLabel = 'calendar-export-exportlabel',

  ChangeManagementProcessSectionLabel = 'change-management-process-section-label',

  DetailsSectionLabel = 'details-section-label',
  DetailsSectionAgreementNameLabel = 'details-section-agreement-name-label',
  DetailsSectionAgreementTypeLabel = 'details-section-agreement-type-label',
  DetailsSectionSupplierLabel = 'details-section-supplier-label',
  DetailsSectionSupplierExternalLabel = 'details-section-supplier-external-label',
  DetailsSectionSupplierInternalLabel = 'details-section-supplier-internal-label',
  DetailsSectionNoOptionsTextLabel = 'details-section-no-option-text-label',
  DetailsSectionPartyToAgreementLabel = 'details-section-party-to-agreement-label',
  DetailsSectionReceiversProvidedServiceLabel = 'details-section-receivers-provided-service-label',
  DetailsSectionContractOwnerLabel = 'details-section-contract-owner-label',
  DetailsSectionInternalReferenceLabel = 'details-section-internal-reference-label',
  DetailsSectionInternalReferencePlaceholderLabel = 'details-section-internal-reference-placeholderlabel',
  DetailsSectionStatusLabel = 'details-section-status-label',
  DetailsSectionStartDateLabel = 'details-section-start-date-label',
  DetailsSectionEndDateLabel = 'details-section-end-date-label',
  DetailsSectionDateAgreementRenewalLabel = 'details-section-date-agreement-renewal-label',
  DetailsSectionNoticePeriodSupplierLabel = 'details-section-notice-period-supplier-label',
  DetailsSectionNoticePeriodInternalEntityLabel = 'details-section-notice-period-internal-entity-label',
  DetailsSectionArchivingReferenceLabel = 'details-section-archiving-reference-label',
  DetailsSectionLatesReviewLabel = 'details-section-latest-review-label',
  DetailsSectionNextReviewLabel = 'details-section-next-review-label',
  DetailsSectionGowerningLawLabel = 'details-section-governing-law-label',
  DetailsSectionEstimatedAnnualBudgetLabel = 'details-section-estimated-annual-budget-label',
  DetailsSectionDescriptionLabel = 'details-section-description-label',
  DetailsSectionAgreementsLabel = 'details-section-agreements-label',
  DetailsSectionNoAttachmentsLabel = 'details-section-no-attachments-label',

  DropzoneMinFileSizeLabel = 'dropzone-min-file-size-label',
  DropzoneMaxFileSizeLabel = 'dropzone-max-file-size-label',

  EditAgreementContractOwnerWarningLabel = 'edit-agreement-contract-owner-warning-label',
  EditAgreementCurrentVersionLabel = 'edit-agreement-curren-version-label',
  EditAgreementUpdateRequiredFieldsLabel = 'edit-agreement-update-required-fields-label',
  EditAgreementAdditionalInformationLabel = 'edit-agreement-additional-information-label',
  EditAgreementSuccessfullyDeletedLabel = 'edit-agreement-successfully-deleted-label',
  EditAgreementFailedDeleteLabel = 'edit-agreement-failed-delete-label',
  EditAgreementSuccessfullyUpdatedLabel = 'edit-agreement-successfully-updated-label',
  EditAgreementSuccessfullyArchivedLabel = 'edit-agreement-successfully-archived-label',
  EditAgreementSuccessfullyUnarchivedLabel = 'edit-agreement-successfully-unarchived-label',
  EditAgreementFailedArchivedLabel = 'edit-agreement-failed-archived-label',
  EditAgreementFailedUnarchivedLabel = 'edit-agreement-failed-unarchived-label',
  EditAgreementArchivedLabel = 'edit-agreement-archived-label',
  EditAgreementUnarchivedLabel = 'edit-agreement-unarchived-label',
  EditAgreementArchivedHeadingLabel = 'edit-agreement-archived-heading-label',
  EditAgreementCabinetMoveLabel = 'edit-agreement-cabinet-move-label',
  EditAgreementCabinetMoveSuccessLabel = 'edit-agreement-cabinet-move-success-label',
  EditAgreementCabinetMoveFailLabel = 'edit-agreement-cabinet-move-fail-label',

  EditCabinetSuccessfullyDeletedLabel = 'edit-cabinet-successfully-deleted-label',
  EditCabinetFailedDeleteLabel = 'edit-cabinet-failed-deleted-label',
  EditCabinetConfirmDeleteLabel = 'edit-cabinet-confirm-delete-label',

  EditCategorySuccessfullyUpdatedLabel = 'edit-category-successfully-updated-label',
  EditCategorySuccessfullyDeletedLabel = 'edit-category-successfully-deleted-label',
  EditCategoryFailedDeleteLabel = 'edit-category-failed-deleted-label',
  EditCategoryConfirmDeleteLabel = 'edit-category-confirm-delete-label',

  EditInternalEntitySuccessfullyUpdatedLabel = 'edit-internal-entity-successfully-updated-label',
  EditInternalEntitySuccessfullyDeletedLabel = 'edit-internal-entity-successfully-deleted-label',
  EditInternalEntityFailedDeleteLabel = 'edit-internal-entity-failed-deleted-label',
  EditInternalEntityConfirmDeleteLabel = 'edit-internal-entity-confirm-delete-label',

  EditReportSuccessfullyUpdatedLabel = 'edit-report-successfully-updated-label',
  EditReportSuccessfullyDeletedLabel = 'edit-report-successfully-deleted-label',
  EditReportFailedDeleteLabel = 'edit-report-failed-deleted-label',
  EditReportConfirmDeleteLabel = 'edit-report-confirm-delete-label',

  EditSupplierCurrentVersionLabel = 'edit-supplier-current-version-label',
  EditSupplierSupplierAssessmentLabel = 'edit-supplier-supplier-assessment-label',
  EditSupplierSuccessfullyUpdatedLabel = 'edit-supplier-successfully-updated-label',
  EditSupplierSuccessfullyDeletedLabel = 'edit-supplier-successfully-deleted-label',
  EditRSupplierFailedDeleteLabel = 'edit-supplier-failed-deleted-label',
  EditSupplierConfirmDeleteLabel = 'edit-supplier-confirm-delete-label',

  ErrorBoundaryMessageHeaderLabel = 'error-boundary-message-header-label',
  ErrorBoundaryMessageBodyLabel = 'error-boundary-message-body-label',

  FieldCompareValueUpdatedFromLabel = 'field-compare-value-updated-from-label',
  FieldCompareValueUpdatedLabel = 'field-compare-value-updated-label',
  FieldCompareItemCreatedLabel = 'field-compare-item-created-label',
  FieldCompareItemDeletedLabel = 'field-compare-item-deleted-label',

  FileUploadModalDragDropLabel = 'file-upload-modal-drag-drop-label',
  FileUploadModalFlieDialogLabel = 'file-upload-modal-file-dialog-label',

  GlobalSearchHeading = 'global-search-heading',
  GlobalSearchTypeLabel = 'global-search-type-label',
  GlobalSearchNameLabel = 'global-search-name-label',
  GlobalSearchContentLabel = 'global-search-content-label',
  GlobalSearchNoItems = 'global-search-no-items',

  GlobalSearchAgreementAttachmentLabel = 'global-search-agreement-attachment-label',
  GlobalSearchSupplierAttachmentLabel = 'global-search-supplier-attachment-label',
  GlobalSearchResultQueryLabel = 'global-search-result-query-label',

  HistoryTableVersionLabel = 'history-table-version-label',
  HistoryTableTimestampLabel = 'history-table-timestamp-label',
  HistoryTableUserLabel = 'history-table-user-label',
  HistoryTableVNoVersionLabel = 'history-table-no-version-label',

  IEBlockerHeadingLabel = 'ie-blocker-heading-label',
  IEBlockerBodyLabel = 'ie-blocker-body-label',

  InternalEntitiesCountryRegistrationLabel = 'internal-entities-country-registration-label',
  InternalEntitiesCorporateRegistrationNumberabel = 'internal-entities-corporate-registration-number-label',
  InternalEntitiesEmptyTableTextLabel = 'internal-entities-empty-table-text-label',

  InternalEntityDetailsSubFormCountryRegistrationLabel = 'internal-entity-details-sub-form-country-registration-label',
  InternalEntityDetailsSubFormRegistratedAddressLabel = 'internal-entity-details-sub-form-registrated-address-label',
  InternalEntityDetailsSubFormUltimateParentLabel = 'internal-entity-details-sub-form-ultimate-parent-label',
  InternalEntityDetailsSubFormParentCompanyLabel = 'internal-entity-details-sub-form-parent-company-label',
  InternalEntityDetailsSubFormCorporateRegistrationNumberLabel = 'internal-entity-details-sub-form-corporate-registration-number-label',
  InternalEntityDetailsSubFormLegalEntityIdentifierLabel = 'internal-entity-details-sub-form-legal-entity-identifier-label',
  InternalEntityDetailsSubFormContactDetailsLabel = 'internal-entity-details-sub-form-contact-details-label',

  JSONTableResultNotRepresentedLabel = 'json-table-result-not-represented-label',

  ListDataTableNOItemsLabel = 'list-data-table-no-items-label',

  MainLayoutHeaderSearchLabel = 'main-layout-header-search-label',

  NotificationsPageHeading = 'notifications-page-heading',
  NotificationsLabel = 'notifications-label',
  NotificationsSettingsEmailSubHeadingLabel = 'notifications-settings-email-sub-heading-label',
  NotificationsSettingsDateSubHeadingLabel = 'notifications-settings-date-sub-heading-label',
  NotificationsSettingsLabel = 'notifications-settings-label',
  NotificationsSettingsText = 'notifications-settings-text',
  NotificationsSettingsToggleTextOn = 'notifications-settings-toggle-text-on',
  NotificationsSettingsToggleTextOff = 'notifications-settings-toggle-text-off',
  NotificationsSettingsSavedSuccess = 'notifications-settings-saved-success',
  NotificationsSettingsSavedFailure = 'notifications-settings-saved-failure',
  NotificationsSettingsDescriptionLabel = 'notifications-settings-description-label',
  NotificationsSettingsReminerSentLabel = 'notifications-settings-reminder-sent-label',
  NotificationsSettingsNumberDaysAheadLabel = 'notifications-settings-number-days-ahead-label',
  NotificationsSettingsEndDateTitleLabel = 'notifications-settings-end-date-title-label',
  NotificationsSettingsEndDateDescriptionLabel = 'notifications-settings-end-date-description-label',
  NotificationsSettingsRenewalDateTitleLabel = 'notifications-settings-renewal-date-title-label',
  NotificationsSettingsRenewalDateDescriptionLabel = 'notifications-settings-renewal-date-description-label',
  NotificationsSettingsNextReviewTitleLabel = 'notifications-settings-next-review-title-label',
  NotificationsSettingsNextReviewDescriptionLabel = 'notifications-settings-next-review-description-label',
  NotificationsSettingsNextAuditTitleLabel = 'notifications-settings-next-audit-title-label',
  NotificationsSettingsNextAuditDescriptionLabel = 'notifications-settings-next-audit-description-label',
  NotificationsSettingsNextRiskAssessmentTitleLabel = 'notifications-settings-next-risk-assessment-title-label',
  NotificationsSettingsNextRiskAssessmentDescriptionLabel = 'notifications-settings-next-risk-assessment-description-label',
  NotificationsSettingsHeadingLabel = 'notifications-settings-heading-label',
  NotificationsUnreadCountTooltip = 'notifications-unread-count-tooltip',

  SideMenuDashboardLabel = 'side-menu-dashboard-label',
  SideMenuCalendarLabel = 'side-menu-calendar-label',
  SideMenuAgreementsLabel = 'side-menu-agreements-label',
  SideMenuCabinetsLabel = 'side-menu-cabinets-label',
  SideMenuTagsLabel = 'side-menu-tags-label',
  SideMenuAgreementTypesLabel = 'side-menu-agreement-types-label',
  SideMenuCategoriesLabel = 'side-menu-categories-label',
  SideMenuSuppliersLabel = 'side-menu-suppliers-label',
  SideMenuInternalEntitiesLabel = 'side-menu-internal-entities-label',
  SideMenuReportsLabel = 'side-menu-reports-label',

  NPAPSectionLabel = 'npap-section-label',

  ParentAgreementFieldSupplierLabel = 'parent-agreement-field-supplier-label',
  ParentAgreementFieldAgreementNameLabel = 'parent-agreement-field-agreement-name-label',

  PersonalDataSectionLabel = 'personal-data-section-label',
  PersonalDataSectionDataTransferredLabel = 'personal-data-section-data-transferred-label',
  PersonalDataSectionDataTransferredCountryLabel = 'personal-data-section-data-transferred-country-label',
  PersonalDataSectionDataProcessedLabel = 'personal-data-section-data-processed-label',
  PersonalDataSectionPartyAgreementLabel = 'personal-data-section-party-agreement-label',
  PersonalDataSectionRoleLabel = 'personal-data-section-role-label',
  PersonalDataSectionDataProtectionIncludedLabel = 'personal-data-section-data-protection-included-label',

  RelatedAgreementsSectionLabel = 'related-agreements-section-label',
  RelatedAgreementsSectionParentAgreementLabel = 'related-agreements-section-parent-agreement-label',
  RelatedAgreementsSectionParentLabel = 'related-agreements-section-parent-label',
  RelatedAgreementsSectionOtherRelationshipsLabel = 'related-agreements-section-other-relationships-label',

  RelatedAgreementsTableNameLabel = 'related-agreements-table-name-label',
  RelatedAgreementsTableAgreementTypeLabel = 'related-agreements-table-agreement-type-label',
  RelatedAgreementsTableCategoryLabel = 'related-agreements-table-category-label',
  RelatedAgreementsTableRelationshipLabel = 'related-agreements-table-relationship-label',
  RelatedAgreementsTableNoAgreementsLabel = 'related-agreements-table-no-agreements-label',

  ReportsAddReportLabel = 'reports-add-report-label',
  ReportsEmptyTableTextLabel = 'reports-empty-table-text-label',

  ReportDetailsCardLabel = 'report-details-card-label',
  ReportDetailsCardEntityAgreementsLabel = 'report-details-card-entity-agreements-label',
  ReportDetailsCardEntitySuppliersLabel = 'report-details-card-entity-suppliers-label',
  ReportDetailsCardNameLabel = 'report-details-card-name-label',
  ReportDetailsCardEntityLabel = 'report-details-card-entity-label',
  ReportDetailsCardQueryLabel = 'report-details-card-query-label',
  ReportDetailsCardDownloadCsvLabel = 'report-details-card-download-csv-label',
  ReportDetailsCardViewTableLabel = 'report-details-card-view-table-label',
  ReportDetailsCardViewJsonLabel = 'report-details-card-view-json-label',

  ServerError400Label = 'server-error-400-label',
  ServerError401Label = 'server-error-401-label',
  ServerError403Label = 'server-error-403-label',
  ServerError404Label = 'server-error-404-label',

  ServiceDetailsSectionLabel = 'service-details-section-label',
  ServiceDetailsSectionCloudServiceLabel = 'service-details-section-cloud-service-label',
  ServiceDetailsSectionDeploymentModelLabel = 'service-details-section-deployment-model-label',
  ServiceDetailsSectionNatureDataLabel = 'service-details-section-nature-data-label',
  ServiceDetailsSectionCountriesServicePerformedLabel = 'service-details-section-countries-service-performed-label',
  ServiceDetailsSectionCountriesDataStoredLabel = 'service-details-section-countries-data-stored-label',
  ServiceDetailsSectionSubContractorsLabel = 'service-details-section-sub-contractors-label',

  SessionTimeoutHeading = 'session-timeout-heading',
  SessionTimeoutInfoMessage = 'session-timeout-info-message',
  SessionTimeoutAutoLogoutMessage = 'session-timeout-auto-logout-message',
  SessionTimeoutTimerMinutesAndSeconds = 'session-timeout-timer-minutes-and-seconds',
  SessionTimeoutTimerOnlySeconds = 'session-timeout-timer-only-seconds',

  SearchAdvancedSearchButtonTooltip = 'search-advanced-search-button-tooltip',
  SearchHelpModalExample1Description = 'search-help-modal-example-1-description',
  SearchHelpModalExample2Description = 'search-help-modal-example-2-description',
  SearchHelpModalExample3Description = 'search-help-modal-example-3-description',
  SearchHelpModalExample1Label = 'search-help-modal-example-1-label',
  SearchHelpModalExample2Label = 'search-help-modal-example-2-label',
  SearchHelpModalExample3Label = 'search-help-modal-example-3-label',
  SearchHelpModalExampleTextLabel = 'search-help-modal-example-text-label',
  SearchHelpModalOrTextDescription = 'search-help-modal-or-text-description',
  SearchHelpModalOrTextLabel = 'search-help-modal-or-text-label',
  SearchHelpModalQuotedTextDescription = 'search-help-modal-quoted-text-description',
  SearchHelpModalQuotedTextLabel = 'search-help-modal-quoted-text-label',
  SearchHelpModalTitle = 'search-help-modal-title',
  SearchHelpModalUnquotedTextDescription = 'search-help-modal-unquoted-text-description',
  SearchHelpModalUnquotedTextLabel = 'search-help-modal-unquoted-text-label',

  SubcontractorsFieldAddLabel = 'subcontractors-field-add-label',
  SubcontractorsFieldEditLabel = 'subcontractors-field-edit-label',
  SubcontractorsFieldDeleteLabel = 'subcontractors-field-delete-label',
  SubcontractorsFieldConfirmDeleteLabel = 'subcontractors-field-confirm-delete-label',
  SubcontractorsFieldEditFormSupplierLabel = 'subcontractors-field-edit-form-supplier-label',
  SubcontractorsFieldEditFormSupplierExternalLabel = 'subcontractors-field-edit-form-supplier-external-label',
  SubcontractorsFieldEditFormSupplierInternalLabel = 'subcontractors-field-edit-form-supplier-internal-label',
  SubcontractorsFieldEditFormFunctionCategoryLabel = 'subcontractors-field-edit-form-function-category-label',
  SubcontractorsFieldEditFormCountriesServicePerformedLabel = 'subcontractors-field-edit-form-countries-service-performed-label',
  SubcontractorsFieldEditFormCountriesDataStoredLabel = 'subcontractors-field-edit-form-countries-data-stored-label',
  SubcontractorsFieldEditFormDescriptionLabel = 'subcontractors-field-edit-form-description-label',
  SubcontractorsFieldTableSupplierNameLabel = 'subcontractors-field-table-supplier-name-label',
  SubcontractorsFieldTableCategoryLabel = 'subcontractors-field-table-category-label',
  SubcontractorsFieldTableCountriesServicePerformedLabel = 'subcontractors-field-table-countries-service-performed-label',
  SubcontractorsFieldTableNoSubcontractorsLabel = 'subcontractors-field-table-no-subcontractors-label',

  SupplierHistoryHeading = 'supplier-history-heading',

  SuppliersHeading = 'suppliers-heading',
  SuppliersNoItems = 'suppliers-no-items',
  SupplierCreateNewSupplierLabel = 'supplier-create-new-supplier-label',
  SupplierCreateSupplierFailure = 'supplier-create-supplier-failure',
  SupplierCreateSupplierSuccess = 'supplier-create-supplier-success',
  SupplierEditThisSupplierLabel = 'supplier-edit-this-supplier-label',
  SupplierSelectorCreateOptionText = 'supplier-selector-create-option-text',
  SupplierSelectorTypeToSelectOrCreateSupplierPlaceholder = 'supplier-selector-type-typ-select-or-create-supplier-placeholder',

  SupplierName = 'supplier-name',
  SupplierNameRequired = 'supplier-name-required',
  SupplierNameNameClashError = 'supplier-name-name-clash-error',
  SupplierCountryOfRegistration = 'supplier-country-of-registration',
  SupplierRegisteredAddress = 'supplier-registered-address',
  SupplierUltimateParent = 'supplier-ultimate-parent',
  SupplierParentCompany = 'supplier-parent-company',
  SupplierCorporateRegistrationNumber = 'supplier-corporate-registration-number',
  SupplierLegalEntityIdentifier = 'supplier-legal-entity-identifier',
  SupplierContactDetails = 'supplier-contact-details',
  SupplierContactDetailsPlaceholder = 'supplier-contact-details-placeholder',
  SupplierDescription = 'supplier-description',
  SupplierAssessmentCSRSurveyRequired = 'supplier-assessment-csr-survey-required',
  SupplierAssessmentDueDilligenceConducted = 'supplier-assessment-due-dilligence-conducted',
  SupplierAssessmentFollowCodeOfConductRequired = 'supplier-assessment-follow-code-of-conduct-required',
  SupplierAssessmentInformationSecurityRequired = 'supplier-assessment-information-security-required',
  SupplierAssessmentLatestCSRSurvey = 'supplier-assessment-latest-csr-survey',
  SupplierAssessmentLatestDueDilligence = 'supplier-assessment-last-due-dilligence',
  SupplierAssessmentLatestRiskAssessment = 'supplier-assessment-latest-risk-assessment',
  SupplierAssessmentRiskAssessmentCompleted = 'supplier-assessment-risk-assessment-completed',

  SupplierDetailsSubFormCreateOptionLabel = 'supplier-details-sub-form-create-option-label',
  SupplierVersionSupplierAssessmentLabel = 'suppliers-version-supplier-assessment-label',
  SupplierVersionVersionSavedLabel = 'suppliers-version-version-saved-label',

  UnauthorizedSettingsTooltip = 'unauthorized-settings-tooltip',

  ValidateAgreementGowerningLawLabel = 'validate-agreement-gowerning-law-label',
  ValidateAgreementReceiversProvidedServiceLabel = 'validate-agreement-receivers-provided-service-label',
  ValidateAgreementAnnualBudgetAmountLabel = 'validate-agreement-annual-budget-amount-label',
  ValidateAgreementAnnualBudgetCurrencyLabel = 'validate-agreement-annual-budget-currency-label',
  ValidateAgreementCompetentAuthorityNotifiedLabel = 'validate-agreement-competent-authority-notified-label',
  ValidateAgreementRiskAssessmentCompletedLabel = 'validate-agreement-risk-assessment-completed-label',
  ValidateAgreementLatestRiskAssessmentLabel = 'validate-agreement-latest-risk-assessment-label',
  ValidateAgreementNextRiskAssessmentLabel = 'validate-agreement-next-risk-assessment-label',
  ValidateAgreementFinalApprovalLabel = 'validate-agreement-final-approval-label',
  ValidateAgreementLatestAuditLabel = 'validate-agreement-latest-audit-label',
  ValidateAgreementNextAuditLabel = 'validate-agreement-next-audit-label',
  ValidateAgreementExitStrategyLabel = 'validate-agreement-exit-strategy-label',
  ValidateAgreementAlternativeServiceProvidersLabel = 'validate-agreement-alternative-service-providers-label',
  ValidateAgreementTimeCriticalOperationLabel = 'validate-agreement-time-critical-operation-label',
  ValidateAgreementAgreementTypeLabel = 'validate-agreement-agreement-type-label',
  ValidateAgreementPartyToAgreementLabel = 'validate-agreement-party-to-agreement-label',
  ValidateAgreementBriefDescriptionLabel = 'validate-agreement-brief-description-label',
  ValidateAgreementExternalSupplierLabel = 'validate-agreement-external-supplier-label',
  ValidateAgreementInternalSupplierLabel = 'validate-agreement-internal-supplierlabel',
  ValidateAgreementStartDateLabel = 'validate-agreement-start-date-label',
  ValidateAgreementEndDateLabel = 'validate-agreement-end-date-label',
  ValidateAgreementPersonalDataTransferredLabel = 'validate-agreement-personal-data-transferred-label',
  ValidateAgreementPersonalDataProcessedLabel = 'validate-agreement-personal-data-processedlabel',
  ValidateAgreementRoleLabel = 'validate-agreement-role-label',
  ValidateAgreementDataProtectionIncludedLabel = 'validate-agreement-data-protection-included-label',
  ValidateAgreementNameLabel = 'validate-agreement-name-label',
  ValidateAgreementContractOwnerLabel = 'validate-agreement-contract-owner-label',
  ValidateAgreementContractOwnerEmailLabel = 'validate-agreement-contract-owner-email-label',
  ValidateAgreementIsOutsourcingArrangementLabel = 'validate-agreement-is-outsourcing-arrangement-label',

  ValidationSummaryAuthorityLabel = 'validation-summary-authority-label',
  ValidationSummaryOtherLabel = 'validation-summary-other-label',

  VersionHistoryTableVersionLabel = 'version-history-table-version-label',
  VersionHistoryTableModifiedLabel = 'version-history-table-modified-label',
  VersionHistoryTableModifiedByLabel = 'version-history-table-modified-by-label',
  VersionHistoryTableNoItems = 'version-history-table-no-items',
}

export type TranslationLocale = {
  language: string;
  messages: {
    [P in MessageKeys]: string;
  };
};
