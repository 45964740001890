/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSupplierAttachmentUploadUrlRequest,
    CreateSupplierAttachmentUploadUrlRequestFromJSON,
    CreateSupplierAttachmentUploadUrlRequestToJSON,
    FileInfoWithS3Url,
    FileInfoWithS3UrlFromJSON,
    FileInfoWithS3UrlToJSON,
    ListAllSupplierAttachmentsResponse,
    ListAllSupplierAttachmentsResponseFromJSON,
    ListAllSupplierAttachmentsResponseToJSON,
    RenameSupplierAttachmentRequest,
    RenameSupplierAttachmentRequestFromJSON,
    RenameSupplierAttachmentRequestToJSON,
} from '../models';

export interface CreateSupplierAttachmentDownloadUrlRequest {
    supplierId: string;
    attachmentId: string;
}

export interface CreateSupplierAttachmentUploadUrlOperationRequest {
    supplierId: string;
    supplierVersion?: number;
    createSupplierAttachmentUploadUrlRequest?: CreateSupplierAttachmentUploadUrlRequest;
}

export interface DeleteSupplierAttachmentRequest {
    supplierId: string;
    attachmentId: string;
}

export interface ListAllSupplierAttachmentsRequest {
    supplierId: string;
    supplierVersion?: number;
}

export interface RenameSupplierAttachmentOperationRequest {
    supplierId: string;
    attachmentId: string;
    renameSupplierAttachmentRequest?: RenameSupplierAttachmentRequest;
}

/**
 * SupplierAttachmentsApi - interface
 * 
 * @export
 * @interface SupplierAttachmentsApiInterface
 */
export interface SupplierAttachmentsApiInterface {
    /**
     * Create a download url
     * @param {string} supplierId The id of the supplier to retrieve history about
     * @param {string} attachmentId The id of the agreement to retrieve attachment from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierAttachmentsApiInterface
     */
    createSupplierAttachmentDownloadUrlRaw(requestParameters: CreateSupplierAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Create a download url
     */
    createSupplierAttachmentDownloadUrl(requestParameters: CreateSupplierAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url>;

    /**
     * 
     * @summary Create a presigned upload url
     * @param {string} supplierId The id of the Supplier to retrieve files from
     * @param {number} [supplierVersion] An additional parameter to find files for a historical revision of an supplier
     * @param {CreateSupplierAttachmentUploadUrlRequest} [createSupplierAttachmentUploadUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierAttachmentsApiInterface
     */
    createSupplierAttachmentUploadUrlRaw(requestParameters: CreateSupplierAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Create a presigned upload url
     */
    createSupplierAttachmentUploadUrl(requestParameters: CreateSupplierAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url>;

    /**
     * 
     * @summary Delete an attachement
     * @param {string} supplierId The id of the supplier to retrieve history about
     * @param {string} attachmentId The id of the agreement to retrieve attachment from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierAttachmentsApiInterface
     */
    deleteSupplierAttachmentRaw(requestParameters: DeleteSupplierAttachmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete an attachement
     */
    deleteSupplierAttachment(requestParameters: DeleteSupplierAttachmentRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary List all attachments that belongs to a supplier
     * @param {string} supplierId The id of the Supplier to retrieve files from
     * @param {number} [supplierVersion] An additional parameter to find files for a historical revision of an supplier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierAttachmentsApiInterface
     */
    listAllSupplierAttachmentsRaw(requestParameters: ListAllSupplierAttachmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllSupplierAttachmentsResponse>>;

    /**
     * List all attachments that belongs to a supplier
     */
    listAllSupplierAttachments(requestParameters: ListAllSupplierAttachmentsRequest, initOverrides?: RequestInit): Promise<ListAllSupplierAttachmentsResponse>;

    /**
     * Rename the attachment
     * @param {string} supplierId The id of the supplier to retrieve history about
     * @param {string} attachmentId The id of the agreement to retrieve attachment from
     * @param {RenameSupplierAttachmentRequest} [renameSupplierAttachmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierAttachmentsApiInterface
     */
    renameSupplierAttachmentRaw(requestParameters: RenameSupplierAttachmentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Rename the attachment
     */
    renameSupplierAttachment(requestParameters: RenameSupplierAttachmentOperationRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class SupplierAttachmentsApi extends runtime.BaseAPI implements SupplierAttachmentsApiInterface {

    /**
     * Create a download url
     */
    async createSupplierAttachmentDownloadUrlRaw(requestParameters: CreateSupplierAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling createSupplierAttachmentDownloadUrl.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling createSupplierAttachmentDownloadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/attachments/{attachmentId}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Create a download url
     */
    async createSupplierAttachmentDownloadUrl(requestParameters: CreateSupplierAttachmentDownloadUrlRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url> {
        const response = await this.createSupplierAttachmentDownloadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a presigned upload url
     */
    async createSupplierAttachmentUploadUrlRaw(requestParameters: CreateSupplierAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling createSupplierAttachmentUploadUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.supplierVersion !== undefined) {
            queryParameters['supplierVersion'] = requestParameters.supplierVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/attachments`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSupplierAttachmentUploadUrlRequestToJSON(requestParameters.createSupplierAttachmentUploadUrlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Create a presigned upload url
     */
    async createSupplierAttachmentUploadUrl(requestParameters: CreateSupplierAttachmentUploadUrlOperationRequest, initOverrides?: RequestInit): Promise<FileInfoWithS3Url> {
        const response = await this.createSupplierAttachmentUploadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an attachement
     */
    async deleteSupplierAttachmentRaw(requestParameters: DeleteSupplierAttachmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling deleteSupplierAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteSupplierAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/attachments/{attachmentId}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an attachement
     */
    async deleteSupplierAttachment(requestParameters: DeleteSupplierAttachmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteSupplierAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * List all attachments that belongs to a supplier
     */
    async listAllSupplierAttachmentsRaw(requestParameters: ListAllSupplierAttachmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllSupplierAttachmentsResponse>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling listAllSupplierAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.supplierVersion !== undefined) {
            queryParameters['supplierVersion'] = requestParameters.supplierVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/attachments`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllSupplierAttachmentsResponseFromJSON(jsonValue));
    }

    /**
     * List all attachments that belongs to a supplier
     */
    async listAllSupplierAttachments(requestParameters: ListAllSupplierAttachmentsRequest, initOverrides?: RequestInit): Promise<ListAllSupplierAttachmentsResponse> {
        const response = await this.listAllSupplierAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rename the attachment
     */
    async renameSupplierAttachmentRaw(requestParameters: RenameSupplierAttachmentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling renameSupplierAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling renameSupplierAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/suppliers/{supplierId}/attachments/{attachmentId}`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RenameSupplierAttachmentRequestToJSON(requestParameters.renameSupplierAttachmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rename the attachment
     */
    async renameSupplierAttachment(requestParameters: RenameSupplierAttachmentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.renameSupplierAttachmentRaw(requestParameters, initOverrides);
    }

}
