/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Cabinet,
    CabinetFromJSON,
    CabinetToJSON,
    GetCabinetResponse,
    GetCabinetResponseFromJSON,
    GetCabinetResponseToJSON,
    ListAllCabinetsResponse,
    ListAllCabinetsResponseFromJSON,
    ListAllCabinetsResponseToJSON,
} from '../models';

export interface CreateOrUpdateCabinetRequest {
    cabinetName: string;
    cabinet?: Cabinet;
}

export interface DeleteCabinetRequest {
    cabinetName: string;
}

export interface GetCabinetRequest {
    cabinetName: string;
}

/**
 * CabinetsApi - interface
 * 
 * @export
 * @interface CabinetsApiInterface
 */
export interface CabinetsApiInterface {
    /**
     * 
     * @summary Create or Update a cabinet
     * @param {string} cabinetName The name of the cabinet to retrieve
     * @param {Cabinet} [cabinet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApiInterface
     */
    createOrUpdateCabinetRaw(requestParameters: CreateOrUpdateCabinetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Create or Update a cabinet
     */
    createOrUpdateCabinet(requestParameters: CreateOrUpdateCabinetRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Delete a single cabinet
     * @param {string} cabinetName The name of the cabinet to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApiInterface
     */
    deleteCabinetRaw(requestParameters: DeleteCabinetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a single cabinet
     */
    deleteCabinet(requestParameters: DeleteCabinetRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Get a single cabinet, with metadata and user permission
     * @param {string} cabinetName The name of the cabinet to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApiInterface
     */
    getCabinetRaw(requestParameters: GetCabinetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCabinetResponse>>;

    /**
     * Get a single cabinet, with metadata and user permission
     */
    getCabinet(requestParameters: GetCabinetRequest, initOverrides?: RequestInit): Promise<GetCabinetResponse>;

    /**
     * 
     * @summary List all agreements cabinets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApiInterface
     */
    listAllCabinetsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllCabinetsResponse>>;

    /**
     * List all agreements cabinets
     */
    listAllCabinets(initOverrides?: RequestInit): Promise<ListAllCabinetsResponse>;

}

/**
 * 
 */
export class CabinetsApi extends runtime.BaseAPI implements CabinetsApiInterface {

    /**
     * Create or Update a cabinet
     */
    async createOrUpdateCabinetRaw(requestParameters: CreateOrUpdateCabinetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.cabinetName === null || requestParameters.cabinetName === undefined) {
            throw new runtime.RequiredError('cabinetName','Required parameter requestParameters.cabinetName was null or undefined when calling createOrUpdateCabinet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/agreements/cabinets/{cabinetName}`.replace(`{${"cabinetName"}}`, encodeURIComponent(String(requestParameters.cabinetName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CabinetToJSON(requestParameters.cabinet),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create or Update a cabinet
     */
    async createOrUpdateCabinet(requestParameters: CreateOrUpdateCabinetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createOrUpdateCabinetRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a single cabinet
     */
    async deleteCabinetRaw(requestParameters: DeleteCabinetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.cabinetName === null || requestParameters.cabinetName === undefined) {
            throw new runtime.RequiredError('cabinetName','Required parameter requestParameters.cabinetName was null or undefined when calling deleteCabinet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/cabinets/{cabinetName}`.replace(`{${"cabinetName"}}`, encodeURIComponent(String(requestParameters.cabinetName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single cabinet
     */
    async deleteCabinet(requestParameters: DeleteCabinetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCabinetRaw(requestParameters, initOverrides);
    }

    /**
     * Get a single cabinet, with metadata and user permission
     */
    async getCabinetRaw(requestParameters: GetCabinetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCabinetResponse>> {
        if (requestParameters.cabinetName === null || requestParameters.cabinetName === undefined) {
            throw new runtime.RequiredError('cabinetName','Required parameter requestParameters.cabinetName was null or undefined when calling getCabinet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/cabinets/{cabinetName}`.replace(`{${"cabinetName"}}`, encodeURIComponent(String(requestParameters.cabinetName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCabinetResponseFromJSON(jsonValue));
    }

    /**
     * Get a single cabinet, with metadata and user permission
     */
    async getCabinet(requestParameters: GetCabinetRequest, initOverrides?: RequestInit): Promise<GetCabinetResponse> {
        const response = await this.getCabinetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all agreements cabinets
     */
    async listAllCabinetsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ListAllCabinetsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/agreements/cabinets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllCabinetsResponseFromJSON(jsonValue));
    }

    /**
     * List all agreements cabinets
     */
    async listAllCabinets(initOverrides?: RequestInit): Promise<ListAllCabinetsResponse> {
        const response = await this.listAllCabinetsRaw(initOverrides);
        return await response.value();
    }

}
