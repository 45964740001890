import { Translator } from '@fcg-tech/regtech-types';
import { MessageKeys } from '../translations/messageKeys';
import Features from '../Features';
import { LogLevel, NonBlockingError } from '../types';

export const validateAgreementNonBlocking = (values: any, t: Translator) => {
  const nonBlockingErrors: Array<NonBlockingError> = [];

  const { isCriticalOrImportant, competentAuthorityNotified } =
    values?.agreementAssessment?.data;
  const { governingLawOftheAgreement, receiversOfProvidedService } =
    values?.details;
  const { isOutsourcingArrangement } = values?.agreementAssessment;
  const {
    riskAssessmentCompleted,
    latestRiskAssessment,
    nextRiskAssessment,
    finalApproval,
    latestAudit,
    nextAudit,
    exitStrategy,
    alternativeServiceProviders,
    timeCriticalOperation,
  } = values.agreementAssessment.data.criticalOrImportant;

  // Hoist specific validation fields
  const { agreementType, partyToAgreement, briefDescription } = values?.details;
  const { isExternal, externalSupplier, internalSupplier } =
    values?.details?.mainSupplier;

  const { startDate, endDate, endDateRequired } = values?.details?.term;

  const {
    isPersonalDataTransferred,
    isPersonalDataProcessed,
    role,
    dataProtectionIncluded,
  } = values?.personalData;

  if (isOutsourcingArrangement === null) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementIsOutsourcingArrangementLabel),
      field: 'agreementAssessment.isOutsourcingArrangement',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    !governingLawOftheAgreement &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementGowerningLawLabel),
      field: 'details.governingLawOftheAgreement',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    receiversOfProvidedService.length === 0 &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementReceiversProvidedServiceLabel),
      field: 'details.receiversOfProvidedService',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  const { annualBudgetCostEstimation } = values.details || {};
  if (
    !annualBudgetCostEstimation?.amount &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementAnnualBudgetAmountLabel),
      field: 'details.annualBudgetCostEstimation.amount',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }
  if (
    !annualBudgetCostEstimation?.currency &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementAnnualBudgetCurrencyLabel),
      field: 'details.annualBudgetCostEstimation.currency',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    competentAuthorityNotified !== true &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementCompetentAuthorityNotifiedLabel),
      field: 'agreementAssessment.data.competentAuthorityNotified',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    riskAssessmentCompleted !== true &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementRiskAssessmentCompletedLabel),
      field:
        'agreementAssessment.data.criticalOrImportant.riskAssessmentCompleted',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    !latestRiskAssessment &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementLatestRiskAssessmentLabel),
      field:
        'agreementAssessment.data.criticalOrImportant.latestRiskAssessment',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    !nextRiskAssessment &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementNextRiskAssessmentLabel),
      field: 'agreementAssessment.data.criticalOrImportant.nextRiskAssessment',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (!finalApproval && isCriticalOrImportant && isOutsourcingArrangement) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementFinalApprovalLabel),
      field: 'agreementAssessment.data.criticalOrImportant.finalApproval',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (!latestAudit && isCriticalOrImportant && isOutsourcingArrangement) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementLatestAuditLabel),
      field: 'agreementAssessment.data.criticalOrImportant.latestAudit',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (!nextAudit && isCriticalOrImportant && isOutsourcingArrangement) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementNextAuditLabel),
      field: 'agreementAssessment.data.criticalOrImportant.nextAudit',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (!exitStrategy && isCriticalOrImportant && isOutsourcingArrangement) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementExitStrategyLabel),
      field: 'agreementAssessment.data.criticalOrImportant.exitStrategy',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    !alternativeServiceProviders &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementAlternativeServiceProvidersLabel),
      field:
        'agreementAssessment.data.criticalOrImportant.alternativeServiceProviders',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    timeCriticalOperation === null &&
    isCriticalOrImportant &&
    isOutsourcingArrangement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementTimeCriticalOperationLabel),
      field:
        'agreementAssessment.data.criticalOrImportant.timeCriticalOperation',
      validationGroup: 'EBA',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.AgreementType &&
    !agreementType
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementAgreementTypeLabel),
      field: 'details.agreementType',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.PartyToAgreement &&
    !partyToAgreement
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementPartyToAgreementLabel),
      field: 'details.partyToAgreement',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.BriefDescription &&
    !briefDescription
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementBriefDescriptionLabel),
      field: 'details.briefDescription',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.Supplier &&
    !externalSupplier &&
    isExternal
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementExternalSupplierLabel),
      field: 'details.mainSupplier.externalSupplier',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.Supplier &&
    !internalSupplier &&
    !isExternal
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementInternalSupplierLabel),
      field: 'details.mainSupplier.internalSupplier',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.Term.StartDate &&
    !startDate
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementStartDateLabel),
      field: 'details.term.startDate',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.Details.Term.EndDate &&
    (endDateRequired === null || (endDateRequired && !endDate))
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementEndDateLabel),
      field: 'details.term.endDate',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.PersonalData
      .IsPersonalDataTransferred &&
    isPersonalDataTransferred === null
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementPersonalDataTransferredLabel),
      field: 'personalData.isPersonalDataTransferred',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.PersonalData
      .IsPersonalDataProcessed &&
    isPersonalDataProcessed === null
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementPersonalDataProcessedLabel),
      field: 'personalData.isPersonalDataProcessed',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.PersonalData.Role &&
    !role &&
    isPersonalDataProcessed
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementRoleLabel),
      field: 'personalData.role',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  if (
    Features.FieldErrors.NonBlocking.Agreement.PersonalData
      .DataProtectionIncluded &&
    dataProtectionIncluded === null &&
    isPersonalDataProcessed
  ) {
    nonBlockingErrors.push({
      message: t(MessageKeys.ValidateAgreementDataProtectionIncludedLabel),
      field: 'personalData.dataProtectionIncluded',
      validationGroup: 'Other',
      level: LogLevel.Warning,
    });
  }

  return nonBlockingErrors;
};
