import { foundation } from '@fcg-tech/regtech-design-system';
import styled from 'styled-components';
import { TabLinkList } from './tabLink';

export const StyledTabLinkList: typeof TabLinkList = styled(TabLinkList)`
  display: flex;
  background-color: ${foundation.palette.onyx100.hex};

  & > .tab-link-list__tab-item {
    padding: 1.1rem 1rem;
    border-right: 2px solid white;
    text-decoration: none;
    color: ${foundation.palette.onyx350.hex};
    font-weight: 600;
    font-size: 1.6rem;
    position: relative;

    &:hover {
      background-color: ${foundation.palette.topaz100.hex};
    }

    &[aria-selected='true'],
    &.force-selected {
      color: ${foundation.palette.topaz500.hex};
      &:after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${foundation.palette.topaz500.hex};
      }
    }

    &.tab-link-list__tab-item--label {
      background-color: transparent;
      color: ${foundation.palette.topaz500.hex};
      &:hover {
        background-color: transparent;
      }
    }
  }

  &.vertical {
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${foundation.palette.onyx200.hex};
    border-right: 2px solid ${foundation.palette.onyx200.hex};
    overflow-y: auto;

    & > .tab-link-list__tab-item {
      padding: 1.1rem 1rem;
      background-color: white;
      border-right: none;

      &:hover {
        background-color: ${foundation.palette.topaz100.hex};
      }

      &[aria-selected='true'],
      &.force-selected {
        &:after {
          content: '';
          position: absolute;
          width: 3px;
          height: initial;
          bottom: 0;
          top: 0;
          right: 0;
          left: initial;
          background-color: ${foundation.palette.topaz500.hex};
        }
      }

      &.tab-link-list__tab-item--label {
        background-color: transparent;
        color: ${foundation.palette.topaz500.hex};
        &:hover {
          background-color: transparent;
        }
      }
    }

    & > .tab-link-list__tab-item + .tab-link-list__tab-item {
      margin-top: 2px;
    }
  }
`;
