/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import { classNames } from '@fcg-tech/regtech-utils';
import React, {
  HTMLInputTypeAttribute,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useCombinedRefs } from '../../utils';
import {
  BorderContainer,
  Input,
  InputErrorMessage,
  StyledInfoCircle,
  TextFieldWrapper,
} from './TextField.styles';

export interface TextFieldProps extends React.HTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errorMessage?: string;
  info?: string;
  isEditEnabled?: boolean;
  selectAllOnFocus?: boolean;
  value?: string;
  name?: string;
  className?: string;
  inputClassName?: string;
  readOnly?: boolean;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  autoFocus?: boolean;
  onFocus?: (event: React.FocusEvent) => void;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      info,
      children,
      isEditEnabled = true,
      value,
      name,
      error,
      errorMessage,
      selectAllOnFocus,
      className,
      inputClassName,
      disabled,
      autoFocus,
      onFocus,
      ...props
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement>();
    const combinedRef = useCombinedRefs(ref, innerRef);
    const handleFocus = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (selectAllOnFocus) {
          event.target.setSelectionRange(0, event.target.value.length);
        }
        if (onFocus && typeof onFocus === 'function') {
          onFocus(event);
        }
        setHasFocus(
          (document.activeElement as unknown) ===
            (combinedRef?.current as unknown),
        );
      },
      [selectAllOnFocus, onFocus, combinedRef],
    );

    const [hasFocus, setHasFocus] = useState(false);
    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        props?.onBlur?.(event);
        setHasFocus(
          (document.activeElement as unknown) ===
            (combinedRef?.current as unknown),
        );
      },
      [combinedRef, props],
    );

    useEffect(() => {
      if (autoFocus) {
        innerRef?.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <TextFieldWrapper className="textfield-wrapper">
        <BorderContainer
          className={classNames(
            'textfield-border',
            disabled && 'disabled',
            !isEditEnabled && 'edit-disabled',
            !hasFocus && (error || errorMessage) && 'error',
            className,
          )}
        >
          {isEditEnabled ? (
            <Input
              ref={combinedRef}
              name={name}
              {...props}
              value={value || ''}
              className={classNames('textfield-input', inputClassName)}
              disabled={disabled || !isEditEnabled}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          ) : (
            value
          )}
        </BorderContainer>
        {info && <StyledInfoCircle info={info} />}
        {children}
        {!hasFocus && errorMessage ? (
          <InputErrorMessage>{errorMessage}</InputErrorMessage>
        ) : null}
      </TextFieldWrapper>
    );
  },
);
