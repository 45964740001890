/* tslint:disable */
/* eslint-disable */
/**
 * Oasys
 * This API handles agreements for Oasys
 *
 * The version of the OpenAPI document: 0.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetPersonalSettingsTokenResponse,
    GetPersonalSettingsTokenResponseFromJSON,
    GetPersonalSettingsTokenResponseToJSON,
    NotificationSettings,
    NotificationSettingsFromJSON,
    NotificationSettingsToJSON,
} from '../models';

export interface UpdatePersonalNotificationSettingsRequest {
    notificationSettings?: NotificationSettings;
}

/**
 * PersonalSettingsApi - interface
 * 
 * @export
 * @interface PersonalSettingsApiInterface
 */
export interface PersonalSettingsApiInterface {
    /**
     * 
     * @summary create a token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalSettingsApiInterface
     */
    createPersonalSettingsTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * create a token
     */
    createPersonalSettingsToken(initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary delete token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalSettingsApiInterface
     */
    deletePersonalSettingsTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * delete token
     */
    deletePersonalSettingsToken(initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Get Setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalSettingsApiInterface
     */
    getPersonalNotificationSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationSettings>>;

    /**
     * Get Setting
     */
    getPersonalNotificationSettings(initOverrides?: RequestInit): Promise<NotificationSettings>;

    /**
     * 
     * @summary Get token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalSettingsApiInterface
     */
    getPersonalSettingsTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetPersonalSettingsTokenResponse>>;

    /**
     * Get token
     */
    getPersonalSettingsToken(initOverrides?: RequestInit): Promise<GetPersonalSettingsTokenResponse>;

    /**
     * 
     * @summary Update Setting
     * @param {NotificationSettings} [notificationSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalSettingsApiInterface
     */
    updatePersonalNotificationSettingsRaw(requestParameters: UpdatePersonalNotificationSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Update Setting
     */
    updatePersonalNotificationSettings(requestParameters: UpdatePersonalNotificationSettingsRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class PersonalSettingsApi extends runtime.BaseAPI implements PersonalSettingsApiInterface {

    /**
     * create a token
     */
    async createPersonalSettingsTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/personalSettings/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create a token
     */
    async createPersonalSettingsToken(initOverrides?: RequestInit): Promise<void> {
        await this.createPersonalSettingsTokenRaw(initOverrides);
    }

    /**
     * delete token
     */
    async deletePersonalSettingsTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/personalSettings/token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete token
     */
    async deletePersonalSettingsToken(initOverrides?: RequestInit): Promise<void> {
        await this.deletePersonalSettingsTokenRaw(initOverrides);
    }

    /**
     * Get Setting
     */
    async getPersonalNotificationSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/personalSettings/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationSettingsFromJSON(jsonValue));
    }

    /**
     * Get Setting
     */
    async getPersonalNotificationSettings(initOverrides?: RequestInit): Promise<NotificationSettings> {
        const response = await this.getPersonalNotificationSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get token
     */
    async getPersonalSettingsTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetPersonalSettingsTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oasys/personalSettings/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPersonalSettingsTokenResponseFromJSON(jsonValue));
    }

    /**
     * Get token
     */
    async getPersonalSettingsToken(initOverrides?: RequestInit): Promise<GetPersonalSettingsTokenResponse> {
        const response = await this.getPersonalSettingsTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Setting
     */
    async updatePersonalNotificationSettingsRaw(requestParameters: UpdatePersonalNotificationSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oasys/personalSettings/notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsToJSON(requestParameters.notificationSettings),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Setting
     */
    async updatePersonalNotificationSettings(requestParameters: UpdatePersonalNotificationSettingsRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.updatePersonalNotificationSettingsRaw(requestParameters, initOverrides);
    }

}
