import { useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { getResourceIdFromLocationHeader, useApi } from '../apiUtils';
import {
  GetReportResponse,
  ListReportAgreementsResponseResults,
  Report,
  ReportListItem,
  ReportsApi,
} from '../schema';
import { allReportAgreementsKey, allReportsKey, reportKey } from './cacheKeys';

export const getAllReports = async (
  api: ReportsApi,
): Promise<Array<ReportListItem>> => {
  const result = await api.listAllReports();
  return result.result ?? [];
};

export const getReportAgreements = async (api: ReportsApi) => {
  const result = await api.listReportAgreements({});
  return result.results ?? [];
};

export const getReport = async (reportId: string, api: ReportsApi) => {
  const result = await api.getReport({ reportId });
  return result;
};

export const useReports = (tenantId: string, config?: SWRConfiguration) => {
  const api = useApi<ReportsApi>('ReportsApi', tenantId);
  return useSWR<Array<ReportListItem>>(
    allReportsKey(tenantId),
    () => getAllReports(api),
    { suspense: true, ...config },
  );
};

export const useReportAgreements = (config?: SWRConfiguration) => {
  const tenant = 'xjk';
  const api = useApi<ReportsApi>('ReportsApi', tenant);
  return useSWR<Array<ListReportAgreementsResponseResults>>(
    allReportAgreementsKey(tenant),
    () => getReportAgreements(api),
    { suspense: true, ...config },
  );
};

export const useReport = (reportId: string, config?: SWRConfiguration) => {
  const tenant = 'xjk';
  const api = useApi<ReportsApi>('ReportsApi', tenant);
  return useSWR<GetReportResponse>(
    reportKey(tenant, reportId),
    () => getReport(reportId, api),
    { suspense: true, ...config },
  );
};

export const useReportActions = () => {
  const tenant = 'xjk';
  const api = useApi<ReportsApi>('ReportsApi', tenant);
  return useMemo(
    () => ({
      createReport: async (report: Report) => {
        const createReportResponse = await api.createReportRaw({ report });
        mutate(allReportsKey(tenant));
        return getResourceIdFromLocationHeader(createReportResponse);
      },
      updateReport: async (reportId: string, report: Report) => {
        await api.updateReport({ reportId, report });
        // TODO: mutate
      },
      deleteReport: async (reportId: string) => {
        await api.deleteReport({ reportId });
        // TODO: mutate
      },
    }),
    [api],
  );
};
